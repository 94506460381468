import React from 'react'
import styled from '@emotion/styled'
import JobPostingSimple from '@/components/JobPostingSimple'

interface JobResultsProps {
  jobs: any[]
}

export const JobResults = ({ jobs }: JobResultsProps) => {
  return (
    <>
      {jobs.length >= 1 && (
        <JobsLayoutContainer>
          {jobs.map((job, i) => (
            <JobPostingSimple key={`job-${i}`} {...job} />
          ))}
        </JobsLayoutContainer>
      )}
      {jobs?.length === 0 && (
        <ResultsMessage>
          No results. Try adjusting the filters above.
        </ResultsMessage>
      )}
    </>
  )
}

const ResultsMessage = styled.p`
  color: #fff;
  text-align: center;
`

const JobsLayoutContainer = styled.div`
  display: grid;
  gap: var(--d-gutter);
  margin: 0 0 60px;
  background-color: #fff;
  padding: 30px;

  > article:not(:last-of-type) {
    border-bottom: solid 1px var(--c-silver);
    padding-bottom: var(--d-gutter);
  }
`
