// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import { graphql } from 'gatsby'
import SidebarLayout from '@/layouts/SidebarLayout'
import Header from '@/components/BlogPostHeader'
import { Image, ImageContainer } from '@/components/Image'
import {
  BlogMetadataHeader,
  BlogMetadataLink,
  BlogMetadataItem,
  BlogMetadataLabel,
  BlogPostDate as Date,
  BlogPostImageCaption,
} from '@/styles/shared'
import { gqlFormatter } from '@/formatters/blog-post'

type Props = {
  pageContext: Object,
  data: Object,
}

function BlogPageTemplate(props: Props) {
  const { pageContext, data } = props
  const blogPost = gqlFormatter({ pageContext, blogPost: data.blogPost })
  const { displayFeaturedImageInPost } = data.blogPost.data
  const {
    company,
    categories: _categories,
    tags: _tags,
    next,
    previous,
    title,
    publishDate,
    featuredImage,
    body,
  } = blogPost
  const categories = _categories
    .map(category => (
      <BlogMetadataLink key={category}>{category}</BlogMetadataLink>
    ))
    .reduce((prev, curr) => [prev, prev.length ? ', ' : '', curr], '')
  const tags = _tags
    .map(tag => <BlogMetadataLink key={tag}>{tag}</BlogMetadataLink>)
    .reduce((prev, curr) => [prev, prev.length ? ', ' : '', curr], '')

  return (
    <SidebarLayout>
      {/* {JSON.stringify(blogPost.data.body)} */}
      <Header next={next} previous={previous}>
        {company && (
          <BlogMetadataItem>
            <BlogMetadataLabel>
              <BlogMetadataHeader>Company</BlogMetadataHeader>
            </BlogMetadataLabel>
            <BlogMetadataLabel>
              <BlogMetadataLink>{company}</BlogMetadataLink>
            </BlogMetadataLabel>
          </BlogMetadataItem>
        )}

        {categories && (
          <BlogMetadataItem>
            <BlogMetadataLabel>
              <BlogMetadataHeader>Category</BlogMetadataHeader>
            </BlogMetadataLabel>
            <BlogMetadataLabel>{categories}</BlogMetadataLabel>
          </BlogMetadataItem>
        )}

        {tags && (
          <BlogMetadataItem>
            <BlogMetadataLabel>
              <BlogMetadataHeader>Tags</BlogMetadataHeader>
            </BlogMetadataLabel>
            <BlogMetadataLabel>{tags}</BlogMetadataLabel>
          </BlogMetadataItem>
        )}
      </Header>
      <Article>
        <Date>{publishDate}</Date>
        <Title>{title}</Title>
        {displayFeaturedImageInPost && featuredImage && (
          <FeaturedImage src={featuredImage} alt="" />
        )}
        {body &&
          body.length >= 1 &&
          body.map((slice, idx) => (
            <Fragment key={`slide-${idx}`}>
              {slice.slice_type === 'text' && slice.primary && (
                <div
                  dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}
                />
              )}
              {slice.slice_type === 'html' && slice.primary && (
                <div
                  className="embed"
                  dangerouslySetInnerHTML={{ __html: slice.primary.html.text }}
                />
              )}
              {slice.slice_type === 'banner_with_caption' && slice.primary && (
                <>
                  {slice.primary.image && (
                    <Image
                      loading="lazy"
                      src={slice.primary.image.url}
                      alt={slice.primary.image.alt}
                    />
                  )}
                  {slice.primary.description && (
                    <BlogPostImageCaption
                      dangerouslySetInnerHTML={{
                        __html: slice.primary.description.html,
                      }}
                    />
                  )}
                </>
              )}
            </Fragment>
          ))}
      </Article>
    </SidebarLayout>
  )
}

export default BlogPageTemplate

const Title = styled.h1`
  color: var(--c-gunmetal);
  font: 400 ${rem('46px')} var(--f-serif);
  letter-spacing: ${rem('-1.15px')};
  line-height: 1;
`

const FeaturedImage = props => (
  <ImageContainer>
    <Image featured loading="lazy" {...props} />
  </ImageContainer>
)

const ArticleStyles = css`
  font-size: ${rem('20px')};
  line-height: 1.5;
  color: var(--c-gunmetal);

  iframe,
  object {
    display: block;
    margin: 0 auto;
  }
`

const Article = styled.main`
  ${ArticleStyles};
`

export const query = graphql`
  query ($prismicId: ID!) {
    blogPost: prismicBlogPost(prismicId: { eq: $prismicId }) {
      data {
        # tags
        title
        publishDate: publish_date
        featuredImage: featured_image {
          url
        }
        displayFeaturedImageInPost: featured_image {
          url
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  title
                }
              }
            }
          }
        }
        company {
          document {
            ... on PrismicCompany {
              id
              data {
                title
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          # ... on PrismicBlogPostBodyBannerWithCaption {
          #   slice_type
          #   primary {
          #     image {
          #       url
          #     }
          #     description {
          #       html
          #     }
          #   }
          # }
          ... on PrismicBlogPostDataBodyHtml {
            slice_type
            primary {
              html {
                text
              }
            }
          }
        }
      }
    }
  }
`
