import { generateMedia } from '@/lib/emotion-media-query'

export const mq = generateMedia({
  large: '1200px',
  medium: '960px',
  mediumSmall: '800px',
  small: '675px',
})

export const theme = {
  colors: {
    mediumGray: '#aab2b5',
    charcoalGrey: '#354042',
    canary: '#eef85c',
    grey: '#979797',
    gunmetal: '#3b5960',
    lightGreyBlue: '#9bbec7',
    lightBlueGrey: '#b5d2d5',
    darkGreenBlue: '#345c62',
    darkerGreenBlue: '#255d63',
    veryLightPink: '#f6f6f6',
    silver: '#ccd0d2',
  },
  dimensions: {
    headerHeightMobile: '100px',
    gutter: '30px',
    formGutter: '36px',
    formInputGutter: '10px',
    layoutMaxWidth: '1275px',
    postMinHeight: '392px',
    postFeaturedImageAspectRatio: '62.3%',
    borderRadius: '8px',
  },
  fonts: {
    default: '"Open Sans", Helvetica, Arial, sans-serif',
    sans: '"Open Sans", Helvetica, Arial, sans-serif',
    serif: '"EB Garamond", Georgia, serif',
    condensed: '"Open Sans Condensed", Helvetica, Arial, sans-serif',
  },
}

export default theme
