// @flow

import React from 'react'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import FormLayout from '@/layouts/FormLayout'
import { Title } from '@/components/Form'
import { LinkButton } from '@/components/Button'
import GuideSrc from '@/assets/guide.png'

type Props = {
  data: Object,
  pageContext: Object,
}

function FormSubmittedTemplate({
  pageContext: { section },
  data: {
    page: {
      data: {
        title: { text: title },
        description: { html: description },
        ctaLabel,
        ctaLink,
        displayGuideImage,
      },
    },
  },
}: Props) {
  return (
    <FormLayout full={displayGuideImage === 'No'} section={section}>
      <div>
        {title && <Title large>{title}</Title>}
        {ctaLabel && ctaLink && (
          <LinkButton to={ctaLink.url}>{ctaLabel}</LinkButton>
        )}
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
      {displayGuideImage === 'Yes' && (
        <div>
          <img
            css={css`
              display: block;
              width: 100%;
            `}
            loading="lazy"
            src={GuideSrc}
            alt="cover of guide"
          />
        </div>
      )}
    </FormLayout>
  )
}

export default FormSubmittedTemplate

export const query = graphql`
  query ($uid: String!) {
    page: prismicFormSubmitted(uid: { eq: $uid }) {
      data {
        title: form_title {
          text
        }
        description {
          html
        }
        ctaLabel: cta_label
        ctaLink: cta_link {
          url
        }
        displayGuideImage: display_guide_image
      }
    }
  }
`
