import React from 'react'
import { css, Global } from '@emotion/core'
import LayoutContainer from '@/components/LayoutContainer'
import Layout from '@/layouts'
import CareersLayout from '@/layouts/CareersLayout'
import LeafPattern from '@/assets/leaf-pattern.png'
import { SplitLayoutStyles } from '@/styles/shared'

type Props = {
  layoutCss: String,
  children?: React.ReactNode,
  full: Boolean,
  section: String,
}

export default ({ children, full, section, ...rest }: Props) => (
  <>
    {section === 'Careers' && (
      <CareersLayout headerOverlay disableFooter {...rest}>
        <Global styles={FormGlobalStyles} />
        <LayoutContainer css={!full && SplitLayoutStyles}>
          {children}
        </LayoutContainer>
      </CareersLayout>
    )}
    {section !== 'Careers' && (
      <Layout headerOverlay disableFooter {...rest}>
        <Global styles={FormGlobalStyles} />
        <LayoutContainer css={!full && SplitLayoutStyles}>
          {children}
        </LayoutContainer>
      </Layout>
    )}
  </>
)

const FormGlobalStyles = css`
  body {
    background: var(--c-lightGreyBlue) url(${LeafPattern});
  }
`
