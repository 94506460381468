// @flow

import React from 'react'
import { object, string } from 'yup'
import Form from '@/components/Form'
import {
  FirstNameLastName,
  Phone,
  Email,
  InterestedIn,
  Comments,
  NewsletterOptIn,
} from '@/components/form-fields'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  phone: Phone.schema.required(),
  'interested-in': InterestedIn.schema.required(),
  comments: Comments.schema,
  email: Email.schema,
  'newsletter-opt-in': NewsletterOptIn.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  phone: Phone.initialValue,
  'interested-in': InterestedIn.initialValue,
  comments: Comments.initialValue,
  email: Email.initialValue,
  'newsletter-opt-in': NewsletterOptIn.initialValue,
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

type Props = {
  action: String,
}

export default ({ action }: Props) => (
  <Form
    name="Contact Form"
    action={action}
    submitLabel="Submit"
    formikOptions={formikOptions}>
    <FirstNameLastName.Component />
    <InterestedIn.Component />
    <Phone.Component />
    <Comments.Component />
    <NewsletterComponent />
  </Form>
)

export const NewsletterComponent = () => (
  <>
    <p>
      <strong>Sign up to receive our newsletter</strong>
    </p>
    <Email.Component />
    <NewsletterOptIn.Component
      className="dark-text"
      style={{ background: 'white', color: 'var(--c-charcoalGrey)' }}
    />
  </>
)
