// @flow

import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { LinkButton } from '@/components/Button'
import Options from '@/components/Options'
import { mq } from '@/styles/theme'

const HomeOptionsStyles = css`
  margin-top: 160px;
  margin-bottom: 60px;

  ${mq.greaterThan('medium')`
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 calc(var(--d-gutter) + 150px);
  `};
`

export default () => {
  const {
    page: {
      data: {
        title,
        buttonLabel,
        buttonLink: { url: buttonLink },
        buttonLabel2,
        buttonLink2: { url: buttonLink2 },
        options,
      },
    },
  } = useStaticQuery(query)

  return (
    <Options
      title={title}
      description={
        <Buttons>
          {buttonLabel && buttonLink && (
            <LinkButton to={buttonLink}>{buttonLabel}</LinkButton>
          )}
          {buttonLabel2 && buttonLink2 && (
            <LinkButton to={buttonLink2}>{buttonLabel2}</LinkButton>
          )}
        </Buttons>
      }
      options={options.map(({ icon: { url: icon }, title }) => ({
        icon: { src: icon },
        title,
      }))}
      css={HomeOptionsStyles}
    />
  )
}

const Buttons = styled.div`
  display: grid;
  grid-gap: 15px 0;
`

const query = graphql`
  {
    page: prismicHomepage {
      data {
        title: care_options_title
        buttonLabel: care_options_button_label
        buttonLink: care_options_button_link {
          uid
          type
        }
        buttonLabel2: care_options_2nd_button_label
        buttonLink2: care_options_2nd_button_link {
          uid
          type
        }
        options {
          title: option_title
          icon: option_icon {
            url
          }
        }
      }
    }
  }
`
