// @flow

import React from 'react'
import styled from '@emotion/styled'
import { MediaAspectRatio, FullPseudoBlockStyles } from '@/styles/shared'
import HeroMask from '@/assets/svgr/HeroMask'
import LeafPatternBackground from '@/styles/shared/leaf-pattern-background'

type Props = {
  src: String,
  alt: String,
  children: React.ReactNode,
}

export default ({ src, alt, children }: Props) => (
  <>
    <HeroMask />
    <MediaBlock>
      <AspectRatio>
        <ImageWrapper>
          <img loading="lazy" src={src} alt={alt} />
        </ImageWrapper>
      </AspectRatio>
      {children}
    </MediaBlock>
  </>
)

const MediaBlock = styled.div`
  grid-area: media;
  position: relative;
`

const AspectRatio = styled.div`
  ${MediaAspectRatio};
  width: calc(100% - var(--d-gutter));
  padding-bottom: 80%;
  background-clip: padding-box;

  img {
    clip-path: url(#hero-mask_svg__hero-mask);
  }

  ${LeafPatternBackground()};
`

const ImageWrapper = styled.div`
  ${FullPseudoBlockStyles};
  filter: drop-shadow(0 15px 25px rgba(0, 0, 0, 0.15));
  z-index: 1;
`
