import React from 'react'
import { string } from 'yup'
import { Input } from '@/components/Form'

const Component = () => {
  return (
    <Input
      as="textarea"
      rows="4"
      label="Comments"
      id="comments"
      name="comments"
      placeholder="Enter your questions/comments here"
    />
  )
}

const schema = string()

const initialValue = ''

export default {
  Component,
  schema,
  initialValue,
}
