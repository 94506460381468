// @flow

import React from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'
import { TitleStyles, AccentRuleStyles } from '@/styles/shared'
import { LinkButton } from '@/components/Button'

type Props = {
  next?: Object,
  previous?: Object,
  children: React.ReactNode,
  headerText: String,
}

export default ({ next, previous, children, headerText = 'Blog' }: Props) => (
  <div>
    <Title>{headerText}</Title>
    {children}
    <BlogHeaderNav>
      {next && (
        <BlogHeaderNavLink to={`/news/${next.uid}`} rel="next">
          ← Newer
        </BlogHeaderNavLink>
      )}
      {previous && (
        <BlogHeaderNavLink to={`/news/${previous.uid}`} rel="prev">
          Older →
        </BlogHeaderNavLink>
      )}
    </BlogHeaderNav>
  </div>
)

const Title = styled.h2`
  ${TitleStyles};
  ${AccentRuleStyles({ justify: 'left' })};

  font-weight: 600;
  font-size: ${rem('80px')};
  letter-spacing: ${rem('-1.5px')};
  display: inline-block;

  margin: 0 0 3rem;
`

const BlogHeaderNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin: calc(var(--d-gutter) * 2) 0 0;
`

const BlogHeaderNavLink = styled(LinkButton)`
  font-size: ${rem('14px')};
  text-align: center;
  padding: 12px 16px;
`
