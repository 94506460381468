// @flow

import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { rem } from 'polished'
import { mq } from '@/styles/theme'
import LayoutContainer from '@/components/LayoutContainer'
import {
  AccentRuleStyles,
  DescriptionStyles,
  PreHeaderStyles,
  TitleStyles,
} from '@/styles/shared'

type Props = {
  subtitle?: String,
  title: String,
  largeTitle: Boolean,
  titleAccent: Boolean,
  description: String,
  mediaBlock: React.ReactNode,
  hero: Boolean,
  wrapperBackground?: Boolean,
}

export default ({
  subtitle,
  title,
  titleAccent,
  largeTitle,
  description,
  mediaBlock,
  hero,
  wrapperBackground,
  ...rest
}: Props) => (
  <Wrapper className={wrapperBackground ? 'background' : ''} {...rest}>
    <FeatureLayoutContainer>
      <Copy>
        {subtitle && <PreHeader>{subtitle}</PreHeader>}
        {hero && title && (
          <Title titleAccent={titleAccent} largeTitle={largeTitle}>
            {title}
          </Title>
        )}
        {!hero && <NonHeroTitle>{title}</NonHeroTitle>}
        {description && <Description>{description}</Description>}
      </Copy>
      {mediaBlock}
    </FeatureLayoutContainer>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 30px 0;

  ${mq.greaterThan('small')`
    padding: 60px 0;
  `};

  &.background {
    background: var(--c-veryLightPink);
  }
`

const FeatureLayoutContainer = styled(LayoutContainer)`
  display: grid;
  align-items: center;
  grid-gap: var(--d-gutter);
  grid-template-areas: 'media' 'copy';

  ${mq.greaterThan('small')`
    grid-template-columns: 4fr 6fr;
    grid-template-areas: 'copy media';
  `};
`

const Copy = styled.div`
  grid-area: copy;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.h1`
  ${TitleStyles};

  font-size: ${rem('46px')};
  letter-spacing: ${rem('-1.5px')};
  margin: 0 0 10px;

  ${p =>
    p.largeTitle &&
    css`
      font-weight: 600;
      font-size: ${rem('56px')};
      letter-spacing: ${rem('-1.15px')};

      ${mq.greaterThan('small')`
        margin: 0 0 var(--d-gutter);
      `};
    `}

  ${p =>
    p.titleAccent &&
    css`
      margin-top: 25px;
      ${AccentRuleStyles({ justify: 'left' })};
    `}
`

const PreHeader = styled.h3`
  ${PreHeaderStyles};
  color: var(--c-lightGreyBlue);
  margin: 0;
`

const NonHeroTitle = Title.withComponent('h2')

const Description = styled.div`
  ${DescriptionStyles};
  color: var(--c-gunmetal);

  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`
