// @flow

import React, { useState } from 'react'
import Modal from 'react-modal'
import Button from '@/components/Button'
import VideoModal from '@/components/VideoModal'

Modal.setAppElement('#___gatsby')

type Props = {
  ctaLabel: Object,
  videoTitle: String,
  videoSrc: String,
  embedHtml?: String,
  className: String,
}

export default ({
  className,
  ctaLabel,
  videoTitle,
  embedHtml,
  videoSrc,
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Button className={className} onClick={() => setModalIsOpen(true)}>
        {ctaLabel}
      </Button>
      <VideoModal
        isOpen={modalIsOpen}
        closeFn={() => setModalIsOpen(false)}
        videoSrc={videoSrc}
        videoTitle={videoTitle}
        embedHtml={embedHtml}
      />
    </>
  )
}
