import React from 'react'
import { string } from 'yup'
import { Input } from '@/components/Form'

const Component = () => (
  <Input
    as="textarea"
    rows="4"
    label="Question"
    id="question"
    name="question"
  />
)

const schema = string()

const initialValue = ''

export default {
  Component,
  schema,
  initialValue,
}
