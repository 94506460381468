// @flow

import React from 'react'
import TourRequestForm from '@/components/Forms/TourRequestForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => (
  <TourRequestForm name="Tour Request - 15 Craigside" action={action} />
)
