// @flow

import React, { useRef } from 'react'
import { Formik, FastField, useFormikContext } from 'formik'
import ReactSelect from 'react-select'
import { rem } from 'polished'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import {
  TitleStyles,
  PreHeaderStyles,
  NoteStyles,
  ResetButtonStyles,
} from '@/styles/shared'
import Button from '@/components/Button'
import { fieldName as NewsletterFieldName } from '@/components/form-fields/NewsletterOptIn'
import { addSubscriberToNewsletter } from '@/lib/functions'

const HONEYPOT_NAME = 'ignore-me'

const Label = styled.label``
const Span = styled.span``

type Props = {
  action: String
  children: React.ReactNode
  name: String
  submitLabel: String
  formikOptions: Object
  className: String
  hideNote: Boolean
  buttonAlignment: String
  debug: Boolean
}

export default ({
  action,
  submitLabel,
  name,
  formikOptions,
  children,
  className,
  hideNote,
  buttonAlignment,
  debug,
}: Props) => {
  const formEl = useRef(null)
  return (
    <Formik
      {...formikOptions}
      onSubmit={(values, formikBag) => {
        if (values[NewsletterFieldName] === true) {
          try {
            return addSubscriberToNewsletter(values).then(() => {
              return formEl.current.submit()
            })
          } catch (e) {
            console.log(error)
          }
        } else {
          return formEl.current.submit()
        }
      }}>
      {({ errors, isSubmitting, handleSubmit, isValid }) => {
        const submitDisabled = errors.length >= 1 || !isValid || isSubmitting
        return (
          <form
            ref={formEl}
            className={['form-component', className].join(' ').trim()}
            action={action}
            method="post"
            name={name}
            onSubmit={handleSubmit}
            data-netlify="true"
            data-netlify-honeypot={HONEYPOT_NAME}>
            <input type="hidden" name="form-name" value={name} />
            <input type="hidden" name="subject" value={name} />
            <Honeypot />
            {children}
            {!hideNote && <Note>*required</Note>}
            <Footer className="form-footer" buttonAlignment={buttonAlignment}>
              <Button
                type="submit"
                disabled={submitDisabled}
                className={submitDisabled && 'disabled'}>
                {isSubmitting ? 'Submitting...' : submitLabel || 'Submit'}
              </Button>
            </Footer>
            {(process.env.NODE_ENV === 'dev' || debug === true) && <Debug />}
          </form>
        )
      }}
    </Formik>
  )
}

interface InputProps {
  label: string
  id: string
  field: React.ReactNode
  required: boolean
  hideLabel: boolean
  name: string
  as: string
}

export const Input = ({
  label,
  id,
  field,
  required,
  name,
  as,
  hideLabel,
  ...rest
}: InputProps) => (
  <>
    <Label className="label-component" htmlFor={id}>
      {!hideLabel && label ? (
        <Span className="label-text" data-required={required}>
          {label}
        </Span>
      ) : null}
      {!field && (
        <FastField
          as={as}
          className={`input-component ${
            as === 'textarea' && 'textarea-component'
          }`}
          required={required}
          id={id}
          name={name}
          {...rest}
        />
      )}
      {field}
    </Label>
  </>
)

type SelectProps = {
  name: String
}

export const Select = (props: SelectProps) => {
  const { name } = props
  const { setFieldValue, setFieldTouched } = useFormikContext()
  return (
    <ReactSelect
      {...props}
      onChange={value => {
        setFieldValue(name, value.value)
      }}
      onBlur={() => setFieldTouched(name, true)}
    />
  )
}

type HoneypotProps = {
  honeypotName: String
}

export const Honeypot = ({ honeypotName }: HoneypotProps) => (
  <HiddenField>
    <label htmlFor={honeypotName || HONEYPOT_NAME}>
      Don’t fill in this field:{' '}
      <input
        id={honeypotName || HONEYPOT_NAME}
        name={honeypotName || HONEYPOT_NAME}
      />
    </label>
  </HiddenField>
)

const HiddenField = styled.div`
  display: none;
`

const Footer = styled.div`
  display: grid;
  justify-content: ${({ buttonAlignment }) =>
    buttonAlignment ? buttonAlignment : 'center'};
`

export const Title = styled.h1`
  ${TitleStyles};
  font-weight: 600;

  ${p =>
    p.large &&
    css`
      font-size: ${rem('48px')};
      letter-spacing: ${rem('-1.5px')};
      line-height: 1.1;

      ${mq.greaterThan('mediumSmall')`
        font-size: ${rem('80px')};
      `};
    `}
`

export const Copy = styled.div`
  color: var(--c-darkGreenBlue);

  a {
    color: var(--c-charcoalGrey);

    &:hover {
      color: var(--c-darkGreenBlue);
    }
  }
`

export const BackButton = styled.button`
  ${ResetButtonStyles};
  ${PreHeaderStyles};
  color: var(--c-canary);
  font-family: var(--f-default);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 10px;

  &:visited {
    color: var(--c-canary);
  }
`

export const Back = props => (
  <BackButton
    onClick={() => {
      if (typeof window !== 'undefined') window.history.back()
    }}
    {...props}
  />
)

export const Note = styled.p`
  ${NoteStyles};
`

export const Debug = () => {
  const { values, ...rest } = useFormikContext()
  return <pre>{JSON.stringify({ values, ...rest }, null, 2)}</pre>
}
