// @flow

import React from 'react'
import { object, string } from 'yup'
import Form from '@/components/Form'
import {
  FirstNameLastName,
  Phone,
  Email,
  Comments,
  NewsletterOptIn,
} from '@/components/form-fields'
import { NewsletterComponent } from './ContactForm'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  phone: Phone.schema.required(),
  comments: Comments.schema,
  email: Email.schema,
  'newsletter-opt-in': NewsletterOptIn.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  phone: Phone.initialValue,
  comments: Comments.initialValue,
  email: Email.initialValue,
  'newsletter-opt-in': NewsletterOptIn.initialValue,
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

type Props = {
  action: String,
}

export default ({ action }: Props) => (
  <Form
    name="Tour Request - Hale Ola Kino"
    action={action}
    submitLabel="Submit"
    formikOptions={formikOptions}>
    <FirstNameLastName.Component />
    <Phone.Component />
    <Comments.Component />
    <NewsletterComponent />
  </Form>
)
