import { css } from '@emotion/core'
import { pxToEm, pxToRem } from './convertors'

export { pxToEm, pxToRem }

/**
 * Default media breakpoints
 * @type {Object}
 */
export const defaultBreakpoints = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px',
}

function getSizeFromBreakpoint(breakpointName, breakpoints = {}) {
  if (breakpoints[breakpointName]) {
    const val = breakpoints[breakpointName]
    if (typeof val === 'number') {
      return `${val}px`
    }
    return val
  } else if (parseInt(breakpointName, 10)) {
    return breakpointName
  }
  console.error(
    'emotion-media-query: No valid breakpoint or size specified for media.'
  )
  return '0'
}

/**
 * Media query generator
 * @param {Object} [defaultBreakpoints] breakpoints - Map labels to breakpoint sizes
 * @return {Object} - Media generators for each breakpoint
 */
export function generateMedia(breakpoints = defaultBreakpoints) {
  const lessThan = breakpoint => (...args) => css`
    @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
      ${css(...args)};
    }
  `

  const greaterThan = breakpoint => (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
      ${css(...args)};
    }
  `

  const between = (firstBreakpoint, secondBreakpoint) => (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(
        firstBreakpoint,
        breakpoints
      )}) and (max-width: ${getSizeFromBreakpoint(
        secondBreakpoint,
        breakpoints
      )}) {
      ${css(...args)};
    }
  `

  return {
    lessThan,
    greaterThan,
    between,
  }
}

/**
 * Media object with default breakpoints
 * @return {object} - Media generators for each size
 */
export const emotionMedia = generateMedia()

/**
 * Usage: emotion.div` ${media.lessThan('medium')`background: #000;`} `;
 * With this code, background for small and medium sizes will be `default` and for more than medium, will be `#000`
 */
