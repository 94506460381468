// @flow

import React from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'
import Link from '@/components/Link'
import { mq } from '@/styles/theme'
import Facebook from '@/assets/svgr/Facebook'
import Instagram from '@/assets/svgr/Instagram'

type Props = {
  subtitle: String,
  title: String,
  cta: Object,
}

export default ({ subtitle, title, cta }: Props) => (
  <Layout>
    <Content>
      <Subtitle>{subtitle}</Subtitle>
      <Title>{title}</Title>
      <CTA to={cta.url} aria-label="footer cta">
        <CTAContent>{cta.label}</CTAContent>
      </CTA>
      <Social>
        <Link
          to="https://www.facebook.com/arcadiafamilyofcompanies/"
          aria-label="footer facebook link">
          <Facebook />
        </Link>
        <Link
          to="https://www.instagram.com/arcadiafamilyofcompanies/"
          aria-label="footer instagram link">
          <Instagram />
        </Link>
      </Social>
    </Content>
  </Layout>
)

const Social = styled.div`
  grid-area: social;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 15px;
  margin-top: 15px;
  justify-content: start;
`

const Layout = styled.div`
  padding: 80px 0;

  ${mq.greaterThan('small')`
    padding: 160px 0;
    grid-area: footer-cta;
    display: flex;
    justify-content: center;
  `};
`

const Content = styled.div`
  color: var(--c-darkerGreenBlue);

  ${mq.greaterThan('small')`
    display: grid;
    align-items: center;
    grid-gap: 0 10px;
    grid-template-areas: 'subtitle subtitle' 'title cta' 'social social';
  `};
`

const Subtitle = styled.p`
  margin: 0 0 5px;
  grid-area: subtitle;
  text-transform: uppercase;
  font: 600 ${rem('16px')} var(--f-sans);
  letter-spacing: ${rem('2px')};
`

const Title = styled.p`
  font: 700 ${rem('70px')} var(--f-condensed);
  text-transform: uppercase;
  margin: 0 0 10px;
  grid-area: title;
  line-height: 1;

  ${mq.greaterThan('small')`
    margin: 0;
  `};
`

const CTA = styled(Link)`
  font: 600 italic ${rem('48px')} var(--f-serif);
  grid-area: cta;
  line-height: 1;
`

const CTAContent = styled.span`
  display: inline-block;
  border-bottom: solid 4px var(--c-canary);
`
