import Prismic from 'prismic-javascript'

export const getClient = async () =>
  Prismic.api(
    `https://${process.env.GATSBY_PRISMIC_REPOSITORY}.prismic.io/api`,
    {
      accessToken: process.env.GATSBY_PRISMIC_FRONTEND_TOKEN,
      fetchLinks: ['my.blog_post.company', 'my.blog_post.categories'],
      pageSize: 100,
    }
  )

export const getCompanies = async () => {
  const api = await getClient()
  return await api.query([Prismic.Predicates.at('document.type', 'company')], {
    orderings: '[my.company.title desc]',
  })
}

export const getCategories = async () => {
  const api = await getClient()
  return await api.query([Prismic.Predicates.at('document.type', 'category')], {
    orderings: '[my.category.title ASC]',
  })
}

export const getBlogPosts = async ({ category, company }) => {
  const api = await getClient()
  const predicates = [Prismic.Predicates.at('document.type', 'blog_post')]
  if (category && category.value) {
    predicates.push(
      Prismic.Predicates.at('my.blog_post.categories.category', category.value)
    )
  }

  if (!category) {
    // hardcoded! Check graphql for ID if this breaks
    predicates.push(
      Prismic.Predicates.at(
        'my.blog_post.categories.category',
        'XH2bvBAAACcAk_Rh'
      )
    )
  }

  if (company && company.value) {
    predicates.push(
      Prismic.Predicates.at('my.blog_post.company', company.value)
    )
  }

  return await api.query(predicates, {
    orderings:
      '[my.blog_post.publish_date desc, document.last_publication_date]',
  })
}
