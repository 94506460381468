// @flow

import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Layout from '@/layouts/CareersLayout'
import Intro from '@/components/Intro'
import PhotoCollage from '@/components/PhotoCollage'
import LayoutContainer from '@/components/LayoutContainer'
import JobDepartment from '@/components/JobDepartment'
import { LinkButton } from '@/components/Button'
import { mq } from '@/styles/theme'

type Props = {
  data: Object,
}

function MeetOurFamilyPage({
  data: {
    page: {
      data: {
        title,
        description: { html: description },
        collage,
        jobDepartments,
      },
    },
  },
}: Props) {
  return (
    <Layout borderedFooter parentLayout>
      <LayoutContainer>
        <Intro
          title={title}
          titleAccent
          titleAccentCenter
          description={
            <>
              <div dangerouslySetInnerHTML={{ __html: description }} />
              <LinkButton to="/careers#jobs">
                Search for currently available jobs
              </LinkButton>
            </>
          }
        />
      </LayoutContainer>
      <PhotoCollage
        css={WorkingHereStyles}
        photos={collage.map(c => c.photo)}
      />
      <LayoutContainer>
        <Departments jobDepartments={jobDepartments} />
      </LayoutContainer>
    </Layout>
  )
}

export default MeetOurFamilyPage

export const query = graphql`
  {
    page: prismicMeetOurFamily {
      data {
        title
        description {
          html
        }
        collage {
          photo: collage_photo {
            url
          }
        }
        jobDepartments: job_categories {
          photo: job_category_photo {
            url
          }
          title: job_category_title
          description: job_category_description {
            html
          }
        }
      }
    }
  }
`

type DepartmentsProps = {
  jobDepartments: Array,
}

const Departments = ({ jobDepartments }: DepartmentsProps) => (
  <>
    {Array.isArray(jobDepartments) && jobDepartments.length && (
      <JobDepartments>
        {jobDepartments.map(
          (
            {
              title,
              description: { html: description },
              photo: { url: photo },
            },
            i
          ) => (
            <JobDepartment
              key={`testimonial-${i}`}
              reverseLayout={i % 2}
              title={title}
              description={description}
              imageSrc={photo}
            />
          )
        )}
      </JobDepartments>
    )}
  </>
)

const JobDepartments = styled.div`
  display: grid;
  grid-gap: 60px 0;
  margin: 60px 0;

  ${mq.greaterThan('small')`
    grid-gap: 130px 0;
    margin: 130px 0;
  `};
`

const WorkingHereStyles = css`
  grid-template-columns: 1fr 1fr 2fr;

  img {
    &:nth-of-type(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-of-type(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    &:nth-of-type(3) {
      grid-area: 1 / 3 / 3 / 4;
    }
    &:nth-of-type(4) {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
`
