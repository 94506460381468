import React from 'react'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import GeneralLayout from '@/layouts/GeneralLayout'
import { HeroImage } from '@/styles/general'

const PHOTO_LAYOUT_UIDS = ['board-of-directors', 'executive-leadership']

const ContentWithPhotosLayoutStyles = css`
  .body {
    &__image {
      float: left;
      margin-right: 30px;
      margin-bottom: 15px;
      max-width: 200px;
    }
  }
`

type Props = {
  data: Object,
  pageContext: Object,
}

function PageTemplate({
  pageContext: { uid },
  data: {
    page: {
      data: {
        title: { html: title },
        heroPhoto: { url: heroPhoto },
        body,
      },
    },
  },
  ...rest
}: Props) {
  const content = body.reduce((acc, slice) => {
    if (slice.slice_type === 'text') {
      return acc.concat(slice.primary.text.html)
    }
    if (slice.slice_type === 'image') {
      return acc.concat(
        `<img class="body__image" src="${slice.primary.image.url}" alt="" />`
      )
    }

    return acc
  }, '')

  return (
    <GeneralLayout
      css={PHOTO_LAYOUT_UIDS.includes(uid) && ContentWithPhotosLayoutStyles}
      {...rest}>
      {heroPhoto && <HeroImage src={heroPhoto} alt="" />}
      <div dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </GeneralLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query ($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      data {
        title {
          html
        }
        heroPhoto: hero_photo {
          url
        }
        body {
          ... on PrismicPageDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`
