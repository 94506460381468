import React from 'react'
import { rem } from 'polished'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { TitleStyles, AccentRuleStyles } from '@/styles/shared'
import slugify from 'slugify'
import LayoutContainer from '@/components/LayoutContainer'

type Props = {
  title: String,
  children: React.ReactNode,
  id: String,
}

export default ({ title, id, children }: Props) => (
  <ContentSection id={id || slugify(title, { lower: true })}>
    <Title>{title}</Title>
    {children}
  </ContentSection>
)

const ContentSection = styled(LayoutContainer)`
  display: grid;
  grid-gap: 40px 0;
`

export const Content = styled.div`
  background: #fff;
  padding: 65px 50px;
  display: grid;
  grid-template-columns: 3fr 10fr;
  grid-gap: 0 var(--d-gutter);
`

const Title = styled.h2`
  ${TitleStyles};
  ${AccentRuleStyles({ justify: 'left' })};
  margin-top: 125px;
  margin-bottom: 0;
`

export const Question = styled.p`
  margin: 0;
  color: var(--c-gunmetal);
  font: 800 ${rem('30px')} var(--f-serif);
  line-height: 1.2;
`

export const ColHeader = styled.p`
  color: var(--c-gunmetal);
  font: bold ${rem('20px')} var(--f-serif);
  margin: 0 0 1rem;
  letter-spacing: ${rem('1.67px')};
  text-transform: uppercase;
`

export const ContentSections = styled.div`
  padding-bottom: 100px;
`

export const Answer = styled.div`
  ${p =>
    p.split &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 var(--d-gutter);
    `}

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.large {
      font-size: ${rem('24px')};
    }

    ${p =>
      p.large &&
      css`
        font-size: ${rem('24px')};
      `};
  }
`
