import React from 'react'
import { css, Global } from '@emotion/core'
import LayoutContainer from '@/components/LayoutContainer'
import CareersLayout from '@/layouts/CareersLayout'
import LeafPattern from '@/assets/leaf-pattern.png'
import { SplitLayoutStyles } from '@/styles/shared'

type Props = {
  layoutCss: String,
  children?: React.ReactNode,
}

export default ({ children, ...rest }: Props) => (
  <CareersLayout headerOverlay disableFooter {...rest}>
    <Global styles={FormGlobalStyles} />
    <LayoutContainer css={SplitLayoutStyles}>{children}</LayoutContainer>
  </CareersLayout>
)

const FormGlobalStyles = css`
  body {
    background: var(--c-lightGreyBlue) url(${LeafPattern});
  }
`
