// @flow

import React from 'react'
import { rem } from 'polished'
import styled from '@emotion/styled'
import Intro from '@/components/Intro'
import MasonryItem from '@/components/MasonryItem'
import LayoutContainer from '@/components/LayoutContainer'
import { mq } from '@/styles/theme'

type Props = {
  title: String,
  description: String,
  groups: Array,
  id: String,
}

export default ({ title, description, groups, id }: Props) => (
  <MasonryListContainer id={id}>
    <Intro title={title} titleAccent description={description} />
    <MasonryGroups>
      {groups &&
        groups.map(({ title, items }) => (
          <div key={title}>
            <GroupTitle>{title}</GroupTitle>
            {items &&
              items.map(item => <MasonryItem key={item}>{item}</MasonryItem>)}
          </div>
        ))}
    </MasonryGroups>
  </MasonryListContainer>
)

const MasonryListContainer = styled(LayoutContainer)`
  margin: 60px auto;

  ${mq.greaterThan('medium')`
    margin: 60px auto 180px;
  `};
`

const MasonryGroups = styled.div`
  display: grid;
  grid-gap: var(--d-gutter);

  ${mq.greaterThan('medium')`
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  `};
`

const GroupTitle = styled.p`
  font-size: ${rem('24px')};
  color: var(--c-gunmetal);
`
