// @flow

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@/styles/theme'
import nanoid from 'nanoid'

const _photos = [
  {
    src: 'https://picsum.photos/300/300',
    alt: '',
  },
  {
    src: 'https://picsum.photos/300/301',
    alt: '',
  },
  {
    src: 'https://picsum.photos/300/302',
    alt: '',
  },
  {
    src: 'https://picsum.photos/300/303',
    alt: '',
  },
]

type Props = {
  styles: String,
  photos: Array,
}

export default ({ styles, photos = _photos }: Props) => (
  <>
    {Array.isArray(photos) && photos.length >= 1 && (
      <PhotoCollage css={styles} photosTotal={photos.length}>
        {photos.map(photo => (
          <div key={nanoid()}>
            <img key={photo.url} loading="lazy" src={photo.url} alt="" />
          </div>
        ))}
      </PhotoCollage>
    )}
  </>
)
const dynamicLargeStyles = props => css`
  ${mq.greaterThan('small')`
    grid-template-columns: ${props.photosTotal === 1 ? '1fr' : '2fr 1fr 1fr'};
    min-height: 70vh;
  `};
`

const dynamicSmallStyles = props => css`
  ${mq.lessThan('small')`
    ${
      props.photosTotal === 1
        ? `grid-template-columns: 1fr`
        : `grid-template-columns: 1fr 1fr`
    };
    grid-auto-rows: 50vw;
  `};
`

const PhotoCollage = styled.div`
  display: grid;
  ${dynamicSmallStyles}

  ${dynamicLargeStyles};

  div {
    position: relative;
    width: 100%;

    &:nth-of-type(1) {
      grid-area: 1 / 1 / 3 / 3;

      ${mq.greaterThan('small')`
        grid-area: 1 / 1 / 3 / 2;
      `};
    }
    &:nth-of-type(2) {
      grid-area: 3 / 1 / 4 / 2;

      ${mq.greaterThan('small')`
        grid-area: 1 / 2 / 2 / 3;
      `};
    }
    &:nth-of-type(3) {
      grid-area: 3 / 2 / 4 / 3;

      ${mq.greaterThan('small')`
        grid-area: 1 / 3 / 2 / 4;
      `};
    }

    &:nth-of-type(4) {
      grid-area: 4 / 1 / 5 / 3;

      ${mq.greaterThan('small')`
        grid-area: 2 / 2 / 3 / 4;
      `};
    }

    &:only-of-type {
      img {
        object-fit: contain;
        margin: 1rem 0;
      }
    }
  }

  img {
    display: block;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`
