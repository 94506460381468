// @flow

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layouts'
import VideoCategoriesSection from '@/components/VideoCategoriesSection'
import { gqlFormatter } from '@/formatters/videos-page'

type Props = {
  data: Object,
}

function VideosPage({ data }: Props) {
  const categories = data.page.nodes.map(
    ({ data: { body: videoCategories } }) => gqlFormatter(videoCategories)
  )[0]
  return (
    <Layout>
      <VideoCategoriesSection categories={categories} />
    </Layout>
  )
}

export default VideosPage

export const query = graphql`
  {
    page: allPrismicVideosPage {
      nodes {
        data {
          body {
            ... on PrismicVideosPageDataBodyCategory {
              id
              items {
                video_title
                video_link
                video_thumbnail_photo {
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
              primary {
                category_title
              }
            }
          }
        }
      }
    }
  }
`
