// @flow

import React from 'react'
import { object } from 'yup'
import CareersFormLayout from '@/layouts/CareersFormLayout'
import Form, { Title, Back } from '@/components/Form'
import {
  FirstNameLastName,
  Phone,
  Email,
  JobInterest,
  Resume,
} from '@/components/form-fields'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  phone: Phone.schema.required(),
  email: Email.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  phone: Phone.initialValue,
  email: Email.initialValue,
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

function SubmitResultPage() {
  return (
    <CareersFormLayout>
      <div>
        <Back>← Back</Back>
        <Title>Submit Your Resume</Title>
      </div>
      <Form
        formikOptions={formikOptions}
        name="Resume Submission"
        action="/careers/submit-resume-complete"
        submitLabel="Submit Resume">
        <FirstNameLastName.Component />
        <Phone.Component />
        <Email.Component />
        <JobInterest.Component />
        <Resume.Component />
      </Form>
    </CareersFormLayout>
  )
}

export default SubmitResultPage
