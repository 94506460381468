import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Input, Select } from '@/components/Form'

const fieldName = 'job-interest'

const Component = () => {
  const { jobs: _jobs } = useStaticQuery(query)
  const jobOptions = _jobs.edges
    .map(j => j.node.data)
    .map(({ title }) => ({ label: title, value: title }))

  return (
    <Input
      label="Job You’re Interested In"
      field={<Select name={fieldName} id={fieldName} options={jobOptions} />}
    />
  )
}

const query = graphql`
  {
    jobs: allPrismicJobPosting(
      sort: { fields: [data___title] }
      filter: { prismicId: { ne: "XunPcRAAAB8Aj3EI" } }
    ) {
      edges {
        node {
          data {
            title
          }
        }
      }
    }
  }
`

export default {
  Component,
}
