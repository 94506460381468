import { css } from '@emotion/core'
import LeafPatternCanary from '@/assets/leaf-pattern-canary.png'
import { FullPseudoBlockStyles } from '@/styles/shared'

export const LeafPatternBackground = (opts = {}) => css`
  &::before {
    ${FullPseudoBlockStyles};
    left: ${opts.left || `calc(var(--d-gutter) * 1.25)`};
    top: ${opts.top || 'var(--d-gutter)'};
    clip-path: url(#hero-mask_svg__hero-mask);
    background: url(${LeafPatternCanary}) center / ${opts.size || '25%'};
    border-radius: inherit;
    z-index: 0;
  }
`

export default LeafPatternBackground
