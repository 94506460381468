// @flow

import React from 'react'
import Modal from 'react-modal'
import { css } from '@emotion/core'
import { AbsolutelyCenter } from '@/styles/shared'
import MediaBlock from '@/components/MediaBlock'
import VideoModalCTA from '@/components/VideoModalCTA'

Modal.setAppElement('#___gatsby')

type Props = {
  src: String,
  alt: String,
  ctaLabel: Object,
  videoTitle: String,
  videoSrc: String,
  embedHtml?: String,
  smallButtonAlign?: Boolean,
}

export default ({
  src,
  alt,
  ctaLabel,
  videoTitle,
  embedHtml,
  videoSrc,
  smallButtonAlign,
}: Props) => (
  <MediaBlock src={src} alt={alt}>
    {/* "OFF" value is set via Prismic CMS */}
    {embedHtml !== 'OFF' && (
      <VideoModalCTA
        css={css`
          ${AbsolutelyCenter};
          z-index: 2;
          ${smallButtonAlign &&
          css`
            left: 43%;
          `}
        `}
        ctaLabel={ctaLabel}
        videoTitle={videoTitle}
        embedHtml={embedHtml}
        videoSrc={videoSrc}
      />
    )}
  </MediaBlock>
)
