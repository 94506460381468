var memoize = require('lodash.memoize');

module.exports = {
  linkResolver: (doc, opts) => {
    // check if `opts` has skilled-nursing page flag
    if (opts?.isSkilledNursingPage) {
      return `/skilled-nursing/${doc.uid}`
    }

    switch (doc.type) {
      case 'blog_post':
        return `/news/${doc.uid}/`
      case 'form':
        return `/${doc.uid}/`
      case 'form_submitted':
        return `/${doc.slug}/`
      case 'page':
        return `/${doc.uid}/`
      case 'residence':
        return `/${doc.uid}/`
      case 'residences':
        return `/${doc.uid}/`
      default:
        if (doc?.url && doc.url.includes('mailto:')) {
          return doc.url
        }

        return `/${doc.uid}/`
    }
  }
}