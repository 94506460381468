// @flow

import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  AccentRuleStyles,
  TitleStyles,
  PreHeaderStyles,
  IntroBodyStyles,
} from '@/styles/shared'

type Props = {
  subtitle?: String,
  title: String,
  titleAccent: Boolean,
  titleAccentCenter: Boolean,
  description: String,
  align: String,
  id: String,
  midSection: Boolean,
}

export default ({
  subtitle,
  title,
  titleAccent,
  description,
  align,
  id,
  midSection,
}: Props) => (
  <Intro id={id} align={align} midSection={midSection}>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    <Title align={align} titleAccent={titleAccent}>
      {title}
    </Title>
    {description && (
      <Description midSection={midSection} align={align}>
        {description}
      </Description>
    )}
  </Intro>
)

const Intro = styled.div`
  text-align: center;
  display: grid;
  justify-content: center;
  margin: 0 auto var(--d-gutter);

  ${p =>
    p.midSection &&
    css`
      margin-top: var(--d-gutter);
    `}

  ${p =>
    p.align &&
    p.align === 'center' &&
    css`
      justify-content: center;
      text-align: center;
    `}

  ${p =>
    p.align &&
    p.align === 'left' &&
    css`
      justify-content: flex-start;
      text-align: left;
    `}
`

const Subtitle = styled.h3`
  ${PreHeaderStyles};
  color: var(--c-lightGreyBlue);
`

const Title = styled.h2`
  ${TitleStyles};

  ${p =>
    p.titleAccent &&
    css`
      margin-top: 25px;
      ${AccentRuleStyles()};
    `}

  ${p =>
    p.align &&
    p.align === 'left' &&
    css`
      ${p.titleAccent &&
      css`
        margin-top: 25px;
        ${AccentRuleStyles({ justify: 'left' })};
      `}
    `}
`

const Description = styled.div`
  ${IntroBodyStyles};
  max-width: 800px;

  ${p =>
    p.midSection &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

  ${p =>
    p.align &&
    p.align === 'left' &&
    css`
      text-align: left;
    `}
`
