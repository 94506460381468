// @flow

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'

export default () => {
  const {
    page: {
      data: {
        mosaic: { url: mosaic },
      },
    },
  } = useStaticQuery(query)
  return <Mosaic loading="lazy" src={mosaic} alt="Mosaic of Residents" />
}

const query = graphql`
  {
    page: prismicHomepage {
      data {
        mosaic {
          url
        }
      }
    }
  }
`

const Mosaic = styled.img`
  display: none;

  ${mq.greaterThan('mediumSmall')`
    object-fit: cover;
    width: 100vw;
    display: block;
    margin: 55px 0;
  `};
`
