// @flow

import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Filter from '@/components/Filter'
import { mq } from '@/styles/theme'

type Props = {
  onChange: Function,
  value: Object,
  options: Array,
}

export const DepartmentFilter = ({ onChange, value, options }: Props) => (
  <Filter
    onChange={onChange}
    value={value}
    labelCss={FilterLabelStyles}
    label="Filter by Department"
    id="filter-department"
    options={options}
  />
)

export const CompanyFilter = ({ onChange, value, options }: Props) => (
  <Filter
    onChange={onChange}
    value={value}
    labelCss={FilterLabelStyles}
    label="Filter by Company"
    id="filter-company"
    options={options}
  />
)

export const TypeFilter = ({ onChange, value, options }: Props) => (
  <Filter
    onChange={onChange}
    value={value}
    labelCss={FilterLabelStyles}
    label="Filter by Type"
    id="filter-type"
    options={options}
  />
)

const FilterLabelStyles = css`
  color: #fff;
`

export const FiltersLayout = styled.div`
  display: grid;
  grid-gap: var(--d-gutter);
  margin-bottom: 60px;

  ${mq.greaterThan('small')`
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 25%);
  `}
`
