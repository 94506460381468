// @flow

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Link from '@/components/Link'

export default () => {
  const {
    nav: {
      data: { links },
    },
  } = useStaticQuery(query)

  return (
    <>
      {links.length >= 1 && (
        <FooterNav>
          {links.map(l => {
            const {
              title,
              link: {
                document: { uid: url },
              },
            } = l

            return (
              <NavLink key={`footer-nav-link=${url}`} to={`/${url}`}>
                {title}
              </NavLink>
            )
          })}
        </FooterNav>
      )}
    </>
  )
}

const query = graphql`
  {
    nav: prismicFooter {
      data {
        links {
          title: link_title
          link {
            document {
              ... on PrismicPage {
                data {
                  meta_title
                }
                uid
              }
              ... on PrismicCareers {
                data {
                  meta_title
                }
                uid
              }
            }
          }
        }
      }
    }
  }
`

const FooterNav = styled.nav`
  grid-area: footer-nav;
  justify-self: flex-end;
  align-self: center;
`

const NavLink = styled(Link)`
  &:not(:last-of-type) {
    &::after {
      content: '/';
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`
