import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Formik } from 'formik'
import Fuse from 'fuse.js'
import styled from '@emotion/styled'
import { Input } from '@/components/Form'
import Button from '@/components/Button'
import { JobsContext } from '@/features/jobs/components/JobsProvider'
import { JobsFormatters } from '@/features/jobs/utils'

const initialValues = {
  q: '',
}

const formikOptions = {
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

const fuseOptions = {
  shouldSort: true,
  threshold: 0,
  // location: 0,
  // distance: 100,
  ignoreLocation: true,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  includeScore: true,
  includeMatches: true,
  keys: [
    {
      name: 'title',
      weight: 3,
    },
    // {
    //   name: 'simpleDescription',
    //   weight: 2,
    // },
    // 'jobDepartments',
    // 'jobTypes',
    // 'companies',
    'keywords',
  ],
}

interface JobSearchProps {
  setResults: Dispatch<SetStateAction<unknown[]>>
}

function JobSearch({ setResults }: JobSearchProps) {
  const { jobs: _jobs } = useContext(JobsContext)
  const jobs = JobsFormatters.shapeJobs(
    _jobs?.map(job => ({ prismicId: job.prismicId, ...job.data }))
  )

  const onSubmit = (values: { q: string }) => {
    if (!jobs || jobs.length < 1) {
      console.warn('No jobs to search')
      return
    }

    if (!values.q || values.q.length < 1) {
      console.warn('Empty search, returning no results')
      setResults([])
      return
    }

    const fuse = new Fuse(jobs, fuseOptions)
    // get results and remove duplicates based on url
    const fuseResults = fuse.search(values.q).filter((job, i, self) => {
      return (
        self.findIndex(_job => _job.item.prismicId === job.item.prismicId) === i
      )
    })
    if (fuseResults.length < 1) {
      setResults(fuseResults as unknown[])
    }
    setResults(fuseResults.map(result => result.item))
  }

  return (
    <section id="job-search">
      <Formik {...formikOptions} onSubmit={onSubmit}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <SearchField>
                <Input
                  type="search"
                  name="q"
                  placeholder="Search for a job"
                  onInput={handleSubmit}
                />
                {/* <Button type="submit">Search</Button> */}
              </SearchField>
            </form>
          )
        }}
      </Formik>
    </section>
  )
}

export { JobSearch }

const SearchField = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 10px;
  margin: 30px auto;

  > label {
    margin: 0;
    width: 100%;
  }

  [type='search'] {
    margin: 0;
  }

  [type='submit'] {
    padding-block: 13px;
  }
`
