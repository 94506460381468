// @flow

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import { mq } from '@/styles/theme'
import { LinkButton } from '@/components/Button'
import LayoutContainer from '@/components/LayoutContainer'
import { AccentRuleStyles, IntroBodyStyles } from '@/styles/shared'
import LeafPattern from '@/assets/leaf-pattern.png'

type Props = {
  title: String,
  body: String,
  cta: Object,
}

export default ({ title, body, cta }: Props) => (
  <LayoutContainer css={LayoutStyles}>
    <Title>{title}</Title>
    <Body>{body}</Body>
    <LinkButton className="wide" to={cta.url}>
      {cta.label}
    </LinkButton>
  </LayoutContainer>
)

const LayoutStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  padding-top: 30px;
  margin-top: 30px;

  ${mq.greaterThan('small')`
    margin-top: -25px;
    margin-bottom: 130px;
  `};

  &::before {
    background: var(--c-lightGreyBlue) url(${LeafPattern});
    content: '';
    display: block;
    width: 100vw;
    height: calc(100% + 80px + 80px + 64px);
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: -30px;

    ${mq.greaterThan('small')`
      height: calc(100% + 360px + 175px + 64px);
      top: -257px;
    `};
  }
`

const Title = styled.h1`
  ${AccentRuleStyles({ color: 'var(--c-canary)' })};
  color: var(--c-gunmetal);
  font: 600 ${rem('56px')} var(--f-serif);
  letter-spacing: ${rem('-1.5px')};
  line-height: 1;
  text-align: center;
  margin: 0 0 15px 0;

  ${mq.greaterThan('small')`
    font-size: ${rem('80px')};
  `};
`

const Body = styled.p`
  ${IntroBodyStyles};
`
