import * as React from 'react'
const SvgLogoArcadiaFamilyOfCompanies = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 1.41421,
    }}
    viewBox="0 0 689 160"
    {...props}>
    <path
      d="M.974.033c.051.073.025.18-.059.238L.583.504a.275.275 0 0 1-.029.017l.218-.704-.678-.032.323-.226C.5-.499.609-.488.66-.415l.314.448Z"
      style={{
        fill: 'url(#logo-arcadia-family-of-companies_svg__a)',
        fillRule: 'nonzero',
      }}
      transform="scale(-206.29993 206.29993) rotate(-55 .375 1.107)"
    />
    <path
      d="m.455.324.003.004A.155.155 0 0 1 .341.267C.392.186.518-.055.436-.328l.22.008-.201.644Z"
      style={{
        fill: 'url(#logo-arcadia-family-of-companies_svg__b)',
        fillRule: 'nonzero',
      }}
      transform="scale(-206.29519 206.29519) rotate(-55 .578 1)"
    />
    <path
      d="M-.033-.101h.452c.058 0 .109.035.137.088L.348.259s-.243-.06-.381-.36Z"
      style={{
        fill: 'url(#logo-arcadia-family-of-companies_svg__c)',
        fillRule: 'nonzero',
      }}
      transform="matrix(206.29517 0 0 -206.29517 59.624 138.896)"
    />
    <path
      d="M0 16.897-20.585-9.697l-22.308 28.854a9.64 9.64 0 0 0 1.924 4.42h.251l20.413-26.423 6.571 8.667c-13.398 4.429-19.602 16.416-21.462 20.765.049 0 .097.008.146.008h4.681c6.864-14.832 18.871-17.837 18.871-17.837L-1.18 22.221A10.063 10.063 0 0 0 0 17.461v-.564Z"
      style={{
        fill: '#fff',
        fillRule: 'nonzero',
      }}
      transform="translate(179.319 48.924) scale(4.16667)"
    />
    <path
      d="M0-13.894 9.484-1.641H7.536L3.332-7.127S-1.384-5.948-3.845 0h-1.742s2.05-6.204 8.099-8.203L.103-11.382l-7.485 9.688h-2.051L0-13.894Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(252.926 92.695) scale(4.16667)"
    />
    <path
      d="M0-13.894 9.484-1.641H7.535L3.332-7.127S-1.383-5.948-3.844 0h-1.744s2.051-6.204 8.1-8.203L.104-11.382l-7.487 9.688h-2.049L0-13.894Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(475.493 92.695) scale(4.16667)"
    />
    <path
      d="M0-13.894 9.482-1.641H7.535L3.332-7.127S-1.385-5.948-3.846 0h-1.742s2.051-6.204 8.1-8.203l-2.41-3.179-7.485 9.688h-2.051L0-13.894Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(648.726 92.695) scale(4.16667)"
    />
    <path
      d="M0 .922c-1.743.103-7.741 0-7.741 0l.052-4.87s6.202-.051 7.894 0c1.692.154 2.615 1.384 2.615 2.41C2.82-.513 1.743.819 0 .922m4.613-2.46c0-2.461-2.819-3.691-4.305-3.691h-9.895l.052 11.38h1.794V2.203h5.331L.564 6.151h1.947L-.513 2.255c2.461 0 5.126-1.333 5.126-3.793"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(341.997 60.225) scale(4.16667)"
    />
    <path
      d="m0-10.663-.871.974s-3.041-1.196-5.963-.12c-2.922 1.077-4.033 2.735-4.033 4.478 0 1.742 1.59 5.075 6.767 5.075 5.178 0 6.459-3.64 6.561-4.409h1.691S3.076.974-4.305.923c-7.383-.258-8.15-5.28-8.15-6.254 0-.974.785-5.964 8.355-6.152 2.358-.154 4.1.82 4.1.82"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(422.943 83.932) scale(4.16667)"
    />
    <path
      d="M0 .667c-.203 2.819-4.049 3.947-5.484 3.999-1.436.051-5.037-.013-5.037-.013l-.038-8.651s4.153-.051 5.69 0C-3.33-3.947.229-2.456 0 .667m-5.33-5.998c-3.947 0-7.074.052-7.074.052V5.947s3.793.051 6.765.051c2.975 0 7.254-2.102 7.358-5.434.101-3.332-3.102-5.793-7.049-5.895"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(575.238 60.434) scale(4.16667)"
    />
    <path
      d="M193.9 44.039h1.641v11.175H193.9z"
      style={{
        fill: '#231f20',
      }}
      transform="matrix(-4.16667 0 0 4.16667 1407.514 -145.487)"
    />
    <path
      d="M0-5.392h-2.388v2.561h2.34v.674h-2.34V.674h-.934v-6.74H0v.674Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(229.147 134.47) scale(4.16667)"
    />
    <path
      d="M0 1.424a39.965 39.965 0 0 1-1.001-3.08h-.02C-1.367-.443-1.724.49-2.089 1.424H0Zm-.52-4.082 2.513 6.74H.915L.222 2.098h-2.571l-.722 1.984h-.906l2.745-6.74h.712Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(247.455 120.272) scale(4.16667)"
    />
    <path
      d="M0 6.74h-.934V1.252h-.02c-.269.789-1.8 3.763-2.705 5.488h-.376c-.837-1.725-2.233-4.604-2.493-5.488h-.019V6.74h-.771V0h1.002a227.932 227.932 0 0 1 2.589 5.488C-2.773 3.592-1.82 1.791-.876 0H0v6.74Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(290.476 109.198) scale(4.16667)"
    />
    <path
      d="M123.214 31.389h.934v6.74h-.934z"
      style={{
        fill: '#231f20',
      }}
      transform="translate(-215.158 -21.591) scale(4.16667)"
    />
    <path
      d="M0-6.74h.934v6.066h2.802V0H0v-6.74Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(309.872 137.28) scale(4.16667)"
    />
    <path
      d="M0 6.836V3.918L-2.031.191-1.021 0 .607 3.195 2.378 0l.829.115L.934 3.918v2.918H0Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(333.555 108.798) scale(4.16667)"
    />
    <path
      d="M0-.233c0-1.261-.675-2.695-2.34-2.695-1.666 0-2.34 1.434-2.34 2.695 0 1.319.597 2.928 2.34 2.928C-.598 2.695 0 1.086 0-.233m-5.71 0c0-1.887 1.425-3.369 3.37-3.369 1.944 0 3.369 1.482 3.369 3.369 0 2.013-1.29 3.602-3.369 3.602-2.08 0-3.37-1.589-3.37-3.602"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(386.047 123.726) scale(4.16667)"
    />
    <path
      d="M0-5.392h-2.388v2.561h2.34v.674h-2.34V.674h-.933v-6.74H0v.674Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(410.488 134.47) scale(4.16667)"
    />
    <path
      d="M0-4.449c-.318-.26-1.041-.587-1.763-.587-1.665 0-2.647 1.193-2.647 2.774C-4.41-.559-3.236.587-1.763.587c.732 0 1.31-.231 1.704-.501l.367.559c-.453.307-1.252.616-2.119.616-2.407 0-3.629-1.647-3.629-3.505 0-1.694 1.329-3.466 3.745-3.466.963 0 1.636.336 2.08.684L0-4.449Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(450.705 132.507) scale(4.16667)"
    />
    <path
      d="M0-.233c0-1.261-.674-2.695-2.34-2.695-1.665 0-2.34 1.434-2.34 2.695 0 1.319.598 2.928 2.34 2.928C-.597 2.695 0 1.086 0-.233m-5.709 0c0-1.887 1.425-3.369 3.369-3.369 1.945 0 3.371 1.482 3.371 3.369 0 2.013-1.291 3.602-3.371 3.602-2.079 0-3.369-1.589-3.369-3.602"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(480.276 123.726) scale(4.16667)"
    />
    <path
      d="M0 6.74h-.934V1.252h-.019c-.27.789-1.801 3.763-2.706 5.488h-.376c-.837-1.725-2.233-4.604-2.493-5.488h-.019V6.74h-.771V0h1.002a224.167 224.167 0 0 1 2.589 5.488C-2.773 3.592-1.82 1.791-.877 0H0v6.74Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(521.376 109.198) scale(4.16667)"
    />
    <path
      d="M0 6.74h-.935V0H.761c1.425 0 2.301.635 2.301 1.945 0 1.136-.973 2.166-2.485 2.166H.279v-.616l.308-.01c.905-.048 1.474-.529 1.474-1.53 0-.742-.415-1.281-1.415-1.281H0V6.74Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(533.022 109.198) scale(4.16667)"
    />
    <path
      d="M0 1.424a40.826 40.826 0 0 1-1.002-3.08h-.019C-1.367-.443-1.724.49-2.09 1.424H0Zm-.52-4.082 2.513 6.74H.914L.222 2.098H-2.35l-.721 1.984h-.906l2.745-6.74h.712Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(561.926 120.272) scale(4.16667)"
    />
    <path
      d="M0 6.74h-.731c-1.464-1.753-3.977-4.775-4.381-5.363h-.019V6.74h-.771V0h.693c1.483 1.752 3.967 4.699 4.42 5.373h.019V0H0v6.74Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(599.043 109.198) scale(4.16667)"
    />
    <path
      d="M197.27 31.389h.935v6.74h-.935z"
      style={{
        fill: '#231f20',
      }}
      transform="translate(-215.158 -21.591) scale(4.16667)"
    />
    <path
      d="M0-5.392h-2.387v2.176h2.34v.674h-2.34V.001H.414v.673h-3.735v-6.74H0v.674Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(632.28 134.47) scale(4.16667)"
    />
    <path
      d="M0-4.662c-.328-.24-.762-.48-1.166-.48-.674 0-1.049.394-1.049.914 0 .482.414.837 1.28 1.463C-.01-2.091.529-1.474.529-.607c0 1.068-1.011 1.761-2.09 1.761-.644 0-1.415-.231-1.896-.703l.414-.558c.395.279.828.588 1.463.588.665 0 1.176-.357 1.176-1.108 0-.847-1.012-1.396-1.561-1.801-.558-.433-1.183-.847-1.183-1.8 0-.905.876-1.588 1.925-1.588.702 0 1.289.25 1.665.674L0-4.662Z"
      style={{
        fill: '#231f20',
        fillRule: 'nonzero',
      }}
      transform="translate(652.551 132.951) scale(4.16667)"
    />
    <defs>
      <linearGradient
        id="logo-arcadia-family-of-companies_svg__a"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(1 0 0 -1 0 .021)"
        gradientUnits="userSpaceOnUse">
        <stop
          offset={0}
          style={{
            stopColor: '#569f48',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.48}
          style={{
            stopColor: '#457fbe',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.64}
          style={{
            stopColor: '#3f77bc',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.96}
          style={{
            stopColor: '#2e3182',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#2e3182',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="logo-arcadia-family-of-companies_svg__b"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(1 0 0 -1 0 0)"
        gradientUnits="userSpaceOnUse">
        <stop
          offset={0}
          style={{
            stopColor: '#569f48',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.48}
          style={{
            stopColor: '#457fbe',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.64}
          style={{
            stopColor: '#3f77bc',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.96}
          style={{
            stopColor: '#2e3182',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#2e3182',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="logo-arcadia-family-of-companies_svg__c"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="scale(-1 1) rotate(55 0 -.426)"
        gradientUnits="userSpaceOnUse">
        <stop
          offset={0}
          style={{
            stopColor: '#569f48',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.48}
          style={{
            stopColor: '#457fbe',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.64}
          style={{
            stopColor: '#3f77bc',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.96}
          style={{
            stopColor: '#2e3182',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#2e3182',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgLogoArcadiaFamilyOfCompanies
