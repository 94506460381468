// @flow

import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Intro from '@/components/Intro'
import LayoutContainer from '@/components/LayoutContainer'
import { MediaAspectRatio } from '@/styles/shared'
import LeafPattern from '@/assets/leaf-pattern.png'

type Props = {
  location?: String,
  subtitle?: String,
  title: String,
  description: String,
  mapSrc: String,
  id: String,
}

const _mapSrc =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3716.699056347984!2d-157.8511678842775!3d21.322904585839748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c006dd3c4cd14f7%3A0x24115e3ea7bf73c9!2s15+Craigside!5e0!3m2!1sen!2sus!4v1540868214697'

type MapProps = {
  mapSrc: String,
  location: String,
}

const Map = ({ mapSrc, location }: MapProps) => (
  <div css={MapStyles}>
    <LayoutContainer>
      <AspectRatio>
        <iframe
          title={`Map for ${location || 'residence'}`}
          src={mapSrc}
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </AspectRatio>
    </LayoutContainer>
  </div>
)

const Location = ({
  location,
  subtitle,
  title,
  description,
  mapSrc,
  id,
}: Props) => (
  <>
    <Intro
      id={id}
      subtitle={id || subtitle}
      title={title}
      description={description}
      midSection
    />
    <Map mapSrc={mapSrc || _mapSrc} location={location} />
  </>
)

export default Location

const MapStyles = css`
  background: var(--c-lightGreyBlue) url(${LeafPattern});
  padding: 40px 0;
`

const AspectRatio = styled.div`
  ${MediaAspectRatio};
`
