// @flow

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { rem } from 'polished'
import FooterNav from '@/components/FooterNav'
import FooterCta from '@/components/FooterCTA'
import { mq } from '@/styles/theme'
import LeafPattern from '@/assets/leaf-pattern.png'

type Props = {
  className?: String,
}

export default ({ className }: Props) => {
  const {
    footer: {
      data: {
        copy: { html: copy },
      },
    },
  } = useStaticQuery(query)

  return (
    <Footer className={className}>
      <FooterCta
        subtitle="So What's Next?"
        title="Let's Connect"
        cta={{ url: '/contact', label: 'Contact Us' }}
      />
      <FooterCredits dangerouslySetInnerHTML={{ __html: copy }} />
      <FooterNav />
    </Footer>
  )
}

const query = graphql`
  {
    footer: prismicFooter {
      data {
        copy {
          html
        }
      }
    }
  }
`

const Footer = styled.footer`
  padding: 45px var(--d-gutter) 90px;
  background: var(--c-lightGreyBlue) url(${LeafPattern});
  color: var(--c-darkerGreenBlue);
  font-size: ${rem('14px')};
  font-weight: 600;
  letter-spacing: ${rem(0.5)};

  ${mq.greaterThan('small')`
    padding: 45px 55px;
    display: grid;
    grid-gap: 0 60px;
    grid-template-areas: 'footer-cta footer-cta' 'footer-credits footer-nav';
  `};

  &.bordered {
    border: solid 15px #fff;
  }

  a {
    color: inherit;
  }
`

const FooterCredits = styled.div`
  margin: 0;

  ${mq.greaterThan('small')`
    grid-area: footer-credits;
  `};

  a {
    text-decoration: underline;
  }
`
