// @flow

import React from 'react'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '@/layouts/CareersLayout'
import LayoutContainer from '@/components/LayoutContainer'
import Options from '@/components/Options'
import MasonryListSection from '@/components/MasonryListSection'
import { NoteStyles } from '@/styles/shared'

type Props = {
  data: Object,
}

function BenefitsPage({
  data: {
    page: {
      data: {
        preheader,
        title,
        overview: { html: overview },
        benefits,
        additionalBenefitsTitle,
        additionalBenefits,
        disclaimer: { html: disclaimer },
      },
    },
  },
}: Props) {
  return (
    <Layout borderedFooter parentLayout parentLayoutBg>
      <LayoutContainer>
        <Options
          layout="detailed"
          subtitle={preheader}
          title={title}
          description={<div dangerouslySetInnerHTML={{ __html: overview }} />}
          options={benefits.map(({ icon: { url: icon }, title }) => ({
            icon: { src: icon },
            title,
          }))}
        />
        <MasonryListSection
          title={additionalBenefitsTitle}
          items={additionalBenefits.map(b => b.item)}
          css={MasonryListSectionStyles}
        />
        <Note dangerouslySetInnerHTML={{ __html: disclaimer }} />
      </LayoutContainer>
    </Layout>
  )
}

export default BenefitsPage

export const query = graphql`
  {
    page: prismicBenefits {
      data {
        preheader
        title
        overview {
          html
        }
        benefits {
          title: benefit_title
          icon: benefit_icon {
            url
          }
        }
        additionalBenefitsTitle: additional_benefits_title
        additionalBenefits: additional_benefits {
          item: additional_benefit_item
        }
        disclaimer {
          html
        }
      }
    }
  }
`

const MasonryListSectionStyles = css`
  margin-bottom: 0;
`

const Note = styled.div`
  ${NoteStyles};
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`
