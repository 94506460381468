import styled from '@emotion/styled'
import { rem } from 'polished'

export default styled.blockquote`
  color: #fff;
  font-size: ${rem('18px')};
  font-style: italic;
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;
  margin: 40px 0 0;

  p {
    &:first-of-type {
      margin-top: 0;

      &::before {
        content: '“';
      }
    }

    &:last-of-type {
      margin-bottom: 0;

      &::after {
        content: '”';
      }
    }
  }
`
