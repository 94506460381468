import React from 'react'
import nanoid from 'nanoid'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import LayoutContainer from '@/components/LayoutContainer'
import MediaBlockWithVideo from '@/components/MediaBlockWithVideo'
import { TitleStyles, AccentRuleStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'

type Props = {
  categories: Array,
}

export default ({ categories }: Props) => {
  return (
    <Wrapper className="background">
      {categories &&
        categories.map(({ items, category_title }) => (
          <LayoutContainer
            key={nanoid()}
            css={css`
              margin-bottom: calc(var(--d-gutter) * 2);
            `}>
            <Title>{category_title}</Title>
            <VideoRow css={categoryLayoutStyles}>
              {items.map(
                ({ video_link, video_title, video_thumbnail_photo }) => (
                  <VideoBox key={nanoid()}>
                    <Title
                      titleAccent
                      css={css`
                        margin-bottom: 0;
                        font-size: 30px !important;
                        min-height: 105px;
                      `}>
                      {video_title}
                    </Title>
                    {/* <MediaBlock thumbnail={{ ...video_thumbnail_photo }} /> */}
                    <MediaBlockWithVideo
                      src={video_thumbnail_photo.url}
                      alt=""
                      ctaLabel="Watch Video"
                      videoTitle="Embedded Video"
                      embedHtml={video_link}
                      smallButtonAlign
                    />
                  </VideoBox>
                )
              )}
            </VideoRow>
          </LayoutContainer>
        ))}
    </Wrapper>
  )
}

const VideoRow = styled.div``

const VideoBox = styled.div``

const categoryLayoutStyles = css`
  display: grid;
  grid-gap: var(--d-gutter);
  grid-template-columns: 1fr;
  grid-template-rows: calc(1fr + 60px);
  grid-auto-flow: row;
  align-items: bottom;

  padding-left: var(--d-gutter);
  margin-bottom: calc(var(--d-gutter) * 4);

  ${mq.greaterThan('medium')`
    grid-template-columns: repeat(3, 250px);
    grid-template-rows: 270px;


  `}

  ${mq.greaterThan('large')`
    grid-template-columns: repeat(4, 270px);
    grid-template-rows: 290px;
    grid-row-gap: 8rem;
  `}
`

const Wrapper = styled.div`
  padding: 30px 0;

  ${mq.greaterThan('small')`
    padding: 60px 0;
  `};

  &.background {
    background: var(--c-veryLightPink);
  }
`

const Title = styled.h2`
  ${TitleStyles};
  grid-area: title;
  margin: 0 0 35px;

  ${p =>
    p.titleAccent &&
    css`
      margin-top: 25px;
      ${AccentRuleStyles({ justify: 'left' })};
    `}
`
