import React from 'react'
import { css } from '@emotion/core'
import { rem, darken } from 'polished'
import nanoid from 'nanoid'
import styled from '@emotion/styled'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import theme from '@/styles/theme'
import { mq } from '@/styles/theme'
import { linkResolver } from '@/config/linkResolver'

export const Banner = styled.div`
  width: calc(100vw - 60px);
  margin: 20px 30px 0;
  text-transform: uppercase;
  min-height: 44px;
  background-color: ${theme.colors.canary};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s ease;
  padding: 10px 12px;
  color: var(--c-charcoalGrey);
  cursor: pointer;

  p {
    margin: 0;
    font-size: ${rem('14px')};
    font-weight: 600;
    letter-spacing: 0.05rem;
    text-align: center;
    color: var(--c-charcoalGrey);

    ${mq.greaterThan('mediumSmall')`
      font-size: ${rem('16px')};
    `};

    &:hover {
      color: ${darken(0.2, '#9bbec7')};
    }
  }
`

export default () => {
  const { allBanners } = useStaticQuery(query)

  if (!allBanners?.data?.body?.length) {
    return null
  }

  return (
    <>
      {allBanners.data.body
        .filter(banner => banner?.primary?.live1)
        .map(banner => {
          const {
            primary: { background_color, title1, title_link },
          } = banner

          return (
            <Banner
              css={css`
                background-color: ${background_color};
              `}
              key={nanoid()}
              onClick={() => navigate(linkResolver(title_link))}
              dangerouslySetInnerHTML={{ __html: title1?.html }}
            />
          )
        })}
    </>
  )
}

const query = graphql`
  {
    allBanners: prismicBanner {
      id
      prismicId
      data {
        body {
          ... on PrismicBannerDataBodyTopBanner {
            id
            primary {
              background_color
              live1
              title_link {
                uid
                type
              }
              title1 {
                html
              }
            }
          }
        }
      }
    }
  }
`
