export const gqlFormatter = videoCategories => {
  return videoCategories.map(
    ({ items, primary: { category_title }, slice_type }) => {
      return {
        items,
        category_title,
        slice_type,
      }
    }
  )
}
