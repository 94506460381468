import React from 'react'
import CareersFormLayout from '@/layouts/FormLayout'
import { Title } from '@/components/Form'

function SubmitResuleCompletePage() {
  return (
    <CareersFormLayout>
      <div>
        <Title>Thank you for submitting your resume</Title>
        <p>
          If your qualifications meet our requirements for an open position, we
          will contact you.
        </p>
      </div>
    </CareersFormLayout>
  )
}

export default SubmitResuleCompletePage
