import React from "react"
import { graphql } from "gatsby"

function LogoWithPrideTheme (props) {
  return <img {...props}/>
}

export const query = graphql`
  fragment LogoSettings on PrismicGlobalSettings {
    data {
      company_logo {
        alt
        url
      }
    }
  }
`

export default LogoWithPrideTheme
