import React from 'react'
import { string } from 'yup'
import { Input } from '@/components/Form'
import { dynamicCol } from '@/styles/shared'

const Component = () => (
  <div css={dynamicCol}>
    <Input
      label="First Name"
      id="first-name"
      type="text"
      name="first-name"
      required
    />
    <Input
      label="Last Name"
      id="last-name"
      type="text"
      name="last-name"
      required
    />
  </div>
)

const schema = string()

const initialValue = ''

export default {
  Component,
  schema,
  initialValue,
}
