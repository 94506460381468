import React from 'react'
import { Input } from '@/components/Form'

const Component = () => (
  <Input label="Upload your resume" id="resume" type="file" name="resume" />
)

export default {
  Component,
}
