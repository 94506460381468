// @flow

import React from 'react'
import styled from '@emotion/styled'
import Intro from '@/components/Intro'
import MasonryList from '@/components/MasonryList'
import { mq } from '@/styles/theme'

type Props = {
  title: String,
  description: String,
  items: Array,
  id: String,
}

export default ({ title, description, items, id }: Props) => (
  <MasonryListContainer id={id}>
    <Intro title={title} titleAccent description={description} />
    <MasonryList items={items} />
  </MasonryListContainer>
)

const MasonryListContainer = styled.div`
  margin: 60px 0;

  ${mq.greaterThan('small')`
    margin: 60px auto 120px;
  `};
`
