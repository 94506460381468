// @flow

import React from 'react'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import Link from '@/components/Link'
import Button, { LinkButton } from '@/components/Button'
import LayoutContainer from '@/components/LayoutContainer'
import {
  AccentRuleStyles,
  TitleStyles,
  PreHeaderStyles,
  DescriptionStyles,
  AbsoluteFill,
  GradientOverlay,
} from '@/styles/shared'
import LeafPattern from '@/assets/leaf-pattern.png'
import { linkResolver } from '@/config/linkResolver'

type Props = {
  locations: Array,
  factSheetUrl: String,
  isSkilledNursingPage: Boolean,
}

// eslint-disable-next-line no-unused-vars
export default ({
  locations,
  factSheetUrl,
  isSkilledNursingPage = false,
}: Props) => {
  return (
    <>
      {locations.length >= 1 && (
        <Wrapper>
          <Layout>
            <Title>Our Locations</Title>
            <Locations>
              {locations.map(({ location }) => {
                const {
                  document: {
                    data: {
                      title,
                      description: { html: description },
                      ctaLabel,
                      photo: { url: photo },
                      ctaLink,
                    },
                  },
                } = location

                const url = linkResolver(
                  { uid: ctaLink.uid },
                  { isSkilledNursingPage }
                )

                return (
                  <Location key={`loc-${url}`} to={url}>
                    <img loading="lazy" src={photo} alt="" />
                    <Copy>
                      <LocationTitle>{title}</LocationTitle>
                      <LocationDescription
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                      <LocationCTA>{ctaLabel}</LocationCTA>
                    </Copy>
                  </Location>
                )
              })}
            </Locations>
            {factSheetUrl && <CTA to={factSheetUrl}>Download Fact Sheet</CTA>}
          </Layout>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  background: var(--c-lightGreyBlue) url(${LeafPattern});
  padding: 60px 0;
`

const Title = styled.h2`
  ${TitleStyles};
  ${AccentRuleStyles({ color: 'var(--c-canary)' })};
  text-align: center;
  grid-column: 1 / 3;
`

const Layout = styled(LayoutContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Locations = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-flow: column;
  gap: 0 var(--d-gutter);

  ${mq.greaterThan('small')`
    margin-bottom: 60px;
    flex-flow: row;
  `};
`

const Location = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 400px;
  width: 100%;
  margin: 15px 0;
  overflow: hidden;

  ${mq.greaterThan('small')`
    margin: 0;
    align-self: stretch;
    flex: 1;
    min-height: 775px;
  `};

  img {
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 8px 0 rgba(45, 35, 66, 0.15);
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform 0.4s ease-in-out;
    transform: scale(1);
  }

  &:hover {
    img {
      transform: scale(1.08);
    }
  }

  &::after {
    ${GradientOverlay};
  }
`

const Copy = styled.div`
  ${AbsoluteFill};
  padding: 40px 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  width: 100%;
`

const Category = styled.p`
  ${PreHeaderStyles};
  color: #fff;
  margin: 0;
`

const LocationTitle = styled.p`
  ${TitleStyles};
  color: #fff;
  margin-bottom: auto;
`

const LocationDescription = styled.div`
  ${DescriptionStyles};
  color: #fff;
  margin-bottom: 15px;
`

const LocationCTA = styled(Button)`
  background: var(--c-lightGreyBlue);
  color: #fff;
`

const CTA = styled(LinkButton)`
  ${AccentRuleStyles({ top: `-45px`, color: 'var(--c-canary)' })};
  margin-top: 45px;
`
