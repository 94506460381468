// @flow

import React from 'react'
import CareersContactForm from '@/components/Forms/CareersContactForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => <CareersContactForm action={action} />
