// @flow

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layouts'

function FourOhFourPage() {
  return (
    <Layout>
      <h1>Page Not Found</h1>
    </Layout>
  )
}

export default FourOhFourPage

export function Head() {
  return (
    <>
      <title>Page Not Found</title>
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
