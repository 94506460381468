import React from 'react'
import { string } from 'yup'
import { Input, Select } from '@/components/Form'

const interestOptions = [
  { label: '15 Craigside', value: '15 Craigside' },
  {
    label: 'Arcadia Retirement Residence',
    value: 'Arcadia Retirement Residence',
  },
  { label: 'Adult Day Care', value: 'Adult Day Care' },
  { label: 'At Home', value: 'At Home' },
  { label: 'Home Health Care', value: 'Home Health Care' },
  { label: 'Other Questions/Comments', value: 'Other' },
]

const initialValue = ''

const fieldName = 'interested-in'

const schema = string()

const Component = () => (
  <Input
    label="Interested In*"
    field={<Select name={fieldName} id={fieldName} options={interestOptions} />}
  />
)

export default {
  Component,
  initialValue,
  schema,
}
