// @flow

import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { rem, lighten } from 'polished'
import Link from '@/components/Link'
import { mq } from '@/styles/theme'

type Props = {
  links: Array,
  className?: String,
  mobileMenuOpen: Boolean,
}

export default ({ links, className, mobileMenuOpen }: Props) => (
  <>
    {Array.isArray(links) && links.length && (
      <Nav
        className={className}
        css={
          mobileMenuOpen &&
          css`
            ${mq.lessThan('medium')`
                display: grid !important;
            `};
          `
        }>
        {links.map(link => (
          <NavLink
            key={link.url + link.label}
            to={link.url}
            data-type={link.type}
            css={link.style}>
            {link.label}
          </NavLink>
        ))}
      </Nav>
    )}
  </>
)

const Nav = styled.nav`
  ${mq.lessThan('medium')`
    align-content: start;
    justify-content: center;
    position: absolute;
    background: #fff;
    left: 0;
    top: calc(var(--d-headerHeightMobile) + 64px);
    z-index: 999;
    display: none;
    width: 100%;
    height: fit-content;
    text-align: center;
    padding: 0 var(--d-gutter) var(--d-gutter);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);
  `};

  ${mq.greaterThan('medium')`
    display: grid;
    grid-gap: 0 45px;
    grid-auto-flow: column dense;
    align-items: center;
    justify-content: end;
  `};
`

const NavLink = styled(Link)`
  ${mq.lessThan('medium')`
    display: block;
    font-family: var(--f-serif);
    font-size: ${rem('36px')};
    letter-spacing: ${rem('-0.9px')};
    line-height: 1.1;
    text-align: center;
    padding: 25px 0;
    position: relative;

    && {
      color: var(--c-gunmetal);
    }

    &:not(:last-of-type):not([data-type="fancy"])::after {
      content: '';
      display: block;
      width: 55px;
      height: 2px;
      background: var(--c-lightGreyBlue);
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
    }
  `};

  ${mq.greaterThan('medium')`
    font-size: ${rem('18px')};
    font-weight: 600;
    letter-spacing: ${rem('1.8px')};
    line-height: 1.1;
    text-transform: uppercase;

    && {
      color: var(--c-charcoalGrey);

      &:hover {
        color: ${lighten(0.3, '#354042')};
      }
    }
  `};
`
