import React from 'react'
import { css } from '@emotion/core'
import { useFormikContext } from 'formik'
import { array } from 'yup'
import { graphql, useStaticQuery } from 'gatsby'
import nanoid from 'nanoid'
import { dynamicCol } from '@/styles/shared'
import { mq } from '@/styles/theme'

const checkboxesStyles = css`
  justify-items: flex-start;

  ${mq.greaterThan('small')`
    grid-auto-flow: unset;
    grid-gap: calc(var(--d-gutter) / 3);
  `};
`

const checkboxLabelStyles = css`
  display: grid;
  grid-gap: 0 10px;
  grid-auto-flow: column;
  justify-content: start;
  cursor: pointer;

  input {
    position: relative;
    top: 3px;
  }
`

const fieldName = 'referrals'

const Component = () => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    touched,
    errors,
    submitCount,
  } = useFormikContext()
  const {
    prismicGlobalSettings: {
      data: { formReferralOptions },
    },
  } = useStaticQuery(query)
  return (
    <div>
      <p className="label-text">How did you hear about us?</p>
      <div css={[dynamicCol, checkboxesStyles]}>
        {formReferralOptions.map(({ option }) => (
          <label css={checkboxLabelStyles} key={nanoid()}>
            <input
              type="checkbox"
              name={fieldName}
              onBlur={e => {
                setFieldTouched(fieldName, true)
                handleBlur(e)
              }}
              onChange={e => {
                setFieldTouched(fieldName, true)
                handleChange(e)
              }}
              value={option}
              checked={values[fieldName] && values[fieldName].includes(option)}
            />
            <span>{option}</span>
          </label>
        ))}
      </div>
      {(touched[fieldName] || submitCount >= 1) && errors[fieldName] && (
        <p className="field-error">{errors[fieldName]}</p>
      )}
    </div>
  )
}

// const schema = array().test(
//   fieldName,
//   'Please choose at least one referral',
//   referrals => referrals && referrals.length >= 1
// )

const schema = array()

const query = graphql`
  {
    prismicGlobalSettings {
      data {
        formReferralOptions: form_referral_options {
          option: form_referral_option
        }
      }
    }
  }
`

export default {
  Component,
  schema,
}
