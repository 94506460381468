import * as React from 'react'
const SvgFacebook = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}>
    <defs>
      <path
        id="facebook_svg__a"
        d="M9.156 27.188V14.813h4.172l.625-4.844H9.156V6.884c0-1.4.389-2.353 2.395-2.353h2.543V.211c-.443-.06-1.96-.19-3.726-.19-3.688 0-6.212 2.25-6.212 6.383V9.97H0v4.844h4.156v12.374h5Z"
      />
      <path
        id="facebook_svg__b"
        d="M17.296-4.813h-28.468c-.976.001-1.766.792-1.765 1.767v28.468c0 .976.79 1.766 1.766 1.765H4.156V14.813H0V9.97h4.156V6.404C4.156 2.271 6.68.02 10.368.02c1.765 0 3.283.132 3.726.19v4.321H11.55c-2.006 0-2.395.954-2.395 2.353v3.085h4.797l-.625 4.844H9.156v12.374h8.14a1.766 1.766 0 0 0 1.767-1.766V-3.047c-.001-.976-.792-1.766-1.767-1.765Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(12.938 4.813)">
      <use xlinkHref="#facebook_svg__b" fill="#255D63" />
    </g>
  </svg>
)
export default SvgFacebook
