import React from 'react'
import styled from '@emotion/styled'
import LayoutContainer from '@/components/LayoutContainer'
import { TitleStyles, AccentRuleStyles } from '@/styles/shared'
import LeafPattern from '@/assets/leaf-pattern.png'

interface JobsResultsSectionProps {
  title: string
  children: React.ReactNode
}

export const JobsResultsSection = ({
  title,
  children,
}: JobsResultsSectionProps) => {
  return (
    <JobsContainer id="jobs">
      <JobsLayout>
        <Title>{title}</Title>
        {children}
      </JobsLayout>
    </JobsContainer>
  )
}

const JobsLayout = styled(LayoutContainer)`
  display: grid;
  justify-content: stretch;
`

const JobsContainer = styled.div`
  background: var(--c-darkerGreenBlue) url(${LeafPattern});
  padding: 125px 0 100px;
`

const Title = styled.h2`
  ${TitleStyles};
  ${AccentRuleStyles({ color: 'var(--c-canary)' })};
  text-align: center;
  color: #fff;
  margin: 0 0 60px;
`
