// @flow

import React from 'react'
import { object } from 'yup'
import Form from '@/components/Form'
import { NewsletterComponent } from './ContactForm'
import {
  FirstNameLastName,
  GuestFirstNameLastName,
  GuestAge,
  Phone,
  Email,
  Referrals,
} from '@/components/form-fields'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  'guest-first-name': GuestFirstNameLastName.schema,
  'guest-last-name': GuestFirstNameLastName.schema,
  phone: Phone.schema.required(),
  email: Email.schema,
  referrals: Referrals.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  'guest-first-name': GuestFirstNameLastName.initialValue,
  'guest-last-name': GuestFirstNameLastName.initialValue,
  'guest-age': GuestAge.initialValue,
  phone: Phone.initialValue,
  email: Email.initialValue,
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

type Props = {
  name: String,
  action: String,
}

export default ({ name, action }: Props) => (
  <Form
    name={name}
    action={action}
    submitLabel="Request a Tour"
    formikOptions={formikOptions}>
    <FirstNameLastName.Component />
    <Phone.Component />
    <p>
      If you are requesting a tour for your loved one, please enter their
      information here:
    </p>
    <GuestFirstNameLastName.Component />
    <GuestAge.Component />
    <Referrals.Component />
    <NewsletterComponent />
  </Form>
)
