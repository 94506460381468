import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'
import PhotoFeature from '@/components/PhotoFeature'
import Options from '@/components/Options'
import CareOption from '@/components/CareOption'
import LeafPattern from '@/assets/leaf-pattern.png'
import LayoutContainer from '@/components/LayoutContainer'
import {
  dynamicCol,
  LargeParagraph,
  ListHeaderStyles,
  AccentRuleStyles,
} from '@/styles/shared'
import { LinkButton } from '@/components/Button'

const OptionsStyles = css`
  background: var(--c-veryLightPink);
  padding: 100px 0;
`

type Props = {
  data: Object,
}

function SeniorGuidePage({
  data: {
    page: {
      data: {
        title,
        heroPreheader,
        heroTitle,
        heroPhoto: { url: heroPhoto },
        servicesTitle,
        services,
        careOptions,
        carePreheader,
        careTitle,
        careDescription: { html: careDescription },
        careItems,
        photoFeatureTitle,
        photoFeatureSubtitle,
        photoFeatureDescription: { html: photoFeatureDescription },
        photoFeaturePhoto: { url: photoFeaturePhoto },
      },
    },
  },
}: Props) {
  return (
    <Layout parentLayout>
      <Feature
        hero={true}
        subtitle={heroPreheader}
        title={heroTitle}
        largeTitle
        mediaBlock={<MediaBlock src={heroPhoto} alt="" />}
      />
      <Options
        layout="detailed"
        title={servicesTitle}
        titleAccent
        options={services.map(
          ({
            icon: { url: icon },
            description: { html: description },
            title,
          }) => ({
            icon: { src: icon },
            description,
            title,
          })
        )}
      />
      {careOptions.length >= 1 && (
        <CareOptions>
          <LayoutContainer css={LayoutContainerStyles}>
            <>
              {careOptions.map(
                (
                  {
                    title,
                    description: { html: description },
                    photo: { url: photo },
                    pros: { html: pros },
                    cons: { html: cons },
                    ctaLabel,
                    ctaLink: { url: ctaLink },
                  },
                  index
                ) => (
                  <CareOption
                    key={`care-option-${ctaLink || index}`}
                    title={title}
                    src={photo}
                    ctaLabel={ctaLabel}
                    ctaUrl={ctaLink}>
                    {description && (
                      <LargeParagraph
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                    {pros && cons && (
                      <div css={dynamicCol}>
                        {pros && (
                          <div className="col">
                            <p className="list-header" css={ListHeaderStyles}>
                              Pros
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: pros }} />
                          </div>
                        )}
                        {cons && (
                          <div className="col">
                            <p className="list-header" css={ListHeaderStyles}>
                              Cons
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: cons }} />
                          </div>
                        )}
                      </div>
                    )}
                  </CareOption>
                )
              )}

              <div
                css={css`
                  ${AccentRuleStyles({
                    color: 'var(--c-canary)',
                    top: '-40px',
                  })};
                  margin-top: 75px;
                  text-align: center;
                `}>
                <LinkButton to="/questions">
                  Frequently Asked Questions
                </LinkButton>
              </div>
            </>
          </LayoutContainer>
        </CareOptions>
      )}
      <Options
        layout="detailed"
        css={OptionsStyles}
        subtitle={carePreheader}
        title={careTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: careDescription }} />
        }
        options={careItems.map(
          ({
            title,
            icon: { url: icon },
            description: { html: description },
          }) => ({
            icon: { src: icon },
            description,
            title,
          })
        )}
      />
      <PhotoFeature
        subtitle={photoFeatureSubtitle}
        title={photoFeatureTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: photoFeatureDescription }} />
        }
        img={{ src: photoFeaturePhoto, alt: '' }}
      />
    </Layout>
  )
}

export default SeniorGuidePage

export const query = graphql`
  {
    page: prismicSeniorGuide {
      data {
        title
        heroPreheader: hero_preheader
        heroTitle: hero_title
        heroPhoto: hero_photo {
          url
        }
        servicesTitle: services_title
        services {
          icon: service_icon {
            url
          }
          title: service_title
          description: service_description {
            html
          }
        }
        careOptions: care_options {
          title: care_option_title
          description: care_option_description {
            html
          }
          photo: care_option_photo {
            url
          }
          pros: care_option_pros {
            html
          }
          cons: care_option_cons {
            html
          }
          ctaLabel: care_option_cta_label
          ctaLink: care_option_cta_link {
            uid
            type
          }
        }
        carePreheader: care_preheader
        careTitle: care_title
        careDescription: care_description {
          html
        }
        careItems: care_items {
          title: care_item_title
          icon: care_item_icon {
            url
          }
          description: care_item_description {
            html
          }
        }
        photoFeatureTitle: photo_feature_title
        photoFeatureSubtitle: photo_feature_subtitle
        photoFeatureDescription: photo_feature_description {
          html
        }
        photoFeaturePhoto: photo_feature_photo {
          url
        }
      }
    }
  }
`

const CareOptions = styled.div`
  margin: 60px 0 0;
  background: var(--c-lightGreyBlue) url(${LeafPattern});
  padding: 40px 0 80px;
`

const LayoutContainerStyles = css`
  display: grid;
  grid-gap: var(--d-gutter) 0;
`
