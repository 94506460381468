// @flow

import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import Feature from '@/components/Feature'
import MediaBlockWithVideo from '@/components/MediaBlockWithVideo'
import Options from '@/components/Options'
import { LinkButton } from '@/components/Button'
import ServiceAreas from '@/components/ServiceAreas'
import { mq } from '@/styles/theme'

type Props = {
  data: Object,
}

function AtHomePage({
  data: {
    page: {
      data: {
        title,
        heroTitle,
        heroDescription: { html: heroDescription },
        heroPhoto,
        heroVideo,
	heroPresentationButtonLabel,
	heroPresentationButtonLink,
        heroTestimonialButtonLabel,
	heroTestimonialButtonLink,
        servicesTitle,
        services,
        benefitsTitle,
        benefitsDescription: { html: benefitsDescription },
        benefits,
        serviceAreasTitle,
        serviceAreasMap: { url: serviceAreasMap },
        serviceAreas,
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}: Props) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      const hash = window.location.hash.replace('#', '')
      const sectionEl = document.getElementById(hash)
      if (sectionEl) {
        sectionEl.scrollIntoView()
      }
    }
  }, [])

  const NewsletterButtonURLFromServiceAreas = serviceAreas.filter(
    ({ primary }) => {
      const { serviceAreasButtonLabel } = primary
      if (serviceAreasButtonLabel === 'NEWSLETTER') return true
    }
  )

  return (
    <Layout parentLayout parentLayoutBg>
      <Feature
        hero={true}
        title={heroTitle}
        titleAccent
        largeTitle
        description={
          <>
            <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}>
              <LinkButton
                css={css`
                  margin-top: 20px;
                `}
                to="/at-home-brochure-request">
                Download Brochure
              </LinkButton>
              <LinkButton
                css={css`
                  margin-top: 20px;
                `}
                to={
                  NewsletterButtonURLFromServiceAreas[0].primary
                    .serviceAreasButtonLink.url
                }>
                Newsletter
              </LinkButton>
              <LinkButton
                css={css`
                  margin-top: 20px;
                `}
                to={heroPresentationButtonLink.url}>
		{heroPresentationButtonLabel}
              </LinkButton>
              <LinkButton
                css={css`
                  margin-top: 20px;
                `}
                to={heroTestimonialButtonLink.url}>
		{heroTestimonialButtonLabel}
              </LinkButton>
            </div>
          </>
        }
        mediaBlock={
          <MediaBlockWithVideo
            src={heroPhoto.url}
            alt=""
            ctaLabel="Watch Video"
            videoTitle="Embedded Video"
            embedHtml={heroVideo}
          />
        }
      />
      <Options
        css={OptionsWrapperStyles}
        layout="detailed"
        subtitle="Key Feature"
        title={servicesTitle}
        options={services.map(
          ({
            icon: { url: icon },
            description: { html: description },
            title,
          }) => ({
            icon: { src: icon },
            description,
            title,
          })
        )}
      />
      <Options
        css={OptionsWrapperStyles}
        layoutCss={BenefitOptionsStyles}
        title={benefitsTitle}
        titleAccent
        description={
          <div dangerouslySetInnerHTML={{ __html: benefitsDescription }} />
        }
        options={benefits.map(({ icon: { url: icon }, title }) => ({
          icon: { src: icon },
          title,
        }))}
      />
      <ServiceAreas
        title={serviceAreasTitle}
        mapSrc={serviceAreasMap}
        serviceAreas={serviceAreas}
        config={{
          copyType: 'service_areas_copy',
          buttonType: 'service_areas_button',
        }}
      />
    </Layout>
  )
}

export default AtHomePage

export function Head({
  data: {
    page: {
      data: {
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}) {
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </>
  )
}

export const query = graphql`
  {
    page: prismicAtHome {
      data {
        title
        heroTitle: hero_title
        heroPhoto: hero_photo {
          url
        }
        heroDescription: hero_description {
          html
        }
        heroVideo: hero_video
	heroPresentationButtonLabel: hero_presentation_button_label
        heroPresentationButtonLink: hero_presentation_button_link {
          url
        }
	heroTestimonialButtonLabel: hero_testimonial_button_label
        heroTestimonialButtonLink: hero_testimonial_button_link {
          url
        }
        servicesTitle: services_title
        services {
          icon: service_icon {
            url
          }
          title: service_title
          description: service_description {
            html
          }
        }
        benefitsTitle: benefits_title
        benefitsDescription: benefits_description {
          html
        }
        benefits {
          icon: benefit_icon {
            url
          }
          title: benefit_title
        }
        serviceAreasTitle: service_areas_title
        serviceAreasMap: service_areas_map {
          url
        }
        serviceAreas: service_areas {
          ... on PrismicAtHomeDataServiceAreasServiceAreasCopy {
            primary {
              serviceAreasCopyTitle: service_areas_copy_title {
                html
                text
              }
              serviceAreasCopyBody: service_areas_copy_body {
                html
                text
              }
            }
            slice_type
          }
          ... on PrismicAtHomeDataServiceAreasServiceAreasButton {
            primary {
              serviceAreasButtonLabel: service_areas_button_label
              serviceAreasButtonLink: service_areas_button_link {
                url
              }
            }
            slice_type
          }
        }
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
      }
    }
  }
`

const OptionsWrapperStyles = css`
  margin-bottom: 100px;
`

const BenefitOptionsStyles = css`
  ${mq.greaterThan('small')`
    padding: 0 150px;
  `};
`
