// @flow

import React from 'react'
import { object } from 'yup'
import styled from '@emotion/styled'
import { default as FormikForm } from '@/components/Form'
import { Email, NewsletterOptIn } from '@/components/form-fields'

const validationSchema = object().shape({
  email: Email.schema.required(),
})

const initialValues = {
  email: Email.initialValue,
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

// const Form = css`
//   ${({ textColor }) => textColor && `color: var(${textColor})`}
// `

type Props = {
  name: String,
  action: String,
  submitLabel: String,
}

export default ({ name, action, submitLabel, ...restProps }: Props) => (
  <FormikForm
    name={name}
    action={action}
    submitLabel={submitLabel}
    formikOptions={formikOptions}
    hideNote
    className="blog-category-menu-newsletter-form"
    {...restProps}>
    <P>Sign up to receive our newsletter</P>
    <Email.Component hideLabel={true} />
    <NewsletterOptIn.Component />
  </FormikForm>
)

const P = styled.p`
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 600;
  color: inherit;
  font-family: var(--f-default);
`
