// @flow

import React, { useEffect, useRef, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import { rem } from 'polished'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Layout from '@/layouts'
import SectionNav from '@/components/SectionNav'
import MasonryGroupsSection from '@/components/MasonryGroupsSection'
import PhotoCollage from '@/components/PhotoCollage'
import Feature from '@/components/Feature'
import Location from '@/components/Location'
import MediaBlock from '@/components/MediaBlock'
import MediaBlockWithVideo from '@/components/MediaBlockWithVideo'
import Options from '@/components/Options'
import OffsetAnchor from '@/components/OffsetAnchor'
import LayoutContainer from '@/components/LayoutContainer'
import { mq } from '@/styles/theme'
import { dynamicCol, TitleStyles } from '@/styles/shared'
import { LinkButton } from '@/components/Button'
import Link from '@/components/Link'
import VideoModalCTA from '@/components/VideoModalCTA'
import OverviewLogo from '@/components/OverviewLogo'
import nanoid from 'nanoid'
import { linkResolver } from '@/config/linkResolver'

const fileResolver = link => link.raw?.url

let anchorLinks = [
  {
    label: 'Overview',
    id: 'overview',
  },
  {
    label: 'Location',
    id: 'location',
  },
  {
    label: 'Inclusions',
    id: 'inclusions',
  },
  {
    label: 'How to Enroll',
    id: 'enroll',
  },
]

type Props = {
  data: Object,
}

function ResidencePageTemplate(props: Props) {
  const {
    data: {
      page: {
        data: {
          title,
          overviewCtaLogo,
          overviewCtaLogoLink,
          heroDescription: { html: heroDescription },
          heroPhoto,
          heroVideo,
          heroCtaCopy,
          heroCtas,  
	  adCodeBlock,
	  collage,
          locationTitle,
          locationDescription: { html: locationDescription },
          mapUrl,
          amenitiesTitle,
          amenitiesDescription: { html: amenitiesDescription },
          amenities,
          featuresTitle,
          featuresDescription: { html: featuresDescription },
          featuresPhoto,
          whatsIncludedTitle,
          whatsIncludedDescription: { html: whatsIncludedDescription },
          body,
          supplemental,
        },
      },
    },
  } = props
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      const hash = window.location.hash.replace('#', '')
      const sectionEl = document.getElementById(hash)
      if (sectionEl) {
        sectionEl.scrollIntoView()
      }
    }
  }, [])

  const renderCtas = props => {
    const { label, type, link } = props

    if (type === 'link' || type === 'file') {
      const resolver = type === 'link' ? linkResolver : fileResolver
      return (
        <LinkButton key={`link-button1-${nanoid()}`} to={resolver(link)}>
          {label}
        </LinkButton>
      )
    }

    if (type === 'video') {
      return (
        <VideoModalCTA
          key={`link-butto3n-${nanoid()}`}
          ctaLabel={label}
          videoTitle={label}
          videoSrc={fileResolver(link)}
        />
      )
    }
  }

  const hasInclusions = Boolean(body && body.length)
  const hasSupplemental = supplemental && supplemental.length

  if (!hasInclusions) {
    anchorLinks = anchorLinks.filter(({ label }) => label !== 'Inclusions')
  }

  if (!hasSupplemental) {
    anchorLinks = anchorLinks.filter(({ label }) => label !== 'How to Enroll')
  }

  return (
    <Layout>
      <SectionNav title={title} navLinks={anchorLinks} />
      <OffsetAnchor offset={-100} id="overview">
        <Feature
          css={FeatureStyles}
          hero={true}
          titleAccent
          largeTitle
          description={
            <>
              <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
              <div dangerouslySetInnerHTML={{ __html: adCodeBlock }} />
              {heroCtas && heroCtas.length && (
                <Ctas
                  css={css`
                    margin-top: 20px;
                  `}>
                  {heroCtas.map(renderCtas)}
                  {overviewCtaLogo &&
                    overviewCtaLogoLink &&
                    overviewCtaLogoLink?.url && (
                      <Link to={overviewCtaLogoLink.url}>
                        <OverviewLogo
                          loading="lazy"
                          src={overviewCtaLogo.url}
                          alt="overview-logo"
                        />
                      </Link>
                    )}
                </Ctas>
              )}
            </>
          }
          mediaBlock={
            <>
              {heroVideo && (
                <MediaBlockWithVideo
                  src={heroPhoto.url}
                  alt=""
                  ctaLabel={heroCtaCopy || 'Watch Video'}
                  videoTitle="Embedded Video"
                  embedHtml={heroVideo}
                />
              )}
              {!heroVideo && <MediaBlock src={heroPhoto.url} alt="" />}
            </>
          }
        />
      </OffsetAnchor>
      <PhotoCollage photos={collage.map(c => c.photo)} />
      <OffsetAnchor offset={-100} id="location">
        <Location
          subtitle="Location"
          title={locationTitle}
          description={
            <div dangerouslySetInnerHTML={{ __html: locationDescription }} />
          }
          mapSrc={mapUrl}
        />
      </OffsetAnchor>
      <OffsetAnchor offset={-100} id="amenities">
        <Options
          title={amenitiesTitle}
          titleAccent
          description={
            <div dangerouslySetInnerHTML={{ __html: amenitiesDescription }} />
          }
          options={amenities.map(({ icon: { url: icon }, title }) => ({
            icon: { src: icon },
            title,
          }))}
          layout="split"
        />
      </OffsetAnchor>
      <Feature
        subtitle="Features"
        title={featuresTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: featuresDescription }} />
        }
        mediaBlock={
          <MediaBlock src={featuresPhoto.url} alt="" ctaLabel="Watch Video" />
        }
      />
      {hasInclusions && (
        <OffsetAnchor offset={-100} id="inclusions">
          <MasonryGroupsSection
            title={whatsIncludedTitle}
            description={
              <div
                dangerouslySetInnerHTML={{ __html: whatsIncludedDescription }}
              />
            }
            groups={body.map(slice => ({
              title: slice.primary.title,
              items: slice.items.map(item => item.item),
            }))}
          />
        </OffsetAnchor>
      )}
      {hasSupplemental && (
        <OffsetAnchor offset={-160} id="enroll">
          <BottomContentLayoutContainer>
            <div css={[dynamicCol, BottomContentColStyles]}>
              {supplemental.map(
                ({
                  primary: {
                    title,
                    copy: { html: copy },
                    footer: { html: footer },
                  },
                  items,
                }) => (
                  <div key={`supplement-${nanoid()}`}>
                    {title && <Title titleAccent>{title}</Title>}
                    {copy && (
                      <div
                        className="large"
                        dangerouslySetInnerHTML={{ __html: copy }}
                      />
                    )}
                    <div css={[dynamicCol, BottomContentButtonStyles]}>
                      {items &&
                        items.length >= 1 &&
                        items.map(({ label, link, type }) => {
                          return (
                            <LinkButton key={nanoid()} to={linkResolver(link)}>
                              {label}
                            </LinkButton>
                          )
                        })}
                    </div>
                    {footer && (
                      <div dangerouslySetInnerHTML={{ __html: footer }} />
                    )}
                  </div>
                )
              )}
            </div>
          </BottomContentLayoutContainer>
        </OffsetAnchor>
      )}
    </Layout>
  )
}

export default ResidencePageTemplate

export const query = graphql`
  query ($uid: String!) {
    page: prismicResidence(uid: { eq: $uid }) {
      data {
        title
        overviewCtaLogo: overview_cta_logo {
          url
        }
        overviewCtaLogoLink: overview_cta_logo_link {
          url
        }
        heroDescription: hero_description {
          html
        }
        heroPhoto: hero_photo {
          url
        }
        heroVideo: hero_video
        heroCtaCopy: hero_cta_copy
        heroCtas: hero_ctas {
          label
          type
          link {
            uid
            type
            raw
          }
        }
	adCodeBlock: adcodeblock
        collage {
          photo: collage_photo {
            url
          }
        }
        locationTitle: location_title
        locationDescription: location_description {
          html
        }
        mapUrl: google_maps_url
        amenitiesTitle: amenities_title
        amenitiesDescription: amenities_description {
          html
        }
        amenities {
          title: amenity_name
          icon: amenity_icon {
            url
          }
        }
        featuresTitle: features_title
        featuresDescription: features_description {
          html
        }
        featuresPhoto: features_photo {
          url
        }
        whatsIncludedTitle: whats_included_title
        whatsIncludedDescription: whats_included_description {
          html
        }
        body {
          ... on PrismicResidenceDataBodyInclusionSection {
            sliceType: slice_type
            primary {
              title: inclusion_section_title
            }
            items {
              item: inclusion_item
            }
          }
        }
        supplemental {
          ... on PrismicResidenceDataSupplementalSupplement {
            primary {
              title: supplement_title
              copy: supplement_copy {
                html
              }
              footer: supplement_footer_copy {
                html
              }
            }
            items {
              label: supplement_cta_label
              link: supplement_cta_link {
                uid
                type
              }
            }
          }
        }
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
      }
    }
  }
`

const FeatureStyles = css`
  && {
    padding: 30px 0;
  }
`

const Ctas = styled.div`
  display: grid;
  grid-gap: 15px;
`

const BottomContentColStyles = css`
  ${mq.lessThan('small')`
    grid-gap: 60px 0;
  `};
`

const BottomContentButtonStyles = css`
  ${mq.lessThan('small')`
    grid-gap: 15px 0;
  `};
`

const Title = styled.h2`
  ${TitleStyles};
  margin-bottom: 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 55px;
    height: 5px;
    background: var(--c-lightGreyBlue);
    position: absolute;
    top: -25px;
  }
`

const BottomContentLayoutContainer = styled(LayoutContainer)`
  margin-bottom: 100px;
  color: var(--c-gunmetal);

  p {
    font-size: ${rem('18px')};
    line-height: 1.5;

    &.large {
      font-size: ${rem('24px')};
    }
  }
`
