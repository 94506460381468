// @flow

import React from 'react'
import StandardContactForm from '@/components/Forms/StandardContactForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => (
  <StandardContactForm
    name="Guide to Senior Living Request"
    action={action}
    submitLabel="Get Guide to Senior Living Options"
  />
)
