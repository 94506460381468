import React from 'react'
import { boolean } from 'yup'
import { Input } from '@/components/Form'

export const fieldName = 'newsletter-opt-in'
const initialValue = false

type Props = {
  className: String,
}

const Component = ({ className, ...restProps }: Props) => {
  return (
    <div className={`has-checkbox ${className}`} {...restProps}>
      <Input
        label="By checking this box, you consent to receive information from the Arcadia Family of Companies."
        type="checkbox"
        id={fieldName}
        name={fieldName}
      />
    </div>
  )
}

export const schema = boolean()

export default {
  Component,
  schema,
  initialValue,
}
