// @flow

import React from 'react'
import StandardContactForm from '@/components/Forms/StandardContactForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => (
  <StandardContactForm
    name="Life Plan Communities Brochure Request"
    action={action}
    submitLabel="Get the Guide"
  />
)
