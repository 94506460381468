// @flow

import React from 'react'
import { graphql } from 'gatsby'
import FormLayout from '@/layouts/FormLayout'
import { Title, Back, Copy } from '@/components/Form'
import slugify from 'slugify'

type Props = {
  data: Object,
  pageContext: Object,
}

function FormPageTemplate({
  pageContext: { section },
  data: {
    page: {
      data: {
        title: { text: title },
        description: { html: description },
        action: { url: action },
        form,
      },
    },
  },
}: Props) {
  const Form = require(`../forms/${slugify(
    form.toLocaleLowerCase()
  )}.js`).default

  return (
    <FormLayout section={section}>
      <div>
        <Back>← Back</Back>
        <Title>{title}</Title>
        <Copy dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {typeof window !== 'undefined' && (
        <Form
          action={
            section
              ? action?.replace(
                  `${slugify(section.toLowerCase())}-`,
                  `${slugify(section.toLowerCase())}/`
                )
              : action
          }
        />
      )}
    </FormLayout>
  )
}

export default FormPageTemplate

export const query = graphql`
  query ($uid: String!) {
    page: prismicForm(uid: { eq: $uid }) {
      data {
        title: form_title {
          text
        }
        description {
          html
        }
        action: form_submitted {
          uid
          type
        }
        form
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
      }
    }
  }
`
