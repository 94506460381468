// @flow

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import LayoutContainer from '@/components/LayoutContainer'
import Option from '@/components/Option'
import { AccentRuleStyles, TitleStyles, PreHeaderStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'

type Props = {
  subtitle?: String,
  title: String,
  titleAccent: Boolean,
  description: String,
  options?: Array,
  className?: String,
  layoutCss?: String,
  layout?: String,
  id: String,
}

const layoutClass = layout => (layout ? `layout--${layout}` : '')

export default ({
  subtitle,
  title,
  titleAccent,
  description,
  options,
  className,
  layoutCss,
  layout,
  id,
}: Props) => {
  return (
    <Wrapper id={id} className={className}>
      <LayoutContainer
        css={[LayoutStyles, layoutCss]}
        className={layoutClass(layout)}>
        <Copy>
          {subtitle && <PreHeader>{subtitle}</PreHeader>}
          <Title titleAccent={titleAccent}>{title}</Title>
          {description && <Description>{description}</Description>}
        </Copy>
        {Array.isArray(options) && options.length && (
          <Options className={layoutClass(layout)}>
            {options.map(option => (
              <Option
                key={option.title}
                className={layoutClass(layout)}
                {...option}
              />
            ))}
          </Options>
        )}
      </LayoutContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: var(--c-gunmetal);
`

const LayoutStyles = css`
  display: grid;
  margin-top: var(--d-gutter);
  margin-bottom: var(--d-gutter);

  ${mq.greaterThan('medium')`
    margin-top: 3rem;
    margin-bottom: 3rem;
    grid-gap: 0 30px;
    grid-template-areas: 'copy options';
  `};

  &.layout--detailed {
    ${mq.greaterThan('medium')`
      grid-template-areas:
        'copy'
        'options'
        'description';
    `};
  }

  &.layout--split {
    ${mq.greaterThan('medium')`
      margin: 60px auto;
    `};
  }

  &.layout--detailed-rows {
    ${mq.greaterThan('medium')`
      grid-template-areas:
        'copy'
        'options'
        'description';
    `};
  }
`

const Copy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.greaterThan('medium')`
    grid-area: copy;
  `};
`

const Options = styled.div`
  margin-top: var(--d-gutter);
  align-items: flex-start;
  display: grid;
  grid-gap: 30px;

  ${mq.greaterThan('medium')`
    margin-top: 0;
    grid-area: options;
  `};

  &.layout--detailed {
    margin-top: 30px;

    ${mq.greaterThan('medium')`
      display: grid;
      grid-gap: 50px 30px;
      grid-template-columns: repeat(3, 1fr);
    `};
  }

  &.layout--split {
    display: grid;
    grid-gap: 10px var(--d-gutter);

    ${mq.greaterThan('small')`
      grid-template-columns: repeat(2, 1fr);
    `};
  }

  &.layout--detailed-rows {
    margin-top: 30px;
    display: flex;
    flex-flow: column nowrap;
  }
`

const Title = styled.h2`
  ${TitleStyles};
  grid-area: title;
  margin: 0 0 35px;

  ${p =>
    p.titleAccent &&
    css`
      margin-top: 25px;
      ${AccentRuleStyles({ justify: 'left' })};
    `}
`

const PreHeader = styled.h2`
  ${PreHeaderStyles};
  color: var(--c-lightGreyBlue);
  margin: 0;
`

const Description = styled.div`
  grid-area: description;
  font-size: ${rem('16px')};
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
