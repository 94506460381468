import React, { useState } from 'react'
import { rem } from 'polished'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Link from '@/components/Link'
import Layout from '@/layouts/CareersLayout'
import { CarrerPageFeature } from '@/components/CareerPageFeature'
import { JobsResultsSection } from '@/components/JobsResultsSection'
import { mq } from '@/styles/theme'
import { AbsoluteFill, GradientOverlay } from '@/styles/shared'
import { JobSearch } from '@/components/JobSearch'
import { JobsProvider } from '@/features/jobs/components/JobsProvider'
import { JobResults } from '@/features/jobs/components/JobResults'

const TILE_SIZE = 256

type Props = {
  data: Object
}

function CareersPage({ data }: Props) {
  const departments = data.jobDepartments.edges.map(edge => edge.node)
  const [results, setResults] = useState([])

  return (
    <Layout>
      <CarrerPageFeature />
      <JobsResultsSection title="All Job Departments">
        <JobsProvider>
          <JobSearch setResults={setResults} />
        </JobsProvider>
        {results?.length > 0 ? (
          <JobResults jobs={results} />
        ) : (
          <DepartmentTileContainer>
            {departments.map(department => {
              const imgSrc = `${department.data.cover.url}&w=${
                TILE_SIZE * 2
              }&h=${TILE_SIZE * 2}&fit=crop`
              return (
                <DepartmentTile
                  key={department.uid}
                  to={`/careers/${department.uid}#jobs`}>
                  <Image src={imgSrc} alt={department.data.title} />
                  <Title>{department.data.title}</Title>
                </DepartmentTile>
              )
            })}
          </DepartmentTileContainer>
        )}
      </JobsResultsSection>
    </Layout>
  )
}

export default CareersPage

export const query = graphql`
  {
    jobDepartments: allPrismicJobCategory(
      sort: { fields: [data___title] }
      filter: { data: { cover: { url: { ne: null } } } }
    ) {
      edges {
        node {
          uid
          data {
            title
            cover {
              url
            }
          }
        }
      }
    }
  }
`

export function Head() {
  return (
    <>
      <title>Arcadia - Careers</title>
      <meta name="description" content={''} />
    </>
  )
}

const DepartmentTileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--d-gutter);
`

const DepartmentTile = styled(Link)`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  aspect-ratio: 1;
  width: ${TILE_SIZE}px;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.08);
    }
  }

  &::after {
    ${GradientOverlay};
    pointer-events: none;
  }
`

const Image = styled.img`
  ${AbsoluteFill};
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
  transform: scale(1);
  pointer-events: none;
`

const Title = styled.p`
  color: #fff;
  font: 600 ${rem('30px')} var(--f-serif);
  line-height: 1.2;
  margin: 0;
  z-index: 2;
`
