import React from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'
import { LinkButton } from '@/components/Button'
import { TitleStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'

type Props = {
  photo: String,
  title: String,
  description: String,
  ctaLabel: String,
  ctaLink: String,
}

export default ({
  photo,
  title,
  description: { html: description },
  ctaLabel,
  ctaLink,
}: Props) => (
  <Company>
    <Image loading="lazy" src={photo.url} alt="" />
    <Content>
      <Title>{title}</Title>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {ctaLabel && ctaLink && <CTA to={ctaLink.url}>{ctaLabel}</CTA>}
    </Content>
  </Company>
)

const Company = styled.div`
  display: grid;
  background: #fff;

  ${mq.greaterThan('small')`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 545px;
  `};
`

const Content = styled.div`
  padding: var(--d-gutter);
  display: grid;
  align-items: center;
  align-content: center;
  grid-gap: 15px 0;

  ${mq.greaterThan('small')`
    grid-gap: var(--d-gutter) 0;
    padding: 48px 70px;
  `};
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;

  ${mq.greaterThan('small')`
    height: 100%;
    grid-area: 1 / 1 / 2 / 2;
  `};
`

const Title = styled.h2`
  ${TitleStyles};
  color: var(--c-lightGreyBlue);
  margin: 0;
`

const Description = styled.div`
  color: var(--c-gunmetal);
  margin: 0;
  font-size: ${rem('18px')};
`

const CTA = styled(LinkButton)`
  background: var(--c-lightGreyBlue);
  color: #fff;
  justify-self: flex-start;
  align-self: flex-start;

  &:visited {
    color: #fff;
  }
`
