// @flow

import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import Link from '@/components/Link'
import Button from '@/components/Button'
import Nav from '@/components/HeaderNav'

type Props = {
  children: React.ReactNode,
  className?: String,
  headerNavLinks: Array,
  logo: React.ReactNode,
  logoUrl: String,
  logoOverride: React.ReactNode,
  nav: Boolean,
}

export default ({
  children,
  className,
  headerNavLinks,
  logo,
  logoUrl,
  logoOverride,
  nav = true,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Header className={className} data-menu={menuOpen ? 'show-mobile' : ''}>
      {!logoOverride && <Link to={logoUrl || '/'}>{logo}</Link>}
      {logoOverride}
      {children}

      {nav && <Nav mobileMenuOpen={menuOpen} links={headerNavLinks} />}
      <MobileButtonToggle toggle={val => setMenuOpen(val)} />
    </Header>
  )
}

type MobileButtonToggleProps = {
  toggle: Function,
}

const MobileButtonToggle = ({ toggle }: MobileButtonToggleProps) => {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (typeof toggle === 'function') {
      toggle(menuOpen)
    }
  }, [menuOpen, toggle])

  return (
    <MobileButtonToggleContainer>
      {!menuOpen && <Button onClick={() => setMenuOpen(true)}>Menu</Button>}
      {menuOpen && (
        <Button css={CloseButtonStyles} onClick={() => setMenuOpen(false)}>
          Close
        </Button>
      )}
    </MobileButtonToggleContainer>
  )
}

const MobileButtonToggleContainer = styled.div`
  ${mq.greaterThan('medium')`
    display: none;
  `};
`

const CloseButtonStyles = css`
  color: #fff;
  background-color: var(--c-mediumGray);
`

const Header = styled.header`
  margin-bottom: 30px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--d-gutter);
  height: var(--d-headerHeightMobile);

  ${mq.greaterThan('medium')`
    margin-top: 35px;
    margin-bottom: 55px;
    height: 55px;
    grid-gap: 0 20px;
  `};

  .logo {
    height: 40px;

    ${mq.greaterThan('medium')`
      height: 50px;
    `};
  }
`
