import React, { useCallback, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import {
  DepartmentFilter,
  TypeFilter,
  CompanyFilter,
  FiltersLayout,
} from '@/components/JobFilters'
import { JobsResultsSection } from '@/components/JobsResultsSection'
import { JobResults } from '@/features/jobs/components/JobResults'

const collectJobDepartmentOptions = data => [
  // DEFAULT_SELECT_OPTION,
  ...data.jobDepartments.edges.map(_dept => ({
    label: _dept.node.data.title,
    value: _dept.node.uid,
  })),
]

const collectCompanyOptions = data => [
  // DEFAULT_SELECT_OPTION,
  ...data.careersPage.data.companies.map(_company => {
    return {
      label:
        _company.company.document.data.shortTitle ??
        _company.company.document.data.title,
      value:
        _company.company.document.data.shortTitle ??
        _company.company.document.data.title,
    }
  }),
]

const collectJobTypeOptions = data => [
  // DEFAULT_SELECT_OPTION,
  ...data.careersPage.data.jobTypes.map(_jobType => ({
    label: _jobType.jobType.document.data.title,
    value: _jobType.jobType.document.data.title,
  })),
]

const getFilteredJobs = (jobs, filterOptions) => {
  return jobs
    .filter(
      job =>
        !filterOptions.company ||
        (filterOptions.company && job.companies.includes(filterOptions.company))
    )
    .filter(
      job =>
        !filterOptions.jobDepartment ||
        (filterOptions.jobDepartment &&
          job.jobDepartments.includes(filterOptions.jobDepartment))
    )
    .filter(
      job =>
        !filterOptions.jobType ||
        (filterOptions.jobType && job.jobTypes.includes(filterOptions.jobType))
    )
}

interface JobsProps {
  title: string
  jobs: any[]
  department: {
    title: string
    uid: string
  }
}

export default ({ title, jobs, department }: JobsProps) => {
  const data = useStaticQuery(query)
  // set value from url query params
  const urlParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : null
  const [jobType, setJobType] = useState(
    urlParams?.get('type')
      ? {
          label: urlParams.get('type'),
          value: urlParams.get('type'),
        }
      : null
  )
  const [company, setCompany] = useState(
    urlParams?.get('company')
      ? {
          label: urlParams.get('company'),
          value: urlParams.get('company'),
        }
      : null
  )
  const [jobDepartment, setJobDepartment] = useState(
    department
      ? {
          label: department.title,
          value: department.uid,
        }
      : null
  )

  const jobDepartments = collectJobDepartmentOptions(data)
  const companies = collectCompanyOptions(data)
  const jobTypes = collectJobTypeOptions(data)

  const filterOptions = {
    company: company?.value ?? '',
    jobDepartment: jobDepartment?.value ?? '',
    jobType: jobType?.value ?? '',
  }

  const filteredJobs = getFilteredJobs(jobs, filterOptions)

  const onCompanychange = useCallback(
    value => {
      setCompany(value)
      if (typeof window !== 'undefined') {
        const url = new URL(window.location.href)
        if (!value) {
          url.searchParams.delete('company')
        } else {
          url.searchParams.set('company', value.value)
        }
        window.history.replaceState({}, '', url)
      }
    },
    [company]
  )

  const onTypeChange = useCallback(
    value => {
      setJobType(value)
      if (typeof window !== 'undefined') {
        const url = new URL(window.location.href)
        if (!value) {
          url.searchParams.delete('type')
        } else {
          url.searchParams.set('type', value.value)
        }
        window.history.replaceState({}, '', url)
      }
    },
    [jobType]
  )

  const onDepartmentChange = useCallback(
    value => {
      setJobDepartment(value)
      if (!value) {
        navigate('/careers#jobs')
        return
      }
      if (
        typeof window !== 'undefined' &&
        value.value !== jobDepartment?.value
      ) {
        let url = `/careers/${value.value}#jobs`
        let params = new URLSearchParams(window.location.search)
        if (jobType) {
          params.set('type', jobType.value)
        }
        if (company) {
          params.set('company', company.value)
        }
        if (params.toString()) {
          url = `${url}?${params.toString()}`
        }
        navigate(url)
      }
    },
    [jobDepartment, jobType, company]
  )

  return (
    <JobsResultsSection title={title}>
      <FiltersLayout>
        <CompanyFilter
          onChange={onCompanychange}
          value={company}
          options={companies}
        />
        <DepartmentFilter
          onChange={onDepartmentChange}
          value={jobDepartment}
          options={jobDepartments}
        />
        <TypeFilter
          onChange={onTypeChange}
          value={jobType}
          options={jobTypes}
        />
      </FiltersLayout>
      <JobResults jobs={filteredJobs} />
    </JobsResultsSection>
  )
}

const query = graphql`
  {
    careersPage: prismicCareers {
      data {
        orderedJobs: ordered_jobs {
          job {
            document {
              ... on PrismicJobPosting {
                prismicId
                ...jobPosting
              }
            }
          }
        }
        jobTypes: job_types {
          jobType: job_type {
            uid
            document {
              ... on PrismicJobType {
                data {
                  title
                }
              }
            }
          }
        }
        companies {
          company {
            uid
            document {
              ... on PrismicCompany {
                data {
                  title
                  shortTitle: short_title
                }
              }
            }
          }
        }
      }
    }
    jobDepartments: allPrismicJobCategory(
      sort: { fields: [data___title] }
      filter: { data: { cover: { url: { ne: null } } } }
    ) {
      edges {
        node {
          uid
          data {
            title
          }
        }
      }
    }
    jobs: allPrismicJobPosting(
      sort: { fields: first_publication_date, order: DESC }
      filter: { prismicId: { ne: "XunPcRAAAB8Aj3EI" } }
    ) {
      edges {
        node {
          prismicId
          ...jobPosting
        }
      }
    }
  }
`
