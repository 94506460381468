import React from 'react'
import { string } from 'yup'
import { Input } from '@/components/Form'
import { fieldName as NewsletterFieldName } from '@/components/form-fields/NewsletterOptIn'
type Props = {
  required: Boolean,
  hideLabel: Boolean,
}

const Component = ({ required, hideLabel }: Props) => (
  <Input
    label="Email"
    id="email"
    type="email"
    name="email"
    placeholder="enter your email address"
    required={required}
    hideLabel={hideLabel}
  />
)

const schema = string().when(NewsletterFieldName, {
  is: true,
  then: string()
    .email()
    .required('Please provide your email address for the Arcadia Newsletter'),
  otherwise: string().email(),
})

const initialValue = ''

export default {
  Component,
  schema,
  initialValue,
}
