// @flow

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import { mq } from '@/styles/theme'
import Link from '@/components/Link'
import LayoutContainer from '@/components/LayoutContainer'
import { PreHeaderStyles, AbsoluteFill, GradientOverlay } from '@/styles/shared'
import { linkResolver } from '@/config/linkResolver'

// TODO: UPGRADE - FIX LINKS!!!!
export default () => {
  const qRes = useStaticQuery(query)

  const {
    prismicHomepage: {
      data: { links },
    },
  } = qRes

  return (
    <>
      {links.length >= 1 && (
        <LayoutContainer css={LayoutStyles}>
          <PhotoLinksContainer>
            <PhotoLinks>
              {links.map(
                ({
                  photo: { url: photo },
                  copy: { text: copy },
                  link,
                  preheader,
                }) => {
                  return (
                    <PhotoLink key={`lc-${preheader}`} to={linkResolver(link)}>
                      <Image loading="lazy" src={photo} alt={preheader} />
                      <Subtitle css={PreHeaderStyles}>{preheader}</Subtitle>
                      <Title>{copy}</Title>
                    </PhotoLink>
                  )
                }
              )}
            </PhotoLinks>
          </PhotoLinksContainer>
        </LayoutContainer>
      )}
    </>
  )
}

const query = graphql`
  {
    prismicHomepage {
      data {
        links: link_collage {
          photo {
            url
          }
          copy {
            text
          }
          link {
            uid
            type
          }
          preheader
        }
      }
    }
  }
`

const LayoutStyles = css`
  margin: 100px auto;
`

const PhotoLinksContainer = styled.div`
  position: relative;

  ${mq.greaterThan('small')`
    height: 0;
    padding-bottom: 63%;
  `};
`

const PhotoLinks = styled.div`
  display: grid;
  grid-gap: var(--d-gutter);

  ${mq.greaterThan('small')`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-auto-rows: 38.5px;
  `};
`

const PhotoLink = styled(Link)`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: calc(100vw - (2 * var(--d-gutter)));
  height: calc(100vw - (2 * var(--d-gutter)));
  overflow: hidden;

  ${mq.greaterThan('small')`
    width: auto;
    height: auto;
  `};

  ${mq.greaterThan('mediumSmall')`
    padding: 35px 40px;
  `};

  &:hover {
    img {
      transform: scale(1.08);
    }
  }

  &::after {
    ${GradientOverlay};
    z-index: -1;
  }

  &:nth-of-type(1) {
    ${mq.greaterThan('small')`
    grid-area: 1 / 1 / 10 / 8;
    `};
  }

  &:nth-of-type(2) {
    ${mq.greaterThan('small')`
    grid-area: 1 / 8 / 20 / 13;
    `};
  }

  &:nth-of-type(3) {
    ${mq.greaterThan('small')`
    grid-area: 10 / 1 / 20 / 5;
    `};
  }

  &:nth-of-type(4) {
    ${mq.greaterThan('small')`
    grid-area: 10 / 5 / 20 / 8;
    `};
  }
`

const Image = styled.img`
  ${AbsoluteFill};
  object-fit: cover;
  z-index: -1;
  transition: transform 0.4s ease-in-out;
  transform: scale(1);
`

const Subtitle = styled.p`
  ${PreHeaderStyles};
  color: #fff;
  margin: 0;
`

const Title = styled.p`
  color: #fff;
  font: 600 ${rem('30px')} var(--f-serif);
  line-height: 1.2;
  margin: 0;
`
