// @flow

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layouts'
import Hero from '@/components/HomeHero'
import LinkCollage from '@/components/LinkCollage'
import TestimonialSlider from '@/components/TestimonialSlider'
import Mosaic from '@/components/Mosaic'
import Options from '@/components/HomeOptions'

const heroOpts = {
  title: 'Senior Living Redefined',
  body: "Looking for the right senior living option for you or your loved one? We offer a full spectrum of services for Hawaii's seniors",
  cta: { url: '/senior-guide', label: 'Start Here' },
}

function IndexPage({
  data,
}: {
  data: {
    page: {
      data: {
        title: string
        metaTitle: string
        metaDescription: {
          text: string
        }
      }
    }
    settings: {
      data: {
        company_logo: {
          url: string
          alt: string
        }
      }
    }
  }
}) {
  return (
    <Layout
      showBanner
      headerOverlay
      logoSrc={data.settings.data.company_logo.url}
      logoAlt={data.settings.data.company_logo.alt}>
      <Hero {...heroOpts} />
      <LinkCollage />
      <Options />
      <Mosaic />
      <TestimonialSlider
        title="Voices of Arcadia"
        description="Hear what our residents and staff members are saying about daily life at any one of our facilities"
      />
    </Layout>
  )
}

export default IndexPage

export function Head({
  data: {
    page: {
      data: {
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}: {
  data: {
    page: {
      data: {
        metaTitle: string
        metaDescription: {
          text: string
        }
      }
    }
  }
}) {
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </>
  )
}

export const query = graphql`
  {
    page: prismicHomepage {
      data {
        title
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
      }
    }
    settings: prismicGlobalSettings {
      ...LogoSettings
    }
  }
`
