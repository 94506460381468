import styled from '@emotion/styled'

const ContentColumn = styled.div`
  display: grid;
  grid-gap: var(--d-gutter);
  align-items: center;
  align-content: center;

  h2 {
    margin: 0;

    + p {
      margin: 0;
    }
  }
`
export default ContentColumn
