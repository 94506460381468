// @flow

import React from 'react'
import { object } from 'yup'
import Form from '@/components/Form'
import {
  FirstNameLastName,
  Phone,
  Email,
  InterestedIn,
  Question,
  Referrals,
} from '@/components/form-fields'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  phone: Phone.schema.required(),
  email: Email.schema,
  question: Question.schema,
  referrals: Referrals.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  'interested-in': InterestedIn.initialValue,
  phone: Phone.initialValue,
  email: Email.initialValue,
  question: Question.initialValue,
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

type Props = {
  name: String,
  action: String,
  submitLabel: String,
}

export default ({ name, action, submitLabel }: Props) => (
  <Form
    formikOptions={formikOptions}
    name={name}
    action={action}
    submitLabel={submitLabel}>
    <FirstNameLastName.Component />
    <InterestedIn.Component />
    <Phone.Component />
    <Email.Component />
    <Question.Component />
    <Referrals.Component />
  </Form>
)
