import React, { useState } from 'react'
import styled from '@emotion/styled'
import Modal from 'react-modal'
import Button from '@/components/Button'
import VideoModal from '@/components/VideoModal'
import { PreHeaderStyles, TitleStyles, GradientOverlay } from '@/styles/shared'

Modal.setAppElement('#___gatsby')

type Props = {
  title: String,
  src: String,
  alt: String,
  videoSrc: String,
}

export default ({ title, src, alt, videoSrc }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <VideoTestimonialContainer>
      <Image src={src} alt={alt || title} />
      <Content>
        <PreHeader>Video Testimonial</PreHeader>
        <Title>{title}</Title>
        <CTA onClick={() => setModalIsOpen(true)}>▶ Watch Video</CTA>
        <VideoModal
          videoTitle={title}
          embedHtml={videoSrc}
          isOpen={modalIsOpen}
          closeFn={() => setModalIsOpen(false)}
        />
      </Content>
    </VideoTestimonialContainer>
  )
}

const VideoTestimonialContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 40px 35px;
  position: relative;

  &::after {
    ${GradientOverlay};
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
`

const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`

const PreHeader = styled.p`
  ${PreHeaderStyles};
  color: #fff;
`

const Title = styled.h2`
  ${TitleStyles};
  color: #fff;
`

const CTA = styled(Button)`
  padding: 15px 20px;
`
