import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { TitleStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'

type JobPostingProps = {
  title: string
  simpleDescription: string
  url: string
}

export default ({ title, simpleDescription, url }: JobPostingProps) => {
  return (
    <Job>
      <a href={url} target="_blank">
        <Title>{title}</Title>
        {simpleDescription ? (
          <Description>{simpleDescription}</Description>
        ) : null}
      </a>
    </Job>
  )
}

export const query = graphql`
  fragment jobPosting on PrismicJobPosting {
    data {
      title
      simpleDescription: simple_description
      keywords
      departments {
        department {
          id
          slug
          document {
            ... on PrismicJobCategory {
              id
              uid
              data {
                title
              }
            }
          }
        }
      }
      companies {
        company: job_company {
          id
          slug
          document {
            ... on PrismicCompany {
              data {
                title
              }
            }
          }
        }
      }
      types {
        type: job_type {
          id
          slug
          document {
            ... on PrismicJobType {
              data {
                title
              }
            }
          }
        }
      }
      url {
        url
      }
    }
  }
`

const Job = styled.article`
  > a {
    display: block;

    &:hover * {
      color: var(--c-gold);
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

const Title = styled.h2`
  ${TitleStyles};
  font-size: 1.75rem;
  margin: -10px 0;

  ${mq.greaterThan('small')`
    font-size: 1.75rem;
  `};
`

const Description = styled.p`
  color: var(--c-gunmetal);
  margin-bottom: 0;
  font-size: 1rem;
`
