// @flow

import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

type Props = {
  children: any,
  to: string,
}

export default ({ children, to, ...other }: Props) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use gatsby-link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} role="link" {...other}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      {...other}
      role="link">
      {children}
    </a>
  )
}
