import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { rem } from 'polished'
import styled from '@emotion/styled'
import SidebarLayout from '@/layouts/SidebarLayout'
import { General } from '@/styles/general'
import Link from '@/components/Link'

type Props = {
  children: React.ReactNode,
  location: Object,
  className: String,
}

function getTitleFromSlug(slug) {
  return slug.replace(/\-/g, ' ')
}

export default ({ children, location: { pathname }, className }: Props) => {
  const {
    nav: {
      data: { links: _links },
    },
  } = useStaticQuery(query)
  const links = _links.map(l => l.link)

  return (
    <SidebarLayout className={className} parentLayout>
      <Nav>
        {links.map(link => {
          const title = getTitleFromSlug(link.uid)
          return (
            <Link
              style={{ textTransform: 'capitalize' }}
              key={`${link.uid}-${title}`}
              className={pathname.includes(link.uid) ? 'active' : ''}
              to={`/${link.uid}`}>
              {title}
            </Link>
          )
        })}
      </Nav>
      <General>{children}</General>
    </SidebarLayout>
  )
}

const query = graphql`
  {
    nav: prismicSupplementalPageNavigation {
      data {
        title
        links {
          link {
            isBroken
            document {
              __typename
            }
            uid
            raw
          }
        }
      }
    }
  }
`

const Nav = styled.div`
  display: grid;
  align-items: flex-start;
  align-content: flex-start;

  a {
    color: var(--c-lightGreyBlue);
    font-size: ${rem('24px')};
    line-height: 1.5;

    &.active {
      color: var(--c-gunmetal);
    }
  }
`
