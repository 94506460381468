// @flow

import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import SidebarLayout from '@/layouts/SidebarLayout'
import Header from '@/components/BlogHeader'
import BlogPosts from '@/components/BlogPosts'
import { FiltersLayout } from '@/components/BlogFilters'
import BlogCategoryMenu from '@/components/BlogCategoryMenu'
import { getCategories, getBlogPosts } from '@/lib/prismic-client'
import {
  gqlFormatter,
  apiListItemFormatter,
  staticCategoryFormatter,
} from '@/formatters/blog-post'

type Props = {
  data: Object,
}

const shapeBlogPosts = results =>
  results.results.map(blogPost => apiListItemFormatter({ blogPost }))

const shapeOptions = (results, type) => {
  return results.results
    .map(({ id, data }) => {
      if (data[type].title.value.includes('Archived - ')) {
        return {
          label: data[type].title.value.split(' - ')[1],
          value: id,
        }
      }
      return false
    })
    .filter(f => !!f)
}

function NewsPage({ data }: Props) {
  /* start static rendering defaults */
  const staticCategoryTitles = data.allCategories.nodes
    .map(staticCategoryFormatter)
    .filter(f => !!f)
  const [firstPost, ...restPosts] = data.blogPosts.edges.map(
    ({ node: blogPost }) => gqlFormatter({ blogPost })
  )
  /* end static rendering defaults */

  const [categories, setCategories] = useState(staticCategoryTitles)
  const [category, setCategory] = useState(null)
  const [featuredPost, setFeaturedPost] = useState(firstPost)
  const [blogPosts, setBlogPosts] = useState(restPosts)
  const [usedFilter, setUsedFilter] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    ;(async () => {
      try {
        setCategories(shapeOptions(await getCategories(), 'category'))
      } catch (err) {
        console.error('Something went wrong: ', err)
      }
    })()
  }, [])

  useEffect(() => {
    if (!usedFilter && category) {
      setUsedFilter(true)
    }
    if (usedFilter || (!usedFilter && category)) {
      // eslint-disable-next-line prettier/prettier
      ;(async () => {
        try {
          const results = await getBlogPosts({ category })
          const shapedResults =
            results.results.length >= 1 ? shapeBlogPosts(results) : []
          const featuredPost = shapedResults[0]
          const newPosts = shapedResults.splice(1, shapedResults.length)
          setFeaturedPost(featuredPost)
          setBlogPosts(newPosts)
        } catch (err) {
          console.error(`Could not load blog posts: ${err}`)
        }
      })()
    }
  }, [category, usedFilter])

  return (
    <SidebarLayout parentLayout>
      <Header headerText="Care Advice">
        <FiltersLayout>
          <BlogCategoryMenu
            id="filter-category"
            options={categories}
            onChange={value => setCategory(value)}
          />
        </FiltersLayout>
      </Header>
      <BlogPosts
        featuredPost={featuredPost}
        posts={blogPosts}
        seeMore={false}
      />
      {(!blogPosts || blogPosts.length < 1) && (
        <p>No results for your search</p>
      )}
    </SidebarLayout>
  )
}

export default NewsPage

export function Head() {
  return (
    <>
      <title>Arcadia - News Articles Page</title>
      <meta name="description" content={''} />
    </>
  )
}

// NOTES: If you update FILTER CATEGORY rules please take a look at "# src/lib/prismic-client.js"
export const query = graphql`
  {
    blogPosts: allPrismicBlogPost(
      filter: {
        uid: { regex: "/^((?!__).)*$/" }
        data: {
          categories: { elemMatch: { category: { slug: { eq: "news" } } } }
        }
      }
      sort: { order: DESC, fields: data___publish_date }
    ) {
      edges {
        node {
          uid
          prismicId
          data {
            title
            publishDate: publish_date
            featuredImage: featured_image {
              url
            }
          }
        }
      }
    }
    allCategories: allPrismicCategory(
      sort: { order: ASC, fields: data___title }
    ) {
      nodes {
        prismicId
        data {
          title
        }
      }
    }
  }
`
