import React from 'react'
import { rem } from 'polished'
import { Global } from '@emotion/core'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import GlobalStyles from '@/styles/global'
import { ButtonStyles, ParentLayoutStyles } from '@/styles/shared'
import DynamicLogoFromCMS from '@/components/DynamicLogoFromCMS'
import BgBlob from '@/assets/bg-blob.svg'
import SvgLogoArcadiaFamilyOfCompanies from '@/assets/svgr/LogoArcadiaFamilyOfCompanies'
import { mq } from '@/styles/theme'
import TopBanners from '@/components/TopBanners'

const _headerNavLinks = [
  {
    label: 'Residences',
    url: '/residences',
    type: 'normal',
  },
  {
    label: 'At Home',
    url: '/at-home',
    type: 'normal',
  },
  {
    label: 'Home Care',
    url: '/home-health',
    type: 'normal',
  },
  {
    label: 'Adult Day Care',
    url: '/central-union',
    type: 'normal',
  },
  {
    label: 'Skilled Nursing',
    url: '/skilled-nursing',
    type: 'normal',
  },
  {
    label: 'News',
    url: '/news',
    type: 'normal',
  },
  {
    label: 'Videos',
    url: '/videos',
    type: 'normal',
  },
  {
    label: 'Careers',
    url: '/careers',
    type: 'normal',
  },
  {
    label: 'Contact',
    url: '/contact',
    type: 'fancy',
    style: [
      ButtonStyles,
      css`
        && {
          ${mq.lessThan('medium')`
            margin: 25px 0;
            font-family: var(--f-sans);
            font-size: ${rem('24px')};
            letter-spacing: ${rem('2px')};
          `};
        }
      `,
    ],
  },
]

type Props = {
  borderedFooter?: Boolean,
  children?: React.ReactNode,
  disableFooter?: Boolean,
  header: Boolean,
  headerNavLinks: Array,
  headerOverlay?: Boolean,
  logo: React.ReactNode,
  logoUrl?: String,
  nav: Boolean,
  parentLayout: Boolean,
  parentLayoutBg: Boolean,
  className: String,
  logoSrc?: String,
  logoAlt?: String,
}

function IndexPage({
  borderedFooter,
  children,
  disableFooter,
  header = true,
  headerNavLinks,
  headerOverlay,
  logo,
  logoUrl = '/',
  nav,
  parentLayout,
  parentLayoutBg,
  className,
  logoSrc,
  logoAlt,
}: Props) {
  return (
    <>
      <Global styles={GlobalStyles} />
      <TopBanners />
      {header && (
        <Header
          nav={nav}
          logo={
            logo || (
              <DynamicLogoFromCMS
                role="img"
                aria-label="logo"
                className="logo"
                src={logoSrc}
                alt={logoAlt}
              />
            )
          }
          logoUrl={logoUrl}
          className={headerOverlay ? 'header-overlay' : ''}
          headerNavLinks={headerNavLinks || _headerNavLinks}
        />
      )}
      {parentLayout && (
        <LayoutParent className={className} parentLayoutBg={parentLayoutBg}>
          {children}
        </LayoutParent>
      )}
      {!parentLayout && children}
      {!disableFooter && (
        <Footer className={borderedFooter ? 'bordered' : ''} />
      )}
    </>
  )
}

export default IndexPage

const LayoutParent = styled.div`
  ${ParentLayoutStyles};

  ${p =>
    p.parentLayoutBg &&
    css`
      background: #fff url(${BgBlob}) left top / contain no-repeat;
    `};
`
