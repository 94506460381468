import React from 'react'
import styled from '@emotion/styled'
import Layout from '@/layouts'
import LayoutContainer from '@/components/LayoutContainer'
import Intro from '@/components/Intro'
import VideoTestimonial from '@/components/VideoTestimonial'
import RetirementCommunitiesSrc from '@/assets/retirement-communities_testimonial.jpg'
import HomeHealthSrc from '@/assets/home-health_testimonial.jpg'
import AdultDayCareSrc from '@/assets/adult-day-care_testimonial.jpg'
import AtHomeSrc from '@/assets/at-home_testimonial.jpg'

const _testimonials = [
  {
    title: 'Retirement Communities',
    videoSrc: `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4qym37rRG-U"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />`,
    src: RetirementCommunitiesSrc,
    alt: '',
  },
  {
    title: 'Home Health',
    videoSrc: `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4qym37rRG-U"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />`,
    src: HomeHealthSrc,
    alt: '',
  },
  {
    title: 'Adult Day Care',
    videoSrc: `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4qym37rRG-U"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />`,
    src: AdultDayCareSrc,
    alt: '',
  },
  {
    title: 'At Home',
    videoSrc: `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4qym37rRG-U"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />`,
    src: AtHomeSrc,
    alt: '',
  },
]

function TestimonialsPage() {
  return (
    <Layout parentLayout>
      <LayoutContainer>
        <Intro
          title="Testimonials"
          titleAccent
          description="Listen to the real life experiences of our life plan community residents and program participants."
        />
        {_testimonials && (
          <Testimonials>
            {_testimonials.map((testimonial, i) => (
              <VideoTestimonial
                key={`video-testimonial-${i}`}
                {...testimonial}
              />
            ))}
          </Testimonials>
        )}
      </LayoutContainer>
    </Layout>
  )
}

export default TestimonialsPage

const Testimonials = styled.div`
  display: grid;
  grid-gap: var(--d-gutter);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 335px;
  padding-bottom: 100px;
`
