import React from 'react'
import { rem } from 'polished'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { LinkButton } from '@/components/Button'
import { TitleStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'

type Props = {
  title: String,
  src: String,
  children: React.ReactNode,
  ctaLabel: String,
  ctaUrl: String,
}

export default ({ title, src, children, ctaLabel, ctaUrl }: Props) => (
  <CareOption>
    <Image loading="lazy" src={src} alt="" />
    <Content>
      <Title>{title}</Title>
      <div>{children}</div>
      {ctaUrl && ctaLabel && (
        <LinkButton css={LinkButtonStyles} to={ctaUrl}>
          {ctaLabel}
        </LinkButton>
      )}
    </Content>
  </CareOption>
)

const Title = styled.h2`
  ${TitleStyles};
  margin-top: 0;
`

const LinkButtonStyles = css`
  width: 100%;
`

const CareOption = styled.div`
  display: grid;
  background: #fff;
  color: var(--c-gunmetal);

  ${mq.greaterThan('mediumSmall')`
    grid-template-columns: 4.5fr 7.5fr;
  `};

  li {
    line-height: 1.5;
    margin: 10px 0;
  }

  ul {
    padding-left: 1.5rem;
  }

  .col {
    p {
      font-size: ${rem('14px')};
      letter-spacing: normal;

      &.list-header {
        font-size: ${rem('18px')};
        letter-spacing: ${rem('0.5px')};
        line-height: 1.5;
      }
    }
  }
`

const Image = styled.img`
  max-height: 50vh;
  display: block;
  object-fit: cover;
  object-position: top;
  width: 100%;

  ${mq.greaterThan('mediumSmall')`
    max-height: none;
    height: 100%;
  `};
`

const Content = styled.div`
  padding: var(--d-gutter);

  ${mq.greaterThan('mediumSmall')`
    padding: 50px;
  `};
`
