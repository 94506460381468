import unionBy from 'lodash/unionBy'

const DUMMY_PRISMIC_ID = 'XunPcRAAAB8Aj3EI'

const shapeJob = job => {
  return {
    prismicId: job.prismicId,
    title: job.title,
    simpleDescription: job.simpleDescription,
    companies:
      job?.companies?.map(
        _company =>
          _company?.company?.document?.data?.shortTitle ??
          _company?.company?.document?.data?.title
      ) ?? [],
    jobTypes:
      job?.types?.map(_jobType => _jobType?.type?.document?.data?.title) ?? [],
    jobDepartments:
      job?.departments
        ?.filter(_dept => _dept?.department?.document)
        .map(_dept => _dept?.department.document.uid) ?? [],
    url: job?.url?.url,
    keywords: job?.keywords ?? '',
  }
}

const shapeJobs = jobs => jobs.map(job => shapeJob(job))

const combineJobs = (allJobsFromQuery, orderedJobs) =>
  unionBy(
    orderedJobs
      .filter(job => job.job.document.prismicId !== DUMMY_PRISMIC_ID)
      .map(job => job.job.document),
    allJobsFromQuery,
    'prismicId'
  ).map(node => node.data)

export const JobsFormatters = {
  shapeJob,
  shapeJobs,
  combineJobs,
}
