import React from 'react'
import { rem } from 'polished'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import { ButtonStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'

const _headerNavLinks = [
  {
    label: 'Meet Our Family',
    url: '/careers/meet-our-family',
  },
  {
    label: 'Companies',
    url: '/careers/companies',
  },
  {
    label: 'Benefits',
    url: '/careers/benefits',
  },
  {
    label: 'Contact',
    url: '/contact',
    type: 'fancy',
    style: [
      ButtonStyles,
      css`
        && {
          ${mq.lessThan('medium')`
            margin: 25px 0;
            font-family: var(--f-sans);
            font-size: ${rem('24px')};
            letter-spacing: ${rem('2px')};
          `};
        }
      `,
    ],
  },
]

type Props = {
  children?: React.ReactNode,
}

export default ({ children, ...rest }: Props) => (
  <Layout headerNavLinks={_headerNavLinks} {...rest}>
    {children}
  </Layout>
)
