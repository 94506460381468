import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layouts'
import Intro from '@/components/Intro'
import SectionNav from '@/components/SectionNav'
import ContentSection, {
  Content,
  Question,
  Answer,
  ContentSections,
} from '@/components/ContentSection'
import slugify from 'slugify'

type Props = {
  data: Object,
}

function QuestionsPage({
  data: {
    page: {
      data: {
        title: { text: title },
        description: { html: description },
        body: sections,
      },
    },
  },
}: Props) {
  return (
    <Layout parentLayout>
      <Intro
        title={title}
        description={<div dangerouslySetInnerHTML={{ __html: description }} />}
        titleAccent
      />
      {sections && (
        <>
          <SectionNav
            title="Categories"
            bgColor="var(--c-veryLightPink"
            navLinks={sections.map(({ primary: { title } }) => ({
              label: title,
              id: `${slugify(title, { lower: true })}`,
            }))}
          />
          <ContentSections>
            {sections.map(({ primary: { title }, items }) => (
              <ContentSection
                id={`${slugify(title, { lower: true })}`}
                key={`${slugify(title, { lower: true })}`}
                title={title}>
                {items.map(({ question, answer: { html: answer } }) => (
                  <Content key={`qa-${question}`}>
                    <Question>{question}</Question>
                    <Answer dangerouslySetInnerHTML={{ __html: answer }} />
                  </Content>
                ))}
              </ContentSection>
            ))}
          </ContentSections>
        </>
      )}
    </Layout>
  )
}

export default QuestionsPage

export const query = graphql`
  {
    page: prismicQuestions {
      data {
        title {
          text
        }
        description {
          html
        }
        body {
          ... on PrismicQuestionsDataBodyQaSection {
            slice_type
            primary {
              title: section_title
            }
            items {
              question
              answer {
                html
              }
            }
          }
        }
      }
    }
  }
`
