import '@/styles/global.css' // version 2

// TODO: verify below works. this is from version 4 starter template
// import * as React from 'react'
// import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// import { repositoryConfigs } from './config/prismic/previews'

// import './src/normalize.css'
// import './src/style.css'

// export const wrapRootElement = ({ element }) => (
//   <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
//     {element}
//   </PrismicPreviewProvider>
// )
