// @flow

import React from 'react'
import Form from '@/components/Forms/HaleOlaKinoForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => <Form action={action} />
