// @flow

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import nanoid from 'nanoid'
import Button from '@/components/Button'
import NewsletterForm from '@/components/Forms/NewsletterForm'

type Props = {
  options: Array,
  onChange: Function,
  value: Object,
}

export default ({ options, onChange, value: _value }: Props) => {
  const [value, setValue] = useState(_value)
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [value, onChange])

  return (
    <Container>
      {options.map(category => {
        return (
          <li key={nanoid()}>
            <Button
              onClick={_ => {
                setValue(category)
                return false
              }}>
              {category.label}
            </Button>
          </li>
        )
      })}

      <li>
        <NewsletterForm name="newsletter-opt-in" submitLabel="Email Me" />
      </li>
    </Container>
  )
}

const Container = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;
  grid-gap: calc(var(--d-gutter) / 2) 0;
  list-style-type: none;
  font-size: 18px;
  color: var(--c-charcoalGrey);

  button {
    padding-left: 0;
    padding-right: 0;
    background: none;
    text-align: left;
    :hover {
      text-decoration: underline;
    }
  }
`
