import React from 'react'
import Layout from '@/layouts'
import LayoutContainer from '@/components/LayoutContainer'
import { SidebarLayoutStyles } from '@/styles/shared'

const innerContent = children => (
  <LayoutContainer css={SidebarLayoutStyles}>{children}</LayoutContainer>
)

type Props = {
  className: String,
  children: React.ReactNode,
}

export default ({ children, className, ...rest }: Props) => (
  <Layout className={className} {...rest}>
    {innerContent(children)}
  </Layout>
)
