// @flow

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Intro from '@/components/Intro'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import Slider from 'react-slick'
import LayoutContainer from '@/components/LayoutContainer'
import Button from '@/components/Button'
import { mq } from '@/styles/theme'
import LeafPatternBackground from '@/styles/shared/leaf-pattern-background'
import { FullPseudoBlockStyles } from '@/styles/shared'
import Quote from '@/components/Quote'

// Slick Stylesheet
import 'slick-carousel/slick/slick.css'

const NextButtonStyles = css`
  font-size: ${rem('27px')};
  font-weight: 800;
  letter-spacing: ${rem('3px')};
  display: flex;
  justify-content: space-between;
  z-index: 1;
  padding-left: 35px;
  padding-right: 35px;

  ${mq.greaterThan('mediumSmall')`
    position: relative;
    grid-area: 2 / 4 / 3 / 6;
  `};
`

const NextButton = ({ currentSlide, slideCount, ...rest }) => (
  <Button {...rest} css={NextButtonStyles}>
    <span>Next</span>
    <span>&gt;</span>
  </Button>
)

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  prevArrow: <></>,
  nextArrow: <NextButton />,
}

type Props = {
  title: String,
  description: String,
}

export default ({ title, description }: Props) => {
  const { testimonials: _testimonials } = useStaticQuery(query)
  const testimonials = _testimonials.edges
    .map(t => t.node.data)
    .map(({ name, quote, photo }) => ({
      name,
      quote,
      photo,
    }))

  return (
    <LayoutContainer css={SliderStyles}>
      <Intro title={title} description={description} />
      {
        <Slider {...settings}>
          {testimonials.map(({ name, quote, photo }) => (
            <Slide key={`slider-${photo}`}>
              <Image src={photo.url} alt="" />
              <QuoteBlock>
                <QuoteBlockContent>
                  <cite>
                    <Name>{name}</Name>
                  </cite>
                  <Quote dangerouslySetInnerHTML={{ __html: quote.html }} />
                </QuoteBlockContent>
              </QuoteBlock>
            </Slide>
          ))}
        </Slider>
      }
    </LayoutContainer>
  )
}

const query = graphql`
  {
    testimonials: allPrismicTestimonial {
      edges {
        node {
          data {
            name
            quote {
              html
            }
            photo {
              url
            }
          }
        }
      }
    }
  }
`

const Name = styled.p`
  color: #fff;
  font-size: ${rem('24px')};
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
  margin: 0;
`

const Slide = styled.div`
  position: relative;

  ${mq.greaterThan('mediumSmall')`
    display: grid !important;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 320px 215px 80px;
  `};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mq.greaterThan('mediumSmall')`
    grid-area: 1 / 1 / 3 / 10;
  `};
`

const QuoteBlock = styled.div`
  color: #fff;
  position: relative;
  z-index: 1;

  ${mq.greaterThan('mediumSmall')`
    grid-area: 2 / 6 / 4 / 13;
  `};
`

const QuoteBlockContent = styled.div`
  padding: 60px 30px;

  &::after {
    background: var(--c-darkGreenBlue);
    ${FullPseudoBlockStyles};

    ${mq.greaterThan('mediumSmall')`
      ${FullPseudoBlockStyles};
    `};
  }

  ${mq.greaterThan('mediumSmall')`
    padding: 60px;
    position: absolute;
    bottom: 0;
  `};

  ${mq.greaterThan('mediumSmall')`
    ${LeafPatternBackground({ top: `-var(--d-gutter)` })};
  `};

  * {
    position: relative;
    z-index: 1;
  }
`

const SliderStyles = css`
  margin-bottom: 100px;

  .slick-list {
    grid-area: 1 / 1 / 3 / 13;
    overflow: visible;
  }

  .slick-slider {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 535px 80px;
    overflow: hidden;
  }
`
