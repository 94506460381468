import dayjs from 'dayjs'

const DATE_FORMAT = 'MMMM D, YYYY'

export const gqlFormatter = ({ blogPost, pageContext }) => {
  const uid = blogPost?.uid
  const url = blogPost?.url
  const company = blogPost?.data?.company?.[0]?.data?.title
  const categories = (blogPost?.data?.categories ?? [])
    .map(item => item?.category?.document?.[0]?.data?.title)
    .filter(n => n)
  const tags = blogPost?.tags ?? []
  const next = pageContext?.next
  const previous = pageContext?.previous
  const title = blogPost?.data?.title
  const publishDate = dayjs(blogPost?.data?.publishDate).format(DATE_FORMAT)
  const featuredImage = blogPost?.data?.featuredImage?.url
  const body = blogPost?.data?.body ?? []

  return {
    uid,
    url,
    company,
    categories,
    tags,
    next,
    previous,
    title,
    publishDate,
    featuredImage,
    body,
  }
}

export const apiListItemFormatter = ({ blogPost }) => {
  const uid = blogPost?.uid
  const url = `/news/${uid}/`
  const title = blogPost?.data?.blog_post?.title?.value
  const publishDate = dayjs(
    blogPost?.data?.blog_post?.publish_date?.value
  ).format(DATE_FORMAT)
  const featuredImage =
    blogPost?.data?.blog_post?.featured_image?.value?.main?.url

  return {
    uid,
    url,
    title,
    publishDate,
    featuredImage,
  }
}

export const staticCategoryFormatter = ({ prismicId: id, data }) => {
  return {
    label: data.title,
    value: id,
  }
}
