// @flow

import React from 'react'
import styled from '@emotion/styled'
import { rem } from 'polished'
import { MediaAspectRatio } from '@/styles/shared'
import { mq } from '@/styles/theme'

type Props = {
  title: String,
  icon: Object,
  description: String,
  className?: String,
}

export default ({ title, icon, description, className }: Props) => (
  <Option className={className}>
    <IconWrapper>
      <AspectRatio>
        {icon && <Icon loading="lazy" src={icon.src} alt={icon.alt} />}
      </AspectRatio>
    </IconWrapper>
    <div>
      <Title>{title}</Title>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  </Option>
)

const Option = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 30px;
  align-items: center;

  &.layout--detailed {
    grid-template-columns: none;
  }
`

const IconWrapper = styled.div`
  background: #fff;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 20px;
  max-width: 100px;
`

const Icon = styled.img`
  &&& {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: contain;
  }
`

const Title = styled.p`
  margin: 0 0 10px;
  font-size: ${rem('20px')};
  line-height: 1.5;

  ${mq.greaterThan('small')`
    font-size: ${rem('20px')};
  `};

  .layout--split & {
    font-size: ${rem('18px')};
  }
`

const Description = styled.div`
  /* color: #354042; */
  margin: 0;
  font-size: ${rem('16px')};
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;
`

const AspectRatio = styled.div`
  ${MediaAspectRatio};
  padding-bottom: 100%;
`
