// @flow

import React from 'react'
import MasonryItem from '@/components/MasonryItem'
import LayoutContainer from '@/components/LayoutContainer'
import { MasonryLayoutStyles } from '@/styles/shared'

type Props = {
  items: Array,
}

// eslint-disable-next-line no-unused-vars
export default ({ items }: Props) => (
  <LayoutContainer css={MasonryLayoutStyles}>
    {items.map(item => (
      <MasonryItem key={item}>{item}</MasonryItem>
    ))}
  </LayoutContainer>
)
