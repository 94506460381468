// @flow

import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import LayoutContainer from '@/components/LayoutContainer'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'
import Locations from '@/components/Locations'
import PhotoFeature from '@/components/PhotoFeature'
import Options from '@/components/Options'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import { DescriptionStyles } from '@/styles/shared'
import { rem } from 'polished'

const OptionsStyles = css`
  background: var(--c-veryLightPink);
  padding: 100px 0;
`

const Wrapper = styled.div`
  &.background {
    background: var(--c-veryLightPink);
  }
`

const LocationsDescriptions = styled.div`
  ${DescriptionStyles};
  display: grid;
  color: var(--c-gunmetal);
  font-size: ${rem('16px')};
  max-width: 768px;
  margin-inline: auto;

  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }

  p {
    strong {
      font-size: ${rem('20px')};
      line-height: 1.5;
      font-weight: normal;
    }
  }

  ${mq.greaterThan('small')`
    padding: 60px 0;
  `};
`

type Props = {
  data: Object,
}

function SkilledNursingResi({
  data: {
    page: {
      data: {
        heroTitle,
        heroDescription: { html: heroDescription },
        heroPhoto,
        residences,
        locations_description: { html: locationsDescription },
      },
    },
  },
}: Props) {
  return (
    <Layout>
      <Feature
        wrapperBackground
        hero={true}
        title={heroTitle}
        titleAccent
        largeTitle
        description={
          <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
        }
        mediaBlock={<MediaBlock src={heroPhoto.url} alt="" />}
      />
      <Locations locations={residences} isSkilledNursingPage />
      <LayoutContainer>
        <LocationsDescriptions
          dangerouslySetInnerHTML={{ __html: locationsDescription }}
        />
      </LayoutContainer>
    </Layout>
  )
}

export default SkilledNursingResi

export function Head({
  data: {
    page: {
      data: {
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}) {
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </>
  )
}

export const query = graphql`
  {
    page: prismicSkilledNursingIndexPage {
      data {
        title
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
        heroTitle: hero_title
        heroPhoto: hero_photo {
          url
        }
        heroDescription: hero_description {
          html
        }
        residencesTitle: locations_title
        residences: locations {
          location {
            uid
            type
            document {
              ... on PrismicResidence {
                uid
                type
                data {
                  title
                  description: preview_description {
                    html
                  }
                  ctaLabel: preview_cta_label
                  ctaLink: preview_skilled_nursing_cta_link {
                    uid
                    type
                  }
                  photo: preview_photo {
                    url
                  }
                }
              }
              ... on PrismicSkilledNursingResidencePage {
                uid
                type
                data {
                  title
                  description: preview_description {
                    html
                  }
                  ctaLabel: preview_cta_label
                  ctaLink: preview_skilled_nursing_cta_link {
                    uid
                    type
                  }
                  photo: preview_photo {
                    url
                  }
                }
              }
            }
          }
        }
        locations_description {
          html
        }
      }
    }
  }
`
