// @flow

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import Post from '@/components/Post'
import Button from '@/components/Button'

type Props = {
  posts: Array,
  featuredPost: Object,
  seeMore: Boolean,
}

export default function BlogPosts({
  featuredPost,
  posts,
  seeMore: _seeMore = false,
}: Props) {
  const [seeMore, setSeeMore] = useState(_seeMore)

  const maxPosts = seeMore ? posts.length : 7 // integer is (ideal size)+1
  const postsWithinLimit = posts.slice(0, maxPosts)

  return (
    <>
      <Posts>
        <Post featured="true" blogPost={featuredPost} />
        {postsWithinLimit.map(blogPost => (
          <Post key={blogPost.uid} blogPost={blogPost} />
        ))}
      </Posts>
      <MoreContentButton
        display={posts.length <= 6 ? 'none' : seeMore ? 'none' : 'block'}
        onClick={() => setSeeMore(true)}>
        More
      </MoreContentButton>
    </>
  )
}

const Posts = styled.main`
  display: grid;
  grid-gap: var(--d-gutter);

  ${mq.greaterThan('small')`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-auto-rows: minmax(var(--d-postMinHeight), 1fr);
  `};
`

const MoreContentButton = styled(Button)`
  grid-area: blog-more;
  margin: 0 auto;
  width: 31.5%;
  background: var(--c-green);
  display: ${p => p.display};
`
