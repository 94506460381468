import React from 'react'
import styled from '@emotion/styled'

const Item = styled.div`
  color: var(--c-charcoalGrey);
  padding: 17px 21px;
  width: 100%;
  margin: 0 0 1rem;
  display: inline-block;
  border-radius: 8px;
  background-color: #eef85c;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
`

type Props = {
  children?: React.ReactNode,
}

export default ({ children }: Props) => <Item>{children}</Item>
