// @flow

import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'
import Locations from '@/components/Locations'
import PhotoFeature from '@/components/PhotoFeature'
import Options from '@/components/Options'

const OptionsStyles = css`
  background: var(--c-veryLightPink);
  padding: 100px 0;
`

type Props = {
  data: Object,
}

function ResidenciesPage({
  data: {
    page: {
      data: {
        title,
        heroTitle,
        heroDescription: { html: heroDescription },
        heroPhoto,
        residences,
        careTitle,
        careDescription: { html: careDescription },
        careItems,
        photoFeatureTitle,
        photoFeatureSubtitle,
        photoFeatureDescription: { html: photoFeatureDescription },
        photoFeaturePhoto,
      },
    },
  },
}: Props) {
  return (
    <Layout>
      <Feature
        wrapperBackground
        hero={true}
        title={heroTitle}
        titleAccent
        largeTitle
        description={
          <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
        }
        mediaBlock={<MediaBlock src={heroPhoto.url} alt="" />}
      />
      <Locations locations={residences} />
      <Options
        css={OptionsStyles}
        layout="detailed"
        subtitle="Key Feature"
        title={careTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: careDescription }} />
        }
        options={careItems.map(
          ({
            icon: { url: icon },
            description: { html: description },
            title,
          }) => ({
            icon: { src: icon },
            description,
            title,
          })
        )}
      />
      <PhotoFeature
        subtitle={photoFeatureSubtitle}
        title={photoFeatureTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: photoFeatureDescription }} />
        }
        img={{ src: photoFeaturePhoto.url, alt: '' }}
      />
    </Layout>
  )
}

export default ResidenciesPage

export const query = graphql`
  {
    page: prismicResidences {
      data {
        title
        heroTitle: hero_title
        heroPhoto: hero_photo {
          url
        }
        heroDescription: hero_description {
          html
        }
        residencesTitle: locations_title
        residences: locations {
          location {
            uid
            type
            document {
              ... on PrismicResidence {
                uid
                type
                data {
                  title
                  description: preview_description {
                    html
                  }
                  ctaLabel: preview_cta_label
                  ctaLink: preview_cta_link {
                    uid
                    type
                  }
                  photo: preview_photo {
                    url
                  }
                }
              }
            }
          }
        }
        careTitle: care_title
        careDescription: care_description {
          html
        }
        careItems: care_items {
          icon: care_item_icon {
            url
          }
          title: care_item_title
          description: care_item_description {
            html
          }
        }
        photoFeatureTitle: photo_feature_title
        photoFeatureSubtitle: photo_feature_subtitle
        photoFeatureDescription: photo_feature_description {
          html
        }
        photoFeaturePhoto: photo_feature_photo {
          url
        }
      }
    }
  }
`
