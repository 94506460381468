import styled from '@emotion/styled'
import { ResetButtonStyles } from '@/styles/shared'

export default styled.button`
  ${ResetButtonStyles};
  font-size: 1.5rem;
  position: absolute;
  right: 10px;
  top: 10px;
`
