import * as React from 'react'
const SvgInstagram = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g fill="#255D63" fillRule="evenodd">
      <path d="M15.995 0C11.651 0 11.106.018 9.4.096 7.698.174 6.535.444 5.518.84a7.84 7.84 0 0 0-2.833 1.845A7.84 7.84 0 0 0 .84 5.518C.444 6.535.174 7.698.096 9.4.018 11.106 0 11.651 0 15.995c0 4.344.018 4.889.096 6.595.078 1.702.348 2.865.744 3.882a7.841 7.841 0 0 0 1.845 2.833 7.84 7.84 0 0 0 2.833 1.845c1.017.396 2.18.666 3.882.744 1.706.077 2.251.096 6.595.096 4.344 0 4.889-.019 6.595-.096 1.702-.078 2.865-.348 3.882-.744a7.841 7.841 0 0 0 2.833-1.845 7.841 7.841 0 0 0 1.845-2.833c.396-1.017.666-2.18.744-3.882.077-1.706.096-2.251.096-6.595 0-4.344-.019-4.889-.096-6.595-.078-1.702-.348-2.865-.744-3.882a7.84 7.84 0 0 0-1.845-2.833A7.841 7.841 0 0 0 26.472.84C25.455.444 24.292.174 22.59.096 20.884.018 20.339 0 15.995 0Zm0 2.882c4.27 0 4.777.016 6.463.093 1.56.071 2.407.332 2.97.551.747.29 1.28.637 1.84 1.196.56.56.906 1.093 1.196 1.84.219.563.48 1.41.55 2.97.078 1.686.094 2.192.094 6.463 0 4.27-.016 4.777-.093 6.463-.071 1.56-.332 2.407-.551 2.97a4.955 4.955 0 0 1-1.197 1.84c-.56.56-1.092.906-1.839 1.196-.563.219-1.41.48-2.97.55-1.686.078-2.192.094-6.463.094s-4.777-.016-6.463-.093c-1.56-.071-2.407-.332-2.97-.551a4.956 4.956 0 0 1-1.84-1.197 4.955 4.955 0 0 1-1.196-1.839c-.22-.563-.48-1.41-.55-2.97-.078-1.686-.094-2.192-.094-6.463 0-4.27.016-4.777.093-6.463.071-1.56.332-2.407.551-2.97.29-.747.637-1.28 1.196-1.84a4.957 4.957 0 0 1 1.84-1.196c.563-.219 1.41-.48 2.97-.55 1.686-.078 2.192-.094 6.463-.094Z" />
      <path d="M15.995 21.327a5.332 5.332 0 1 1 0-10.664 5.332 5.332 0 0 1 0 10.664Zm0-13.546a8.214 8.214 0 1 0 0 16.428 8.214 8.214 0 0 0 0-16.428ZM26.453 7.457a1.92 1.92 0 1 1-3.84 0 1.92 1.92 0 0 1 3.84 0Z" />
    </g>
  </svg>
)
export default SvgInstagram
