import React from 'react'
import { css } from '@emotion/core'

type Props = {
  id: String,
  offset: Number,
  children: React.ReactNode,
}

export default ({ offset, id, children, ...rest }: Props) => (
  <>
    <div
      id={id}
      css={css`
        position: absolute;
        pointer-events: none;
        width: 100%;
        ${offset &&
        css`
          transform: translateY(${offset}px);
        `}
      `}
      {...rest}
    />
    {children}
  </>
)
