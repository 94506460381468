// @flow

import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'
import PhotoFeature from '@/components/PhotoFeature'
import Options from '@/components/Options'
import { LinkButton } from '@/components/Button'
import ServiceAreas from '@/components/ServiceAreas'

const OptionsStyles = css`
  background: var(--c-veryLightPink);
  padding: 100px 0;
`

type Props = {
  data: Object,
}

function HomeHealthPage({
  data: {
    page: {
      data: {
        title,
        heroTitle,
        heroDescription: { html: heroDescription },
        heroPhoto: { url: heroPhoto },
        servicesTitle,
        servicesDescription: { html: servicesDescription },
        services,
        photoFeatureTitle,
        photoFeatureSubtitle,
        photoFeatureDescription: { html: photoFeatureDescription },
        photoFeaturePhoto: { url: photoFeaturePhoto },
        serviceAreasTitle,
        serviceAreasMap: { url: serviceAreasMap },
        serviceAreas,
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}: Props) {
  return (
    <Layout>
      <Feature
        wrapperBackground
        hero={true}
        title={heroTitle}
        titleAccent
        largeTitle
        LinkButton
        description={
          <>
            <div dangerouslySetInnerHTML={{ __html: heroDescription }} />
            <LinkButton to="/home-health-fact-sheet-request">
              Download Brochure
            </LinkButton>
          </>
        }
        mediaBlock={<MediaBlock src={heroPhoto} alt="" />}
      />

      <ServiceAreas
        title={serviceAreasTitle}
        mapSrc={serviceAreasMap}
        serviceAreas={serviceAreas}
        config={{
          copyType: 'PrismicHomeHealthDataServiceAreasServiceAreasCopy',
          buttonType: 'PrismicHomeHealthDataServiceAreasServiceAreasButton',
        }}
      />
      <Options
        css={OptionsStyles}
        layout="detailed"
        subtitle="Key Feature"
        title={servicesTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: servicesDescription }} />
        }
        options={services.map(
          ({
            icon: { url: icon },
            description: { html: description },
            title,
          }) => ({
            icon: { src: icon },
            description,
            title,
          })
        )}
      />
      <PhotoFeature
        subtitle={photoFeatureSubtitle}
        title={photoFeatureTitle}
        description={
          <div dangerouslySetInnerHTML={{ __html: photoFeatureDescription }} />
        }
        img={{ src: photoFeaturePhoto, alt: '' }}
      />
    </Layout>
  )
}

export default HomeHealthPage

export function Head({
  data: {
    page: {
      data: {
        metaTitle,
        metaDescription: { text: metaDescription },
      },
    },
  },
}) {
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </>
  )
}

export const query = graphql`
  {
    page: prismicHomeHealth {
      data {
        title
        heroTitle: hero_title
        heroPhoto: hero_photo {
          url
        }
        heroDescription: hero_description {
          html
        }
        servicesTitle: services_title
        servicesDescription: services_description {
          html
        }
        services {
          icon: service_icon {
            url
          }
          title: service_title
          description: service_description {
            html
          }
        }
        photoFeatureTitle: photo_feature_title
        photoFeatureSubtitle: photo_feature_subtitle
        photoFeatureDescription: photo_feature_description {
          html
        }
        photoFeaturePhoto: photo_feature_photo {
          url
        }
        serviceAreasTitle: service_areas_title
        serviceAreasMap: service_areas_map {
          url
        }
        serviceAreas: service_areas {
          ... on PrismicHomeHealthDataServiceAreasServiceAreasCopy {
            primary {
              serviceAreasCopyTitle: service_areas_copy_title {
                html
                text
              }
              serviceAreasCopyBody: service_areas_copy_body {
                html
                text
              }
            }
          }
          ... on PrismicHomeHealthDataServiceAreasServiceAreasButton {
            primary {
              serviceAreasButtonLabel: service_areas_button_label
              serviceAreasButtonLink: service_areas_button_link {
                uid
                type
              }
            }
          }
        }
        metaTitle: meta_title
        metaDescription: meta_description {
          text
        }
      }
    }
  }
`
