import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@/styles/theme'
import { MediaAspectRatio, TitleStyles } from '@/styles/shared'
import { LinkButton } from '@/components/Button'

type Props = {
  imageSrc: String,
  title: String,
  description: String,
  reverseLayout: Boolean,
}

export default ({ imageSrc, title, description, reverseLayout }: Props) => (
  <JobDepartment reverseLayout={reverseLayout}>
    <AspectRatio reverseLayout={reverseLayout}>
      <img loading="lazy" src={imageSrc} alt="" />
    </AspectRatio>
    <QuoteBlock reverseLayout={reverseLayout}>
      <Title>{title}</Title>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
      <LinkButton to="/careers#jobs">Join Our Family</LinkButton>
    </QuoteBlock>
  </JobDepartment>
)

const JobDepartment = styled.div`
  display: grid;
  ${mq.lessThan('small')`
    grid-template-areas: 'media' 'copy';
  `};

  ${mq.greaterThan('small')`
    grid-template-columns: 2fr 3fr;
  `}

  ${p =>
    p.reverseLayout &&
    css`
      ${mq.greaterThan('small')`
        grid-template-columns: 3fr 2fr;
      `};
    `}
`

const AspectRatio = styled.div`
  ${MediaAspectRatio};
  padding-bottom: 100%;
  order: 1;

  ${mq.lessThan('small')`
    grid-area: media;
  `};

  ${mq.greaterThan('small')`
    padding-bottom: 120%;
  `};

  ${p =>
    p.reverseLayout &&
    css`
      order: 2;
    `}
`

const QuoteBlock = styled.div`
  order: 2;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: var(--d-gutter);
  display: grid;
  align-content: center;

  ${mq.lessThan('small')`
    grid-area: copy;
  `};

  ${mq.greaterThan('small')`
    padding: 70px;
  `};

  ${p =>
    p.reverseLayout &&
    css`
      order: 1;
    `}
`

const Title = styled.p`
  ${TitleStyles};
  margin: 0 0 var(--d-gutter);
  color: var(--c-lightGreyBlue);
  margin-bottom: 0;
`

const Description = styled.div`
  display: block;
  margin-bottom: var(--d-gutter);

  ul {
    column-count: 2;
    column-gap: var(--d-gutter);
  }
`
