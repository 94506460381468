// @flow

import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'

type Props = {
  id?: String,
  labelCss: String,
  label: String,
  options: Array,
  onChange: Function,
  value: Object,
}

export default ({
  id,
  label,
  options,
  labelCss,
  onChange,
  value: _value,
}: Props) => {
  const [value, setValue] = useState(_value)
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [value, onChange])

  return (
    <Item htmlFor={id}>
      <Label css={labelCss}>{label}</Label>
      <Select
        id={id}
        css={SelectMenuStyles}
        value={value}
        onChange={value => setValue(value)}
        options={options}
        isClearable
      />
    </Item>
  )
}

const Item = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Label = styled.span`
  color: #fff;
  font: 800 ${rem('24px')} var(--f-serif);
  letter-spacing: ${rem('0.5px')};
  line-height: 1.2;
  margin-bottom: 0.5rem;
`

const SelectMenuStyles = css`
  flex: 1;
  width: 100%;
`
