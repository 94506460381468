// @flow

import React from 'react'
import Modal from 'react-modal'
import styled from '@emotion/styled'
import CloseButton from '@/components/CloseButton'

type Props = {
  videoTitle: String,
  videoSrc: String,
  isOpen: Boolean,
  embedHtml: String,
  closeFn: Function,
}

export default ({
  videoTitle,
  videoSrc,
  isOpen,
  embedHtml,
  closeFn,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeFn}
    shouldCloseOnOverlayClick={true}
    contentLabel="Video"
    style={{
      overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: '10',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',
        width: 'calc(100vw - 60px)',
        padding: '0',
        borderWidth: '0',
        maxHeight: 'calc(100vh - 60px)',
        overflow: 'hidden',
        position: 'relative',
        maxWidth: '800px',
        background: 'transparent',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
      },
    }}>
    <CloseButton onClick={closeFn}>
      <span role="img" aria-label="close modal">
        ✖️
      </span>
    </CloseButton>
    {embedHtml && (
      <EmbedContainer dangerouslySetInnerHTML={{ __html: embedHtml }} />
    )}
    {!embedHtml && (
      <EmbedContainer>
        <iframe
          title={videoTitle}
          src={videoSrc}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          webkitallowfullscreen
          mozallowfullscreen
        />
      </EmbedContainer>
    )}
  </Modal>
)

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
