// @flow

import React from 'react'
import { Input, Select } from '@/components/Form'

const options = [
  { label: 'Age 59 and under', value: 'Age 59 and under' },
  { label: 'Age 60-64', value: 'Age 60-64' },
  { label: 'Age 65-69', value: 'Age 65-69' },
  { label: 'Age 70-74', value: 'Age 70-74' },
  { label: 'Age 75-79', value: 'Age 75-79' },
  { label: 'Age 80-84', value: 'Age 80-84' },
  { label: 'Age 85-89', value: 'Age 85-89' },
  { label: 'Age 90-94', value: 'Age 90-94' },
  { label: 'Age 95-99', value: 'Age 95-99' },
  { label: 'Age 100 and over', value: 'Age 100 and over' },
]

const initialValue = options[0].value

const fieldName = 'guest-age'

const Component = () => (
  <Input
    label="Guest Age"
    field={<Select name={fieldName} id={fieldName} options={options} />}
  />
)

export default {
  Component,
  initialValue,
}
