// @flow

import React from 'react'
import styled from '@emotion/styled'
import { mq } from '@/styles/theme'
import LayoutContainer from '@/components/LayoutContainer'
import {
  MediaAspectRatio,
  AbsoluteFill,
  TitleStyles,
  PreHeaderStyles,
  DescriptionStyles,
  GradientOverlay,
} from '@/styles/shared'

type Props = {
  subtitle: String,
  title: String,
  description: String,
  img: Object,
  children: React.ReactNode,
}

export default ({ subtitle, title, description, img, children }: Props) => (
  <Wrapper>
    <Copy>
      <PreHeader>{subtitle}</PreHeader>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </Copy>
    <AspectRatio>
      <img loading="lazy" src={img.src} alt={img.alt} />
    </AspectRatio>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;

  ${mq.lessThan('small')`
    display: grid;
    grid-template-areas: 'media' 'copy';
  `};
`

const AspectRatio = styled.div`
  ${mq.greaterThan('small')`
    ${MediaAspectRatio};
  `};

  ${mq.lessThan('small')`
    grid-area: media;
  `};

  img {
    ${mq.lessThan('small')`
      width: 100vw;
      height: 100vw;
      object-fit: cover;
      object-position: right;
    `};
  }

  &::after {
    ${mq.greaterThan('small')`
      ${GradientOverlay};
    `};
  }
`

const Copy = styled(LayoutContainer)`
  ${AbsoluteFill};
  position: relative;
  height: auto;
  padding: var(--d-gutter);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${mq.lessThan('small')`
    grid-area: copy;
  `};

  ${mq.greaterThan('small')`
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    position: absolute;
  `};

  * {
    ${mq.greaterThan('small')`
      max-width: 75%;
    `};
  }
`

const Title = styled.h2`
  ${TitleStyles};
  margin: 0 0 1rem;

  ${mq.greaterThan('small')`
    color: #fff;
  `};
`

const PreHeader = styled.h3`
  ${PreHeaderStyles};
  margin: 0;

  ${mq.greaterThan('small')`
    color: var(--c-canary);
  `};
`

const Description = styled.div`
  ${DescriptionStyles};
  margin: 0;

  ${mq.greaterThan('small')`
    color: #fff;
  `};
`
