// @flow

import React from 'react'
import { css } from '@emotion/core'
import { object } from 'yup'
import Form from '@/components/Form'
import { FirstNameLastName, Email } from '@/components/form-fields'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  email: Email.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  email: Email.initialValue,
}

const FormStyles = css`
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
`

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  validateOnMount: true,
}

type Props = {
  action: String,
  className: String,
  name: String,
}

export default ({ action, name = 'Simple Contact Form', className }: Props) => (
  <Form
    name={name}
    action={action}
    submitLabel="Submit"
    formikOptions={formikOptions}
    className={className}
    css={FormStyles}>
    <FirstNameLastName.Component />
    <Email.Component required />
  </Form>
)
