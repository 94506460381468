// @flow

import React from 'react'
import { object } from 'yup'
import Form from '@/components/Form'
import { NewsletterComponent } from './ContactForm'
import {
  FirstNameLastName,
  Phone,
  Email,
  Comments,
  Referrals,
} from '@/components/form-fields'

const validationSchema = object().shape({
  'first-name': FirstNameLastName.schema.required(),
  'last-name': FirstNameLastName.schema.required(),
  phone: Phone.schema.required(),
  email: Email.schema,
  comments: Comments.schema,
  referrals: Referrals.schema,
})

const initialValues = {
  'first-name': FirstNameLastName.initialValue,
  'last-name': FirstNameLastName.initialValue,
  phone: Phone.initialValue,
  email: Email.initialValue,
  comments: Comments.initialValue,
  referrals: [],
}

const formikOptions = {
  validationSchema,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
}

type Props = {
  name: String,
  action: String,
  submitLabel: String,
}

export default ({ name, action, submitLabel }: Props) => (
  <Form
    name={name}
    action={action}
    submitLabel={submitLabel}
    formikOptions={formikOptions}>
    <FirstNameLastName.Component />
    <Phone.Component />
    <Comments.Component />
    <Referrals.Component />
    <NewsletterComponent />
  </Form>
)
