import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '@/layouts'
import LayoutContainer from '@/components/LayoutContainer'
import SimpleContactForm from '@/components/Forms/SimpleContactForm'
import Feature from '@/components/Feature'
import MediaBlock from '@/components/MediaBlock'

const FormSection = styled.section`
  background: var(--c-veryLightPink);
  padding-bottom: 100px;

  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-component {
    width: 100%;
  }
`

const headerNavLinks = [
  {
    label: 'Home',
    url: '/',
    type: 'normal',
  },
  {
    label: 'Residences',
    url: '/residences',
    type: 'normal',
  },
  {
    label: 'Home Care',
    url: '/home-health',
    type: 'normal',
  },
  {
    label: 'At Home',
    url: '/at-home',
    type: 'normal',
  },
]

type LandingPageProps = {
  data: Object,
  pageContext: Object,
}

function LandingPageTemplate({ data, pageContext }: LandingPageProps) {
  return (
    <Layout
      disableFooter
      headerNavLinks={headerNavLinks}
      logo={
        <>
          <img
            className="logo"
            loading="lazy"
            src={data?.page?.data?.logo?.url}
            alt={`Logo for ${data?.page?.data?.title}`}
          />
        </>
      }>
      <Feature
        wrapperBackground
        hero={true}
        title={data?.page?.data?.title}
        titleAccent
        largeTitle
        description={
          <>
            <div
              dangerouslySetInnerHTML={{ __html: data?.page?.data?.body?.html }}
            />
          </>
        }
        mediaBlock={<MediaBlock src={data?.page?.data?.image?.url} alt="" />}
      />
      <FormSection>
        <LayoutContainer className="layout">
          <SimpleContactForm
            name={`${data?.page?.data?.title} Landing Page - Brochure Request`}
            action={`/landing/${pageContext?.uid}-submission`}
            submitLabel="Get Brochure"
          />
          {/* <LinkButton
          to={`/`}
          css={css`
            margin-top: 60px;
          `}>
          Learn More About Arcadia Family of Companies
        </LinkButton> */}
        </LayoutContainer>
      </FormSection>
    </Layout>
  )
}

export default LandingPageTemplate

export const query = graphql`
  query ($uid: String!) {
    page: prismicLandingPage(uid: { eq: $uid }) {
      data {
        title
        body {
          html
        }
        logo {
          url
        }
        image {
          url
        }
        parent_page {
          uid
          document {
            ... on PrismicResidence {
              id
              data {
                title
              }
            }
          }
        }
      }
    }
  }
`
