// @flow

import React from 'react'
import styled from '@emotion/styled'
import LayoutContainer from '@/components/LayoutContainer'
import { TitleStyles, AccentRuleStyles } from '@/styles/shared'
import { mq } from '@/styles/theme'
import LeafPattern from '@/assets/leaf-pattern.png'
import { LinkButton } from '@/components/Button'
import ContentColumn from '@/components/ContentColumn'
import nanoid from 'nanoid'

type Props = {
  location?: String,
  title: String,
  mapSrc: String,
  serviceAreas: Array,
  config: Object,
}

export default ({ title, mapSrc, serviceAreas, config }: Props) => {
  // console.log(serviceAreas)
  // console.log(config)

  return (
    <ServiceAreas>
      <ServiceAreasLayoutContainer>
        <Title>{title}</Title>
        <MapImg src={mapSrc} alt="Map of Service Areas" />
        <ServiceAreasContent>
          {serviceAreas.map(({ slice_type, primary }) => (
            <div key={nanoid()}>
              {slice_type === config.copyType && primary && (
                <ServiceAreasCopy key={nanoid()} {...primary} />
              )}
              {slice_type === config.buttonType && primary && (
                <ServiceAreasButton key={nanoid()} {...primary} />
              )}
            </div>
          ))}
        </ServiceAreasContent>
      </ServiceAreasLayoutContainer>
    </ServiceAreas>
  )
}

type ServiceAreasCopyProps = {
  serviceAreasCopyTitle: String,
  serviceAreasCopyBody: String,
}

export const ServiceAreasCopy = ({
  serviceAreasCopyTitle,
  serviceAreasCopyBody,
}: ServiceAreasCopyProps) => {
  // console.log(serviceAreasCopyTitle)
  return (
    <ContentColumn
      dangerouslySetInnerHTML={{
        __html: serviceAreasCopyTitle.html + serviceAreasCopyBody.html,
      }}
    />
  )
}

type ServiceAreasButtonProps = {
  serviceAreasButtonLabel: String,
  serviceAreasButtonLink: Object,
}

export const ServiceAreasButton = ({
  serviceAreasButtonLabel,
  serviceAreasButtonLink,
}: ServiceAreasButtonProps) => {
  // console.log(serviceAreasButtonLink)
  return (
    <ContentColumn key={serviceAreasButtonLabel}>
      {serviceAreasButtonLabel && (
        <LinkButton to={serviceAreasButtonLink.url}>
          {serviceAreasButtonLabel}
        </LinkButton>
      )}
    </ContentColumn>
  )
}

const MapImg = styled.img`
  display: block;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  margin: var(--d-gutter) 0;
`

export const LinkButtonWithAccent = styled(LinkButton)`
  ${AccentRuleStyles({ color: 'var(--c-canary)', top: '-40px' })};
  margin-top: 55px;
`

const Title = styled.h2`
  ${TitleStyles};
  ${AccentRuleStyles({ color: 'var(--c-canary)' })};
  text-align: center;
  color: #fff;
`

const ServiceAreas = styled.div`
  background: var(--c-darkGreenBlue) url(${LeafPattern});
  padding: 85px 0;
  color: #fff;
`

export const ServiceAreasContent = styled.div`
  display: grid;
  grid-gap: var(--d-gutter);

  ${mq.greaterThan('small')`
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-items: baseline;
  `};
`

const ServiceAreasLayoutContainer = styled(LayoutContainer)`
  display: grid;
  grid-gap: var(--d-gutter);
  justify-content: center;
  justify-items: center;
`
