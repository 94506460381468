import React from 'react'
import { rem } from 'polished'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Layout from '@/layouts'
import LayoutContainer from '@/components/LayoutContainer'
import { mq } from '@/styles/theme'
import { AccentRuleStyles, TitleStyles } from '@/styles/shared'
import { LinkButton } from '@/components/Button'

const Title = styled.div`
  ${TitleStyles};

  font-size: ${rem('46px')};
  letter-spacing: ${rem('-1.5px')};
  margin: 0 auto 10px;
  max-width: 800px;
  text-align: center;

  ${p =>
    p.largeTitle &&
    css`
      font-weight: 600;
      font-size: ${rem('56px')};
      letter-spacing: ${rem('-1.15px')};

      ${mq.greaterThan('small')`
        margin: 0 0 var(--d-gutter);
        font-size: ${rem('80px')};
      `};
    `}

  ${p =>
    p.titleAccent &&
    css`
      margin-top: 25px;
      ${AccentRuleStyles({ justify: 'left' })};
    `}
`

const ContentSection = styled.section`
  background: var(--c-veryLightPink);

  .layout {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

type LandingPageProps = {
  data: Object,
}

function LandingSubmissionTemplate({ data }: LandingPageProps) {
  return (
    <Layout
      nav={false}
      disableFooter
      parentLayout
      logo={
        <>
          <img
            className="logo"
            loading="lazy"
            src={data?.page?.data?.logo?.url}
            alt={`Logo for ${data?.page?.data?.title}`}
          />
        </>
      }>
      <ContentSection>
        <LayoutContainer className="layout">
          <Title
            dangerouslySetInnerHTML={{
              __html: data?.page?.data?.submissionDescription?.html,
            }}
          />
          {data?.page?.data?.downloadLink.url && (
            <LinkButton to={data?.page?.data?.downloadLink.url}>
              Download Brochure
            </LinkButton>
          )}
        </LayoutContainer>
      </ContentSection>
    </Layout>
  )
}

export default LandingSubmissionTemplate

export const query = graphql`
  query ($uid: String!) {
    page: prismicLandingPage(uid: { eq: $uid }) {
      data {
        title
        logo {
          url
        }
        submissionDescription: submission_description {
          html
        }
        downloadLink: download_link {
          url
        }
      }
    }
  }
`
