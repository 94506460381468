import React, { createContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const JobsContext = createContext({})

interface JobsProviderProps {
  children: React.ReactNode
}

export const JobsProvider = ({ children }: JobsProviderProps) => {
  const data = useStaticQuery(query)
  const [jobs, setJobs] = useState(data.jobs.edges.map(edge => edge.node))

  return (
    <JobsContext.Provider
      value={{
        jobs,
        setJobs,
      }}>
      {children}
    </JobsContext.Provider>
  )
}

const query = graphql`
  {
    jobs: allPrismicJobPosting(
      sort: { fields: first_publication_date, order: DESC }
      filter: { prismicId: { ne: "XunPcRAAAB8Aj3EI" } }
    ) {
      edges {
        node {
          prismicId
          ...jobPosting
        }
      }
    }
  }
`
