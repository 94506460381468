// @flow

import React from 'react'
import { rem } from 'polished'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Scrollspy from 'react-scrollspy'
import LayoutContainer from '@/components/LayoutContainer'
import { mq } from '@/styles/theme'

type Props = {
  title: String,
  navLinks: Array,
  bgColor: String,
}

export default ({ title, navLinks, bgColor }: Props) => (
  <Header bgColor={bgColor}>
    <SectionNavLayout>
      <Title>{title}</Title>
      {navLinks && (
        <Scrollspy
          css={NavStyles}
          items={navLinks.map(link => link.id)}
          currentClassName="active"
          componentTag="nav">
          {navLinks.map(navLink => (
            <NavLink
              key={`navLink-${navLink.id}`}
              active
              href={`#${navLink.id}`}>
              {navLink.label}
            </NavLink>
          ))}
        </Scrollspy>
      )}
    </SectionNavLayout>
  </Header>
)

const Title = styled.h1`
  color: var(--c-gunmetal);
  font: 800 ${rem('30px')} var(--f-serif);
  line-height: 1.2;
  padding: var(--d-gutter) 0;
  margin: 0;

  ${mq.lessThan('small')`
    font-size: ${rem('48px')};
    font-weight: 600;
  `};
`

const SectionNavLayout = styled(LayoutContainer)`
  display: grid;
  grid-gap: 0 60px;
  grid-auto-flow: column;
  align-items: flex-end;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: var(--d-gutter);
    bottom: 0;
    width: calc(100% - var(--d-gutter) * 2);
    height: 1px;
    background: var(--c-grey);
  }
`

const Header = styled.div`
  ${mq.greaterThan('small')`
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
  `};

  ${p =>
    p.bgColor &&
    css`
      background: ${p.bgColor};
    `}
`

const NavStyles = css`
  display: none;

  ${mq.greaterThan('small')`
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    grid-gap: 0 var(--d-gutter);
    padding-left: 0;
    margin: 0;
  `};
`

const NavLink = styled.a`
  font-size: ${rem('18px')};
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;
  position: relative;
  padding: var(--d-gutter) 0;

  && {
    color: var(--c-gunmetal);
  }

  &.active {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 1px;
      left: 0;
      background: var(--c-lightGreyBlue);
    }
  }
`
