// @flow

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Feature from '@/components/Feature'
import MediaBlockWithVideo from '@/components/MediaBlockWithVideo'

export function CarrerPageFeature() {
  const {
    page: {
      data: {
        heroTitle,
        qrCode,
        heroDescription: { html: heroDescription },
        heroPhoto: { url: heroPhoto },
        heroVideo,
        adCodeBlock
      },
    },
  } = useStaticQuery(query)
  const mediaBlock = (
    <MediaBlockWithVideo
      src={heroPhoto}
      alt=""
      ctaLabel="Watch Video"
      videoTitle="Embedded Video"
      embedHtml={heroVideo}
    />
  )
  return (
    <Feature
      wrapperBackground
      hero={true}
      title={heroTitle}
      largeTitle
      titleAccent
      description={
        <div dangerouslySetInnerHTML={{ __html: qrCode + heroDescription + adCodeBlock }} />
      }
      mediaBlock={mediaBlock}
    />
  )
}

const query = graphql`
  {
    page: prismicCareers {
      data {
        title {
          text
        }
        heroTitle: hero_title
        qrCode: careers_qr_code
        heroDescription: hero_description {
          html
        }
        heroPhoto: hero_photo {
          url
        }
        heroVideo: hero_video
        adCodeBlock: adcodeblock
      }
    }
  }
`
