import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { rem } from 'polished'
import { TitleStyles, AccentRuleStyles } from '@/styles/shared'

const DefaultStyles = css`
  color: var(--c-gunmetal);
  font-size: ${rem('18px')};
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;
`

export const HeroImage = styled.img`
  display: block;
  margin: -80px -50px 50px;
  width: calc(100% + 100px);
`

const GeneralStyles = css`
  p,
  td,
  li {
    ${DefaultStyles};
  }

  p {
    &.large {
      font-size: ${rem('24px')};
    }

    b {
      font-weight: 600;
    }
  }

  h1 {
    ${TitleStyles};
    ${AccentRuleStyles({ justify: 'left' })};
  }

  h2 {
    color: var(--c-gunmetal);
    font: bold ${rem('36px')} var(--f-serif);
    letter-spacing: ${rem('2px')};
    line-height: 1.5;
    text-transform: uppercase;
  }

  h3 {
    ${DefaultStyles};
    font-weight: 700;
    margin-bottom: 0;

    + p {
      margin-top: 0;
    }
  }
`

export default GeneralStyles

export const General = styled.div`
  ${GeneralStyles};
  padding: 80px 50px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
`
