// @flow

import React from 'react'
import StandardContactForm from '@/components/Forms/StandardContactForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => (
  <StandardContactForm
    name="Home Health Fact Sheet Request"
    action={action}
    submitLabel="Get Fact Sheet"
  />
)
