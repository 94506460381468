// @flow

import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '@/layouts/CareersLayout'
import Intro from '@/components/Intro'
import LayoutContainer from '@/components/LayoutContainer'
import Company from '@/components/Company'

type Props = {
  data: Object,
}

function CompaniesPage({
  data: {
    page: {
      data: {
        title: { text: title },
        overview: { html: overview },
        companies,
      },
    },
  },
}: Props) {
  return (
    <Layout borderedFooter parentLayout>
      <LayoutContainer>
        <Intro
          title={title}
          titleAccent
          titleAccentCenter
          description={<div dangerouslySetInnerHTML={{ __html: overview }} />}
        />
      </LayoutContainer>
      {companies.length >= 1 && (
        <LayoutContainer>
          <Companies companies={companies} />
        </LayoutContainer>
      )}
    </Layout>
  )
}

export default CompaniesPage

type CompaniesProps = {
  companies: Array,
}

const Companies = ({ companies }: CompaniesProps) => (
  <>
    {Array.isArray(companies) && companies.length && (
      <CompaniesLayout>
        {companies.map(company => (
          <Company key={`company-${company.title}`} {...company} />
        ))}
      </CompaniesLayout>
    )}
  </>
)

export const query = graphql`
  {
    page: prismicCompanies {
      data {
        title {
          text
        }
        overview {
          html
        }
        companies {
          title: company_title
          photo: company_photo {
            url
          }
          description: company_description {
            html
          }
          ctaLabel: company_cta_label
          ctaLink: company_cta_link {
            uid
            type
            url
          }
        }
      }
    }
  }
`

const CompaniesLayout = styled.div`
  display: grid;
  grid-gap: var(--d-gutter) 0;
  margin: var(--d-gutter) 0;
`
