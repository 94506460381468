import { css } from '@emotion/core'
import { rem, darken } from 'polished'

export default css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    height: var(--body-height);
    background-color: var(--body-background-color);
  }

  html,
  body {
    margin: 0;
  }

  body,
  button,
  td {
    font-family: var(--f-default);
  }

  a {
    text-decoration: none;
    color: var(--c-lightGreyBlue);

    &:hover {
      color: ${darken(0.2, '#9bbec7')};
    }
  }

  .center-align {
    text-align: center;
  }

  .right-align {
    text-align: right;
  }

  .nowrap {
    white-space: nowrap;
  }

  .underline {
    text-decoration: underline;
  }

  .form-component {
    display: grid;
    background: #fff;
    padding: var(--d-formGutter);
    border-radius: var(--d-borderRadius);
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    font-family: var(--f-default);
  }
  .input-component {
    border: 1px solid var(--c-silver);
    border-radius: var(--d-borderRadius);
    padding: 13px 10px;
    font-size: 1rem;
    font-family: var(--f-default);

    &::placeholder {
      color: var(--c-mediumGray);
    }

    &:invalid {
      border-color: red;
    }
  }

  .textarea-component {
    resize: vertical;
  }

  .label-component {
    display: grid;
    margin: var(--d-formInputGutter) 0;
  }

  .label-text {
    display: flex;
    color: var(--c-charcoalGrey);
    font: 800 ${rem('24px')} var(--f-serif);
    letter-spacing: ${rem('0.5px')};
    line-height: 1.16;
    margin: 0 0 5px;

    &[data-required] {
      &::after {
        content: '*';
      }
    }
  }

  .has-checkbox {
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;
    padding: 0.25rem 1.5rem;
    background: var(--c-lightGrey);
    border-radius: 8px;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    align-items: flex-start;

    .label-component {
      display: flex;
      flex-flow: row nowrap;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
    }

    &.dark-text {
      .label-text {
        color: var(--c-charcoalGrey);
      }
    }

    .label-text {
      margin: 0;
      display: block;
      font-size: 16px;
      height: 100%;
      font-family: var(--f-default);
      font-weight: 400;
      color: white;
    }

    [type='checkbox'] {
      margin-top: 0;
      margin-right: 1rem;
      transform: scale(2);
      border: none;
    }
  }

  .field-error {
    color: #d00;
    font-weight: 600;
    font-size: 0.9rem;
  }

  &.blog-category-menu-newsletter-form {
    .form-footer {
      display: flex;

      [type='submit'] {
        text-align: center;
        width: 100%;
        background: var(--c-canary);
      }
    }
  }

  ul {
    .form-component {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      max-width: 100%;
      background: unset;
    }
  }

  /* aspect ratio: https://css-tricks.com/responsive-iframes/ */
  .embed {
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(100% / (16 / 9));
    }

    > img {
      height: auto;
    }

    > :first-of-type {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
`
