import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const ImageContainer = styled.div`
  position: relative;
  display: block;
  height: 0;
  padding-bottom: 68%;
`

export const Image = styled.img`
  display: block;
  width: 100%;

  ${props =>
    props.featured &&
    css`
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      object-fit: contain;
    `};
`
