import React, { useContext, useMemo } from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '@/layouts/CareersLayout'
import Jobs from '@/components/Jobs'
import { CarrerPageFeature } from '@/components/CareerPageFeature'
import { JobsFormatters } from '@/features/jobs/utils'
import {
  JobsContext,
  JobsProvider,
} from '@/features/jobs/components/JobsProvider'

type JobDepartmentPageProps = {
  params: {
    department: string
  }
  data: Object
}

const useDepartment = (departmentFromParams, jobDepartments) => {
  return useMemo(() => {
    let jobDept = jobDepartments.edges.find(
      edge => edge.node.uid === departmentFromParams
    )
    if (!jobDept) {
      return null
    }
    return {
      title: jobDept.node.data.title,
      uid: jobDept.node.uid,
    }
  }, [departmentFromParams])
}

function JobDepartmentPage({ params, data }: JobDepartmentPageProps) {
  const department = useDepartment(params.department, data.jobDepartments)

  if (!department) {
    if (typeof window !== 'undefined') {
      navigate('/careers')
    }
    return null
  }

  return (
    <Layout>
      <CarrerPageFeature />
      <JobsProvider>
        <JobsByDepartment department={department} />
      </JobsProvider>
    </Layout>
  )
}

export default JobDepartmentPage

export function Head({ params, data }) {
  const department = useDepartment(params.department, data.jobDepartments)
  return (
    <>
      <title>Arcadia - Careers - {department?.title}</title>
      <meta name="description" content={''} />
    </>
  )
}

interface JobsByDepartmentProps {
  department: {
    title: string
    uid: string
  }
}

const JobsByDepartment = ({ department }: JobsByDepartmentProps) => {
  const { jobs } = useContext(JobsContext)
  const allJobsFromQuery = jobs
    .filter(job => {
      return job.data.departments
        .map(dept => dept?.department?.document?.uid)
        .includes(department?.uid)
    })
    .map(job => ({ prismicId: job.prismicId, ...job.data }))
  const formattedJobs = JobsFormatters.shapeJobs(allJobsFromQuery)

  return (
    <Jobs
      title={`Job Openings by Department: ${department.title}`}
      jobs={formattedJobs}
      department={department}
    />
  )
}

export const query = graphql`
  {
    careersPage: prismicCareers {
      data {
        orderedJobs: ordered_jobs {
          job {
            document {
              ... on PrismicJobPosting {
                prismicId
                ...jobPosting
              }
            }
          }
        }
      }
    }
    jobDepartments: allPrismicJobCategory(
      sort: { fields: [data___title] }
      filter: { data: { cover: { url: { ne: null } } } }
    ) {
      edges {
        node {
          uid
          data {
            title
            cover {
              url
            }
          }
        }
      }
    }
  }
`
