// @flow

import React from 'react'
import ContactForm from '@/components/Forms/ContactForm'

type Props = {
  action: String,
}

export default ({ action }: Props) => <ContactForm action={action} />
