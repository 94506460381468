// @flow

import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import Link from '@/components/Link'
import { Image } from '@/components/Image'
import { mq } from '@/styles/theme'
import { BlogPostDate as Date } from '@/styles/shared'

type Props = {
  featured: Boolean,
  className?: String,
  blogPost: Object,
}

export default ({
  blogPost: { uid, url, featuredImage, title, publishDate },
  featured,
  ...props
}: Props) => {
  return (
    <Post featured={featured} {...props}>
      <ImageContainer featured={featured} to={url || `/news/${uid}/`}>
        <Image loading="lazy" src={featuredImage} alt={featuredImage} />
      </ImageContainer>
      <Content featured={featured}>
        <Date featured={featured}>{publishDate}</Date>
        <Title featured={featured}>
          <Link to={url || `/news/${uid}/`}>{title}</Link>
        </Title>
      </Content>
    </Post>
  )
}

const Post = styled.article`
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);

  ${props =>
    props.featured &&
    css`
      background-image: url(${props.src});
      background-size: contain;
      ${mq.greaterThan('small')`
        position: relative;
        height: 0;
        padding-bottom: var(--d-postFeaturedImageAspectRatio);
        grid-column: 1 / 4;
      `};
    `};
`

const ImageContainer = styled(Link)`
  position: relative;
  display: block;
  height: 0;
  padding-bottom: var(--d-postFeaturedImageAspectRatio);
  overflow: hidden;
`

const Content = styled.div`
  display: block;
  padding: 20px 40px;
  position: relative;
  z-index: 1;

  ${props =>
    props.featured &&
    css`
      ${mq.greaterThan('small')`
        background: linear-gradient(
          196.95deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.67) 100%
        );
        pointer-events: none;
        padding: 60px 200px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      `};
    `};
`

const Title = styled.p`
  color: var(--c-gunmetal);
  font-size: ${rem('18px')};
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;

  a {
    color: inherit;
  }

  ${props =>
    props.featured &&
    css`
      a {
        ${mq.greaterThan('small')`
          color: #fff;
          font-family: var(--f-serif);
          font-size: ${rem('46px')};
          letter-spacing: ${rem('-1.15px')};
          line-height: 1;
          margin: 0;
        `};
      }
    `};
`
