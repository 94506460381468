import React from 'react'
import * as Yup from 'yup'
import { Input } from '@/components/Form'

const Component = () => (
  <Input label="Phone" id="phone" type="tel" name="phone" required />
)

const schema = Yup.string()

const initialValue = ''

export default {
  Component,
  schema,
  initialValue,
}
