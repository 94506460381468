import * as React from 'react'
const SvgHeroMask = props => (
  <svg width={0} height={0} viewBox="0 0 681 569" {...props}>
    <defs>
      <clipPath
        id="hero-mask_svg__hero-mask"
        clipPathUnits="objectBoundingBox"
        transform="scale(.00147 .00176)">
        <path d="M63.593 527.805c-24.649-2.14-47.767-24.199-50.858-48.85-16.98-139.637-16.98-279.273 0-418.909 3.09-24.652 26.209-46.712 50.858-48.851 178.605-14.927 357.21-14.927 535.814 0 24.649 2.14 47.767 24.2 50.858 48.851 16.98 139.636 16.98 279.272 0 418.908-3.09 24.652-26.209 46.711-50.858 48.851-178.605 14.927-357.21 14.927-535.814 0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgHeroMask
